import Translate from "components/CustomTranslate";
import {SelectField, SelectFieldItems, StringField} from "../Fields";
import {maritalStatus, salutations} from "../../../js/helpers/data";
import React from "react";
import NumberField from "../NumberField";
import PlacesAutocomplete from 'react-places-autocomplete';
import {phoneValidation} from "../../../js/helpers/functions";

let Salutations = (props) => {
	return <SelectField
		id={props.id}
		onChange={(e) => props.update('salutation', e.target.value)}
		value={props.data.salutation}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label={<Translate value='step4.salutation'/>}
		margin="normal"
		disabled={props.disabled}
	>{SelectFieldItems(props.lang === 'bg' ? [{key: "mr", name: "Господин"}, {key: "mrs", name: "Госпожа/Госпожица"}] : salutations)}</SelectField>;
};

let MaritalStatus = (props) => {
	return <SelectField
		onChange={(e) => props.update('marital_status', e.target.value)}
		value={props.data.marital_status}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label={<Translate value='step4.maritalStatus'/>}
		margin="normal"
		className="mediumLabel"
		name={'maritalStatus'}
		disabled={props.disabled}
	>{SelectFieldItems(maritalStatus)}</SelectField>;
};

let FirstName = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('first_name', e.target.value)}
		value={props.data.first_name}
		validators={['required', 'nameValidation', 'nameValidationLength']}
		errorMessages={['Cette information doit être renseignée', "Les caractères spéciaux ne sont pas admis", 'Cette saisie comporte trop de caractères']}
		label={<Translate value='step4.firstName'/>}
		margin="normal"
		name={'firstName'}
		disabled={props.disabled}
	/>;
};

let LastName = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('last_name', e.target.value)}
		value={props.data.last_name}
		validators={['required', 'nameValidation', 'nameValidationLength']}
		errorMessages={['Cette information doit être renseignée', "Les caractères spéciaux ne sont pas admis", 'Cette saisie comporte trop de caractères']}
		label={<Translate value='step4.lastName'/>}
		margin="normal"
		className='mediumLabel'
		name={'lastName'}
		disabled={props.disabled}
	/>;
};

let MaidenName = (props) => {
	return <StringField
		onChange={(e) => props.update('maiden_name', e.target.value)}
		value={props.data.maiden_name}
		validators={['required', 'nameValidation', 'nameValidationLength']}
		errorMessages={['Cette information doit être renseignée', "Les caractères spéciaux ne sont pas admis", 'Cette saisie comporte trop de caractères']}
		label='Nom de naissance'
		margin="normal"
		className='mediumLabel'
		disabled={props.disabled}
	/>;
};

let BirthPlace = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('birth_city', e.target.value)}
		value={props.data.birth_city}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label={<Translate value='step4.cityOfBirth'/>}
		margin="normal"
		className='longLabel'
		name={'birthPlace'}
		disabled={props.disabled}
	/>;
};

let StreetDetails = (props) => {
	return <StringField
		id={`streetDetails_${props.isCoborrower ? 'coborrower' : 'borrower'}`}
		onChange={(e) => props.update('address', e.target.value)}
		value={props.data.address}
		validators={['required', 'lessThan26characters']}
		errorMessages={['Cette information doit être renseignée', 'Votre adresse ne doit pas dépasser 26 caractères']}
		label={<Translate value='step4.streetDetails'/>}
		margin="normal"
		disabled={props.disabled}
	/>;
};

let AdditionalDetails = (props) => {
	return <StringField
		id={`additionalDetails_${props.isCoborrower ? 'coborrower' : 'borrower'}`}
		onChange={(e) => props.update('additional_address', e.target.value)}
		value={props.data.additional_address}
		label={<Translate value='step4.additionalDetails'/>}
		margin="normal"
		disabled={props.disabled}
	/>;
};

let PostalCode = (props) => {
	let validators = ['required']
	let errors     = ['Cette information doit être renseignée']

	if (props.data.birth_country === 'France' && props.locale === 'fr') {
		validators.push('postalValidation')
		errors.push("Ceci n'est pas un code postal français")
	}
	return <StringField
		id={`postalCode_${props.isCoborrower ? 'coborrower' : 'borrower'}`}
		type={'tel'}
		className={props.data.zip && props.additionalError && 'additionalError'}
		onChange={(e, value) => {
			props.update('zip', e.target.value);
			props.updateTown && props.updateTown(e.target.value);
		}}
		value={props.data.zip}
		validators={validators}
		errorMessages={errors}
		label={<Translate value='step4.postalCode'/>}
		margin="normal"
		disabled={props.disabled}
	/>;
};

let Email = (props) => {
	return <StringField
		onChange={(e) => {
			props.update('email', e.target.value);
			// let error = '';
			// if (props.borrower && props.borrower.email === e.target.value) {
			// 	error = "Le email de l'emprunteur et du coemprunteur doivent être différents.";
			// }
			// props.updateUniqueEmail && props.updateUniqueEmail(error);
		}}
		value={props.data.email}
		name='email'
		helperText={props.uniqueEmailError}
		error={props.uniqueEmailError.length > 0}
		validators={['required', 'isEmail', 'isNotMatch:' + props.borrower.email]}
		errorMessages={['Cette information doit être renseignée', 'L\'e-mail est incorrect', 'L\'adresse email du coemprunteur doit être différente.']}
		label={<Translate value='step4.coborrowerEmail'/>}
		margin="normal"
	/>
};

let Phone = (props) => {
	return <StringField
		value={props.data.phone ? phoneValidation(props.data.phone, true).phone : ''}
		onChange={e => {
			let format = phoneValidation(e.target.value, true);
			props.update('phone', format.phone);
			// let error = '';
			// if (props.borrower && props.borrower.phone === e.target.value) {
			// 	error = "Le téléphone de l'emprunteur et du coemprunteur doivent être différents.";
			// }
			// props.updateUniquePhone && props.updateUniquePhone(error);
		}}
		id={props.id}
		name='phone'
		helperText={props.uniquePhoneError || null}
		error={props.uniquePhoneError && props.uniquePhoneError.length > 0}
		type={'tel'}
		// className='extraLongLabel'
		className={props.className || ''}
		validators={['required', 'isMobileNumber']}
		errorMessages={['Cette information doit être renseignée', 'Ceci n\'est pas un n° de téléphone français.']}
		label={props.label || <Translate value='step9.phone'/>}
		placeholder='+33'
		disabled={props.disabled}
	/>;
};

let Town = (props) => {
	const searchOptions = {
		types: ['(cities)'],
		componentRestrictions: {country: 'FR'}
	};

	return <PlacesAutocomplete
		value={props.data.city}
		onChange={(e) => props.update('city', e.replace(', France', ''))}
		onSelect={(e) => props.update('city', e.replace(', France', ''))}
		searchOptions={searchOptions}
	>{({getInputProps, suggestions, getSuggestionItemProps}) => (
		<div className='placesInputWrapper'>
			<StringField
				id={`town_${props.isCoborrower ? 'coborrower' : 'borrower'}`}
				{...getInputProps()}
				validators={['required']}
				errorMessages={['Cette information doit être renseignée']}
				label={<Translate value='step4.town'/>}
				margin="normal"
				className={'location-search-input'}
				disabled={props.disabled}
			/>
			<div className="autocomplete-dropdown-container">
				{suggestions.map(suggestion => {
					const className = suggestion.active
						? 'suggestion-item--active'
						: 'suggestion-item';
					return (
						<div {...getSuggestionItemProps(suggestion, {className})} >
							<span>{suggestion.description.replace(', France', '')}</span>
						</div>
					);
				})}
			</div>
		</div>
	)}
	</PlacesAutocomplete>
};

let SubscriberName          = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('subscriber_name', e.target.value)}
		value={props.data.subscriber_name}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='Nom du représentant'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};
let SubscriberCompany       = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('subscriber_company', e.target.value)}
		value={props.data.subscriber_company}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='Raison Sociale'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};
let SubscriberNumber        = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('subscriber_company_number', e.target.value)}
		value={props.data.subscriber_company_number}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='SIRET'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};
let SubscriberCompanyStatus = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('subscriber_company_status', e.target.value)}
		value={props.data.subscriber_company_status}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='Statut juridique'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};
let SubscriberCompanyEmail  = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('subscriber_company_email', e.target.value)}
		value={props.data.subscriber_company_email}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='Email'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};
let SubscriberCompanyPhone  = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('subscriber_company_phone', e.target.value)}
		value={props.data.subscriber_company_phone}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='Numéro de mobile'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};

let SubscriberАddress    = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('subscriber_address', e.target.value)}
		value={props.data.subscriber_address}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='Numéro, rue'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};
let SubscriberTown       = (props) => {
	return <StringField
		id={props.id}
		onChange={(e) => props.update('subscriber_town', e.target.value)}
		value={props.data.subscriber_town}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='Ville'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};
let SubscriberPostalCode = (props) => {
	return <StringField
		id={props.id}
		type='tel'
		onChange={(e) => props.update('subscriber_zip', e.target.value)}
		value={props.data.subscriber_zip}
		validators={['required']}
		errorMessages={['Cette information doit être renseignée']}
		label='Code postal'
		margin="normal"
		className='longLabel'
		disabled={props.disabled}
	/>;
};

export {
	Salutations,
	MaritalStatus,
	FirstName,
	LastName,
	MaidenName,
	BirthPlace,
	StreetDetails,
	AdditionalDetails,
	SubscriberTown,
	SubscriberАddress,
	SubscriberPostalCode,
	SubscriberName,
	SubscriberNumber,
	SubscriberCompany,
	SubscriberCompanyPhone,
	SubscriberCompanyStatus,
	SubscriberCompanyEmail,
	PostalCode,
	Town,
	Phone,
	Email
};
