import React from "react";
import Translate from "components/CustomTranslate";
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import './style.css';
import connect from "react-redux/es/connect/connect";
import Shell from "components/Shell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {TiTick} from 'react-icons/ti'
import femaleImage from 'components/Modal/female.svg';
import {BsCloudDownload} from "react-icons/bs";
import {getBackendUrl} from "js/helpers/functions";
import {getContractData} from "../../../../js/actions/config";

class Home extends React.Component {

	constructor() {
		super();

		this.state = {
			dialogOpen: false,
			renunciation_step: 1,
			renunciation_reason: '',
			renunciation_suggestion: '',
		};
	}

	getStep() {
		return 'personal.home';
	}

	handleUploadButtonChange(e, id) {
		if (e.target.files[e.target.files.length - 1]) {
			let formData = new FormData();
			formData.append('id', id);
			formData.append('file', e.target.files[e.target.files.length - 1]);
			this.props.addAttachments(formData)
				.then(() => this.props.getAttachments({email: this.props.borrower.email}));
			e.target.value = null; //If needed to re-upload the same file
		}
	}

	uploadButton() {
		return (
			<div style={{maxWidth: '200px'}}>
				<input hidden id='selfie_picture' name='selfie_picture' type="file" accept="pdf,jpeg,jpg,png" multiple
				       onChange={(e) => this.handleUploadButtonChange(e, 'selfie_picture')}/>
				<label htmlFor='selfie_picture' style={{borderColor: this.props.config.theme.primary, color: this.props.config.theme.primary}} className={'stepsButton'}>
					<BsCloudDownload className="reactIcons" size={20} style={{transform: 'rotate(180deg)'}}/>Качи
				</label>
			</div>
		)
	}

	downloadButton(document_name) {
		return (
			<div style={{maxWidth: '200px'}}>
				<a href={`${getBackendUrl()}/static/${document_name}`} target="_blank">
					<label style={{borderColor: this.props.config.theme.primary, color: this.props.config.theme.primary}} className={'stepsButton'}>
						<BsCloudDownload className="reactIcons" size={20}/>Свали
					</label>
				</a>
			</div>
		)
	}

	render() {
		let finished = false;
		//
		// let documents = this.props.config.contract ? this.props.config.contract.map(contract => {
		// 	return {
		// 		name: "Wedou Emprunteur N°" + contract.id,
		// 		signDate: moment(contract.sign_date, 'YYYY/MM/DD').format('DD/MM/YYYY'),
		// 		state: contract.phase,
		// 		bundle_sent: contract.bundle_sent
		// 	};
		// }) : [];

		return (
			<>
				<div className="personalFrame" style={{marginTop: '150px'}}>
					<div style={{backgroundColor: this.props.config.theme.primary}} className='personalHomeFrame'>
						{!!finished && (
							<p className='paper light'>
								<i style={{color: this.props.config.theme.primary}} className="fa fa-info-circle question-icon"/>
								Няколко документа липсват, за да финализирате защитата Ви
							</p>
						)}
						{!finished && (
							<p className='paper light'>
								<TiTick className="reactIcons tickIcon" size={25}/>
								Няколко документа липсват, за да финализирате защитата Ви
							</p>
						)}
					</div>


					<Paper className='personalPaper' style={{marginTop: '100px'}}>
						<img src={femaleImage} style={{position: 'absolute', width: '340px', top: '180px', right: '30px'}}/>
						<Table className='documentsTable'>
							<TableBody className='documentsBody' stripedRows showRowHover>
								<TableRow>
									<TableCell style={{textAlign: 'center'}}><b>Номер на договор</b></TableCell>
									<TableCell style={{textAlign: 'center'}}><b>Дата на подписане</b></TableCell>
									<TableCell style={{textAlign: 'center'}}><b>Етап</b></TableCell>

								</TableRow>
								<TableRow>
									<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>20312</TableCell>
									<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{moment().format('DD/MM/YYYY')}</TableCell>
									<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>Завършен</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>

					<Table className='documentsTable'>
						<TableBody className='documentsBody' stripedRows showRowHover>

							<h3 style={{alignSelf: 'center'}}>Документи за качване</h3>
							<hr/>
							<TableRow>
								<TableCell className='name'>
									Лична карта - лице и гръб
								</TableCell>
								<TableCell className='download' align="right">
									{this.uploadButton()}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className='name'>
									Снимка на лицето Ви и личната карта до него
								</TableCell>
								<TableCell className='download' align="right">
									{this.uploadButton()}
								</TableCell>
							</TableRow>
							<h3 style={{alignSelf: 'center'}}>Документи за сваляне</h3>
							<hr/>
							{this.props.config.contract?.documents && <TableRow>
								<TableCell className='name'>
									Общи условия
								</TableCell>
								<TableCell className='download' align="right">
									{/*{this.props.config.contract.documents[0].name}*/}
									<div style={{maxWidth: '200px'}}>
										<a href="#" onClick={() => {
											this.props.downloadAttachment({file: this.props.config.contract.documents[0].fs_name + '.' + this.props.config.contract.documents[0].extension})
										}}>
											<label style={{borderColor: this.props.config.theme.primary, color: this.props.config.theme.primary}} className={'stepsButton'}>
												<BsCloudDownload className="reactIcons" size={20}/>Свали
											</label>
										</a>
									</div>
								</TableCell>
							</TableRow>}
							<TableRow>
								<TableCell className='name'>
									Преддоговорна информация
								</TableCell>
								<TableCell className='download' align="right">
									{this.downloadButton('WEDOU_Pre_Contractual.pdf')}
								</TableCell>
							</TableRow>

						</TableBody>
					</Table>
				</div>
			</>
		)
	}

}

export default connect()(Shell(Home));
