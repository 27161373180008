import React from "react";

import {Row, Col} from 'react-grid-system';
import history from "../../../history";
import Translate from "components/CustomTranslate";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import './style.css';
import connect from "react-redux/es/connect/connect";
import ReactTooltip from "react-tooltip";

import Shell from "../../../components/Shell";
import {PersonalSpaceHeader} from "../../../components/Header/PersonalSpaceHeader";

class Documents extends React.Component {

	getStep() {
		return 'personal.documents';
	}

	wedouDocuments(documents, coborrower = false) {
		return (
			<Row>
				<Col md={12}>
					<h3 className='semiBold'>
						<Translate value={'personal.documents.wedouDocuments' + (coborrower ? 'Co' : '')}/>
					</h3>
				</Col>
				<Col md={12}>
					{documents.map(i => !i.attached).length === 0 ? (
						<p><Translate value='personal.documents.noDocuments'/></p>
					) : (
						<Paper className='personalPaper'>
							<Table className='documentsTable'>
								<TableBody className='documentsBody' stripedRows showRowHover>
									{documents.map((item) => {
										if (!item.attached) {
											return <TableRow>
												<TableCell className='name'>
													<Translate value={`personal.steps.${item.type}`} dangerousHTML/>
												</TableCell>
												<TableCell className='download' align="right">
													<i className="fa fa-download"
													   onClick={() => this.props.downloadAttachment({file: item.file})}/>
												</TableCell>
											</TableRow>;
										} else
											return <div/>;
									})}
								</TableBody>
							</Table>
						</Paper>
					)}
				</Col>
			</Row>
		);
	}

	render() {
		let attachable = ['id_document', 'id_document_co', 'contract', 'isp', 'other'];
		let borrower   = Object.keys(this.props.attachments.borrower).map(key => {
			return {
				name: this.props.attachments.borrower[key].document_name,
				file: this.props.attachments.borrower[key].name,
				type: key,
				attached: attachable.includes(key)
			};
		});

		let coborrower = Object.keys(this.props.attachments.coborrower).map(key => {
			return {
				name: this.props.attachments.coborrower[key].document_name,
				file: this.props.attachments.coborrower[key].name,
				type: key,
				attached: attachable.includes(key)
			};
		});
		return (
			<div className='personalFrame'>
				<PersonalSpaceHeader
					contract={this.props.config.contract}
					health_selection={this.props.borrower.health_selection}
					signed={this.props.borrower.has_signed}
					showResigning={this.props.attachments.documents_for_resigning && this.props.attachments.documents_for_resigning.length > 0 }
					title={'personal.home.myContacts'}/>

				<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
				<Row>
					<Col md={12}>
						{borrower.map(i => i.attached).length === 0 ? (
							<p><Translate value='personal.documents.noDocuments'/></p>
						) : (
							<Paper className='personalPaper'>
								<Table className='documentsTable'>
									<TableBody className='documentsBody' stripedRows showRowHover>
										{borrower.map((item) => {
											if (item.attached) {
												return <TableRow>
													<TableCell className='name'>
														<Translate value={`personal.steps.${item.type}`} dangerousHTML/>
													</TableCell>
													<TableCell className='download' align="right">
														<i className="fa fa-download"
														   onClick={() => this.props.downloadAttachment({file: item.file})}/>
													</TableCell>
												</TableRow>;
											} else {
												return <div/>;
											}
										})}
									</TableBody>
								</Table>
							</Paper>
						)}
					</Col>
				</Row>

				{this.wedouDocuments(borrower)}
				{!!this.props.borrower.has_coborrower && this.wedouDocuments(coborrower, true)}

			</div>
		)
	}
}

export default connect()(Shell(Documents));
