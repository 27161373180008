import {LOAN_UPDATE, LOAN_INITIAL_FETCH, LOANS_SET, RE_INITIALIZE_ALL, SET_STATE, RELOAD_STATE} from "../../constants/action-types";
import {loadState} from "../../localStorage";

const persistedState = loadState();

let loans        = {
	loan_status: "",
	amount: "",
	interest_rate: 0,
	duration_years: null,
	deferred: false,
	duration_months: null,
	taea: null,
	split_payments: [{amount: 0, duration: 0}, {amount: 0, duration: 0}],
	co_taea: null,
	global_insurance: null,
	insurance_premium: null,
	insurance_rate: null,
	co_insurance_rate: null,
	type: "depriciable",
	residual_value: 0
};
let initialState = {
	chosen_input: 'globalInsurance',
	total_loans: 1,
	loans: [
		Object.assign({}, loans),
		Object.assign({}, loans),
		Object.assign({}, loans)
	], // 3 loans default states. Sorry.
	borrower_coverage: null,
	co_borrower_coverage: null,
	bank: '',
	bank_search: '',
	project_type: '',
	loan_contract_date: '',
	insurer: '',
	monthly_installment: null,
	assurance_tax: null,
	co_assurance_tax: null,
	combined_monthly_fee: null,
	constant_premium: true,
	flow: 'mncap',
	sci: false,
	real_estate_60: null,
	type: 'depreciable'

};
let defaultState = Object.assign({}, initialState, persistedState.loan);
export default (state = defaultState, action) => {
	let newState = {...state};

	switch (action.type) {
		case SET_STATE:
			return action.data.loan;
		case RE_INITIALIZE_ALL:
			return initialState;
		case RELOAD_STATE:
			return {
				...state,
				...action.payload.loan
			};
		case LOAN_UPDATE:
			if (Array.isArray(action.key)) {
				newState['loans'][action.key[0]][action.key[1]] = action.value;
				return newState;
			} else {
				return {...state, [action.key]: action.value}
			}
		case LOAN_INITIAL_FETCH:
			action = addMissingLoans(action);
			return {
				...initialState,
				...action.data
			};
		case LOANS_SET:
			action = addMissingLoans(action);
			return {
				...state,
				...action.data
			};
		default:
			return state;
	}
}

function addMissingLoans(action) {
	let new_action = JSON.parse(JSON.stringify(action));
	let new_loans  = [];
	for (let i = 0; i < initialState.loans.length; i++) {
		new_loans.push(Object.assign({}, loans, new_action.data.loans[i] || {}));
	}
	new_action.data.loans = new_loans;
	return new_action;
}
