import React from 'react';
import {connect} from 'react-redux';
import Shell from '../../components/Shell';
import {Row, Col} from "react-grid-system";
import Button from '@material-ui/core/Button';
import history from "../../history";

import Translate from "components/CustomTranslate";
import firstImage from './first.png';
import secondImage from './second.png';
import phoneImage from './phone.png';
import traitImage from "../../components/FinalOffer/trait.svg";
import appstoreImage from './appstore.png';
import playstoreImage from './playstore.png';

class Finalize extends React.Component {

	componentDidMount() {
		!this.props.isCoborrower && this.props.hasSigned()
			.then(response => {
				if (response.data.result) {
					this.props.updateBorrower('has_signed', true);
				} else {
					// alert("Error");
				}
			}).catch(console.warn);
	}

	getStep() {
		return 'step11';
	}

	render() {
		return !!this.props.isCoborrower ?
			<div>
				<Row justify={'center'} style={{textAlign: "center"}}>
					<Col md={8} style={{marginTop: "40px"}}>
						<h3>Vous retrouverez dans quelques instants vos documents signés dans votre boîte email.</h3>
					</Col>
				</Row>
				<Row justify={'center'} style={{textAlign: "center"}}>
					<Col md={8}>
						<p style={{fontSize: '17px'}}>Bienvenue dans la communauté Wedou. Merci de penser à compléter votre dossier avec dans votre espace client.</p>
					</Col>
				</Row>
			</div>
			:
			<div style={{color: '#5372e4'}}>
				<Row justify={'center'} style={{textAlign: "center"}}>
					<Col md={12} style={{marginTop: "40px"}}>
						<h2 style={{color: 'rgb(246, 182, 13)', fontSize: 30, fontWeight: '800'}}>Bienvenue {this.props.borrower.firstName},</h2>
						<h4 style={{color: '#000', fontWeight: '600', fontSize: 20}}>vous trouverez dans quelques instants<br/> vos documents signés dans votre boîte email.</h4>
					</Col>
				</Row>
				<Row justify={'center'} style={{textAlign: "center", marginTop: "2px"}}>
					<Col md={12}>
						<p style={{fontSize: '17px', color: "#707071"}}>Afin de finaliser la substitution nous devons faire parvenir à votre banque certains documents.<br/>
							Pour nous les transmettre, nous vous invitons à vous connecter à votre espace personnel.</p>
						<img src={firstImage} style={{width: '700px', height: '500px'}}/>
					</Col>
				</Row>
				<Row justify={'center'} style={{marginTop: '30px'}}>
					<Col md={4} sm={9} xs={9}>
						<Button onClick={() => {
							history.push('personal_home')
						}} style={{backgroundColor: this.props.config.theme.primary, borderRadius: 25, marginTop: 50, marginBottom: 50}} className={'formButton'} autoFocus>
							<Translate value={'step11.continueButton'}/>
						</Button>
					</Col>
				</Row>
				<Row justify={'center'} style={{marginTop: '30px', textAlign: 'center'}}>
					<Col md={4} sm={9} xs={9}>
						<h1 style={{color: '#000'}}>Prenez soin <span style={{color: '#88BAC7'}}>de vous</span></h1>
						<img src={traitImage} style={{marginLeft: '50px'}} className="traitImage"/>
						<p style={{color: '#000', marginTop: '50px'}}>et faites des économies avec wedou.care, l’application qui vous aide à être toujours en pleine forme.</p>
					</Col>
				</Row>
				<Row justify={'center'} style={{marginTop: '30px', textAlign: 'center'}}>
					<Col md={8} sm={9} xs={9} style={{flexDirection: 'row'}}>
						<Row>
							<Col md={6}>
								<img src={secondImage} style={{height: '500px'}}/>
							</Col>
							<Col md={6}>
								<img src={phoneImage} style={{width: '300px'}}/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1 style={{fontSize: '45px', color: '#000'}}>Télécharger <span style={{color: '#E3755F'}}>wedou.care</span></h1>
					</Col>
				</Row>
				<Row justify='center'>
					<Col md={3}>
						<a href="https://app.wedou.fr/download"><img src={appstoreImage} style={{width: 200}}/></a>
						<a href="https://app.wedou.fr/download"><img src={playstoreImage} style={{width: 200}}/></a>
					</Col>
				</Row>
			</div>
	}

}

export default connect()(Shell(Finalize));
