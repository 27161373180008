import React, {ChangeEventHandler, useRef, useState} from 'react';

import {format, isValid, parse} from 'date-fns';
import FocusTrap from 'focus-trap-react';
import {DayPicker} from 'react-day-picker';
import {usePopper} from 'react-popper';
import {DateField} from "../index";
import calendarImage from './calend.svg';
import style from './style.css';
var I18n = require('react-redux-i18n').I18n;
export default function DatePickerDialog(props) {
	const [selected, setSelected]         = useState();
	const [inputValue, setInputValue]     = useState('');
	const [isPopperOpen, setIsPopperOpen] = useState(false);

	const popperRef                         = useRef(null);
	const buttonRef                         = useRef(null);
	const [popperElement, setPopperElement] = useState(
		null
	);

	const popper = usePopper(popperRef.current, popperElement, {
		placement: 'bottom-start'
	});

	const closePopper = () => {
		setIsPopperOpen(false);
		buttonRef.current.focus();
	};

	const handleInputChange = (e, v) => {
		setInputValue(v);
		const date = parse(v, 'dd/MM/y', new Date());
		if (isValid(date)) {
			setSelected(date);
		} else {
			setSelected(undefined);
		}
	};

	const handleButtonClick = () => {
		setIsPopperOpen(true);
	};

	const handleDaySelect = (date) => {
		setSelected(date);
		if (date) {
			props.update(props.updateField, format(date, 'dd/MM/y'))
			setInputValue(format(date, 'dd/MM/y'));
			closePopper();
		} else {
			setInputValue('');
		}
	};

	return (
		<div>
			<div ref={popperRef}>
				<DateField
					placeholder={format(new Date(), 'dd/MM/y')}
					value={props.value}
					className="input-reset pa2 ma2 bg-white black ba"
					InputProps={{
						endAdornment:
							<span data-tip={I18n.t("generic.chooseDate")}>
							          <span role="img" aria-label="calendar icon"
							                ref={buttonRef}
							                style={{cursor: 'pointer'}}
							                className="pa2 bg-white button-reset ba"
							                onClick={handleButtonClick}>
								         <img src={calendarImage} style={{height: '35px', paddingTop: '2px'}} />
							          </span>
								 </span>,
					}}
					{...props}
					onChange={(e, v) => {
						props.update(props.updateField, v);
						handleInputChange(e, v)
					}}
				/>

			</div>
			{isPopperOpen && (
				<FocusTrap
					active
					style={{zIndex: 9999}}
					focusTrapOptions={{
						initialFocus: false,
						allowOutsideClick: true,
						clickOutsideDeactivates: true,
						onDeactivate: closePopper
					}}
				>
					<div
						tabIndex={-1}
						style={popper.styles.popper}
						className="dialog-sheet"
						{...popper.attributes.popper}
						ref={setPopperElement}
						role="dialog"
					>
						<DayPicker
							initialFocus={isPopperOpen}
							mode="single"
							style={{zIndex: 9999}}
							defaultMonth={selected}
							selected={props.value}
							fromYear={props.fromYear}
							toYear={props.toYear}
							captionLayout="dropdown"
							// fromMonth={selected}
							// toMonth={new Date(2015, 10, 20)}
							onSelect={handleDaySelect}
						/>
					</div>
				</FocusTrap>
			)}
		</div>
	);
}
