import React from "react";

import {Row, Col} from 'react-grid-system';
import history from "../../../history";
import Translate from "components/CustomTranslate";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import './style.css';
import connect from "react-redux/es/connect/connect";
import ReactTooltip from "react-tooltip";

import Shell from "../../../components/Shell";
import {PersonalSpaceHeader} from "../../../components/Header/PersonalSpaceHeader";
import FormButton from "../../../components/Form/FormButton";
import stepsImg2 from "../Steps/stepsImg2.png";
import {BsCloudDownload} from "react-icons/bs";
import {Button} from "@material-ui/core";
import StandardButton from "../../../components/Form/StandardButton";
import Modal from "../../../components/Modal";

class ResignedDocuments extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false
		}
	}

	getStep() {
		return 'personal.documents';
	}

	handleUploadButtonChange(e, id) {
		if (e.target.files[e.target.files.length - 1]) {
			let formData = new FormData();
			formData.append('id', id);
			formData.append('resign', true);
			formData.append('file', e.target.files[e.target.files.length - 1]);
			this.props.addAttachments(formData)
				.then(() => this.props.getAttachments({email: this.props.borrower.email}));
			e.target.value = null; //If needed to re-upload the same file
		}
	}

	async resign() {

		if (this.props.borrower.has_coborrower) {
			this.setState({dialogOpen: true})
		} else {
			this.handleSignature(false)
		}
	}

	async handleSignature(sign_as_coborrower = false) {

		this.props.updateConfig('loading', true);
		// await this.props.updateCustomer({borrower: {payment_iban: this.props.borrower.payment_iban, email: this.props.borrower.email}})
		// await this.props.documents({
		// 	flow: this.props.loan.flow,
		// 	personal_space: true,
		// 	templates: [sepa]
		// });
		await this.props.resignDocuments({
			documents: this.props.attachments.documents_for_resigning
		});
		await this.props.sign({
			borrower: sign_as_coborrower ?  this.props.coborrower : this.props.borrower,
			documents: this.props.config.documents,
			sign_as_coborrower
		})
		this.props.updateConfig('loading', false);
		history.push('/personal_sign');
	}

	stepsButton(document_type) {
		return (
			<div>
				<input hidden id={document_type} name={document_type} type="file" accept="pdf,jpeg,jpg,png" multiple
				       onChange={(e) => this.handleUploadButtonChange(e, document_type)}/>
				<label htmlFor={document_type} style={{borderColor: this.props.config.theme.primary, color: this.props.config.theme.primary}} className={'stepsButton'}>
					<BsCloudDownload className="reactIcons" size={20}/><Translate value={'generic.' + (!this.props.documents ? 'attach' : 'reattach')}/>
				</label>
			</div>
		)
	}

	render() {
		return (
			<div className='personalFrame'>
				<PersonalSpaceHeader
					contract={this.props.config.contract}
					health_selection={this.props.borrower.health_selection}
					signed={this.props.borrower.has_signed}
					showResigning={this.props.attachments.documents_for_resigning && this.props.attachments.documents_for_resigning.length > 0}
					title={'personal.home.resignDocuments'}/>
				<br/>
				<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
				<Row>
					<Col md={12}>
						{this.props.attachments.documents_for_resigning && this.props.attachments.documents_for_resigning.length > 0 ?
							(
								<Paper className='personalPaper'>
									<Table className='documentsTable'>
										<TableBody className='documentsBody' stripedRows showRowHover>
											{this.props.attachments.documents_for_resigning.map((item,index) => {
												return <TableRow>
													<TableCell className='name'>
														{item.name}
													</TableCell>
													<TableCell className='download' align="right">
														<i className="fa fa-download"
														   onClick={() => this.props.downloadAttachment({file: item.fs_name + '.' + item.extension})}/>
													</TableCell>
												</TableRow>;
											})}
										</TableBody>
									</Table>
								</Paper>
							)
							: <p><Translate value='personal.documents.noDocuments'/></p>}
					</Col>
				</Row>
				<Row justify="center">
					<Col md={6}>
						<FormButton value='Signer mes documents en ligne'
						            endIcon={<i className="fa fa-pencil"/>}
						            onClick={() => {
							            this.resign();
						            }}/>
					</Col>
				</Row>
				<Row justify="center">
					<Col style={{textAlign: "center", paddingBottom: "15px", fontSize: '21px', fontWeight: 500, color: '#576FD8'}}>
						ou
					</Col>
				</Row>
				<Row justify="center">
					<Col md={6}>
						<input hidden id="other" name="other" type="file" accept="pdf,jpeg,jpg,png" multiple
						       onChange={(e) => this.handleUploadButtonChange(e, "other")}/>
						<label htmlFor="other">
							<StandardButton value='Télécharger un document signé manuellement'
							                config={this.props.config}
							                onClick={() => {
								                document.getElementById("other").click()
							                }}
							                style={{backgroundColor: 'white', color: '#576FD8'}}
							                endIcon={<i className="fa fa-upload"/>}/>
						</label>
					</Col>
				</Row>
				<Modal minWidth="paperWidthLg" maxWidth="xl" title='Signataire'
				       dialogOpen={this.state.dialogOpen}
				       onClose={() => this.setState({dialogOpen: false})}>
					<div style={{margin: '15px 0 40px 0', textAlign: 'center'}}>Qui signe le document?</div>
					<Row justify={"center"} style={{minWidth: '35rem', maxWidth: '45rem'}}>
						<Col md={6}>
							<FormButton value='Emprunteur'
							            onClick={() => {
								            this.handleSignature(false);
							            }}
							            style={{backgroundColor: 'white', color: '#576FD8'}}/>
						</Col>
						<Col md={6}>
							<FormButton value='Co-emprunteur'
							            onClick={() => {
								            this.handleSignature(true);
							            }}
							            style={{backgroundColor: 'white', color: '#576FD8'}}/>
						</Col>
					</Row>
				</Modal>
			</div>
		)
	}
}

export default connect()(Shell(ResignedDocuments));
