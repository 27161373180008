import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Row, Col} from 'react-grid-system';
import {isMobile} from 'react-device-detect';
import style from '../10.Signature/style.module.css';

class BravoSuccess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bravo: {}
		};
	}

	getStep() {
		return 'bravo_success';
	}

	render() {
		return (
			<div>
			<Row justify='center' style={{textAlign: 'center'}}>
				<Col md={12}>
					<div>Success message</div>
				</Col>
			</Row>
			</div>
		);
	}

}


export default connect()(Shell(BravoSuccess));
