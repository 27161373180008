import React from 'react';
import {connect} from 'react-redux';
import Shell from '../../components/Shell';
import Bravo from '../../components/Bravo';
import Offer from '../../components/Offer';
import AfiescaOffer from '../../components/Offer/Afiesca';

class InitialOffer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {DialogOpen: false};
	  }

	getStep() {
		return 'step3';
	}

	componentDidMount() {
		if(!this.props.borrower.phone){
			this.setState({DialogOpen: true})
		}
	}

	render() {
		return this.props.config.calculation.result ?
			   this.props.loan.flow === 'afi-esca' && this.props.config.calculation.variable && this.props.config.calculation.variable.installments ?
				<AfiescaOffer
					loan={this.props.loan}
					login={this.props.login}
					config={this.props.config}
					borrower={this.props.borrower}
					coborrower={this.props.coborrower}
					updateLoan={this.props.updateLoan}
					updateConfig={this.props.updateConfig}
					updateBorrower={this.props.updateBorrower}
					updateCoBorrower={this.props.updateCoBorrower}
					insuranceCalculation={this.props.insuranceCalculation}
					registration={this.props.registration}
					calculation={this.props.config.calculation}
					afiEsca={this.props.afiEsca}
				/>
				:
				<Offer
					loan={this.props.loan}
					login={this.props.login}
					config={this.props.config}
					borrower={this.props.borrower}
					coborrower={this.props.coborrower}
					updateLoan={this.props.updateLoan}
					updateConfig={this.props.updateConfig}
					updateBorrower={this.props.updateBorrower}
					updateCoBorrower={this.props.updateCoBorrower}
					insuranceCalculation={this.props.insuranceCalculation}
					registration={this.props.registration}
					calculation={this.props.config.calculation}
				/>
			:
			<Bravo
				reason={this.props.config.calculation.reason}
				simulation_id={this.props.config.calculation.simulation_id}
				updateBorrower={this.props.updateBorrower}
				config={this.props.config}
				borrower={this.props.borrower}
				bravoDocuments={this.props.bravoDocuments}
				bravoContact={this.props.bravoContact}
			/>
			}
	}


export default connect()(Shell(InitialOffer));
