import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';

class Captcha extends Component {
	state = {
		loaded: true
	};

	getCaptchaResponse = (response) => {
	};

	render() {
		return (this.state.loaded ?
				<div className="recaptchaWrapper"  style={{display: 'flex', justifyContent: 'center'}}>
					<Recaptcha
						sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
						type="image"
						hl={this.props.lang}
						render='explicit'
						onloadCallback={function(){}}
						getCaptchaResponse={this.getCaptchaResponse}
						expiredCallback={this.props.expiredSession}
						verifyCallback={this.props.verifyCallback}
					/>
				</div> : null
		);
	}
}

export default Captcha;
