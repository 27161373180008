import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";
import style from './style.css';
import Translate from "components/CustomTranslate";
import StandardButton from "../../components/Form/StandardButton";
import {CustomValidatorForm} from "../../components/Form";
import {Slider} from "@material-ui/core";
import {ButtonGroup} from "@material-ui/core";
import {Button} from "@material-ui/core";
import history from "../../history";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@material-ui/lab";
import {BirthDate} from "../../components/Form/StepsFields/step2";
import familyImage from './family.png';
import traitImage from "../../components/FinalOffer/trait.png";
import IncomeCard from "../../components/IncomeCard";
// import Carousel from 'carousel-react-rcdev'

var I18n = require('react-redux-i18n').I18n;
console.log('translations');

class IncomeStepOne extends React.Component {

	constructor(props) {
		console.log('props coming in', props)
		super(props);
		this.state = {
			buttonClicked: false,
			price: 2.50,
			salary: 1000,
			tariff: 3.5,
			ittSelected: 1,
			iptSelected: 1,
			itt: 1,
			ipp: 0,
			unemployment: 0
		}
	}

	getTarriff(itt = false, ipp = false, unemployment = false) {
		let base = 2.5;
		if (itt) {
			base += 1;
		}
		if (ipp) {
			base += 1;
		}
		if (unemployment) {
			base += 3;
		}
		return base;
	}

	getStep() {
		return 'income_step_one';
	}

	render() {
		if (!this.props.config.plans) {
			return <div></div>
		}
		return (
			<div>
				<Container style={{color: '#000', textAlign: 'center', width: '100%',}}>
					<CustomValidatorForm>
						<Row justify="center">
							<Col md={12} style={{textAlign: 'left'}}>
								<Row justify="center" style={{textAlign: 'center'}}>
									<Col md={12}>
										<span className="titlePrets" style={{fontSize: '29px'}}><Translate value="income.title"/></span><br/>
									</Col>
								</Row>
								<Row justify="center">
									<Col md={6} style={{textAlign: 'center', fontSize: '17px', color: '#818E9A', padding: '14px 0'}}>
										Искам покритие за:
									</Col>
								</Row>
								<Row justify="center" style={{textAlign: 'center'}}>
									<Col md={3}>
												<span className="globalPricing" style={{fontWeight: '500', fontSize: '37px'}}>
													{this.state.salary} лв.
												</span>
										<img src={traitImage} style={{width: '170px', position: 'relative', bottom: '5px', left: '10px'}}/>
									</Col>
								</Row>
								<Row justify="center">
									<Col md={6} className="incomeSlider">
										<Slider
											aria-label="Custom marks"
											defaultValue={this.state.salary}
											step={500}
											min={1000}
											max={15000}
											onChange={(e, val) => {
												this.setState({salary: val})
											}}
											// valueLabelDisplay="auto"
											// marks={[
											// 	{
											// 		value: 50,
											// 		label: '50 лв.',
											// 	},
											// 	{
											// 		value: 15000,
											// 		label: '15000 лв.',
											// 	},
											// ]}
										/>
									</Col>
								</Row>
								<Row justify="center" style={{marginTop: '0px'}}>
									<Col md={12} style={{textAlign: 'center'}}>
										<img src={familyImage} style={{width: '410px', position: 'relative', marginBottom: '-100px'}}/>
									</Col>
								</Row>
								<Row justify="center" style={{padding: 0, width: '1110px'}}>
									{this.props.config.plans.map(plan => {
										return (
											<Col style={{marginRight: '10px'}}>
												<IncomeCard openAuthModal={this.props.openAuthModal}
												            isLogged={this.props.config.loggedIn}
												            totalNum={this.props.config.plans.length}
												            plan={plan.name}
												            color={plan.color_scheme}
												            price={((this.state.salary / 1000) * plan.price).toFixed(2)}
												            ipp={plan.guarantees.ipp}
												            itt={plan.guarantees.itt}
												            unemployment={plan.guarantees.unemployment}
												            telemedicine={plan.guarantees.telemedicine}/>
											</Col>
										)
									})}
								</Row>
							</Col>
						</Row>

					</CustomValidatorForm>
				</Container>
			</div>
		)
	}

}

export default connect()(Shell(IncomeStepOne));
