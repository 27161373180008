import React from 'react';
import Shell from '../../components/Shell';
import {Row, Col} from 'react-grid-system';
import Translate from "components/CustomTranslate";
import connect from 'react-redux/es/connect/connect';
import FormButton from '../../components/Form/FormButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import history from '../../history';
import {ValidatorForm} from 'react-material-ui-form-validator';
import BackButton from "../../components/Form/BackButton";

import './style.css';
import ReactTooltip from 'react-tooltip';
import {YesNoRadio} from '../../components/Form/Fields';

import {mapData} from "../../js/helpers/functions";
import AfiEscaHealthQuestions from "./AfiEsca";

class HealthQuestions extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			question_one: '',
			question_two: '',
			question_three: '',
			question_four: '',
			question_five: ''
		};
	}

	componentWillMount() {
		if (this.props.config.isCoborrower) {
			this.props.updateLoan('flow', this.props.coborrower.flow);
		}
		this.update   = this.props.config.isCoborrower ? this.props.updateCoBorrower : this.props.updateBorrower;
		this.borrower = this.props.config.isCoborrower ? this.props.coborrower : this.props.borrower;
		// Health questions must not be prefilled
		if (this.borrower.questions_filled) {
			this.setState({
				question_one: this.borrower.question_one,
				question_two: this.borrower.question_two,
				question_three: this.borrower.question_three,
				question_four: this.borrower.question_four,
				question_five: this.borrower.question_five,
			});
		}
	}

	getStep(isCoborrower) {
		return isCoborrower ? 'coStep6' : 'step6';
	}

	mapData(b, flow) {
		let key         = this.props.config.isCoborrower ? 'coborrower' : 'borrower';
		let data        = {state: {}, flow};
		data.state[key] = this.props[key];
		data[key]       =
			{
				question_one: b.question_one,
				question_two: b.question_two,
				question_three: b.question_three,
				question_four: b.question_four,
				question_five: b.question_five
			};
		if (key === 'borrower') {
			data[key].step  = 'sante';
			data[key].email = b.email;
		}
		return data;
	}


	render() {
		if (sessionStorage.getItem('hqp') === 'disabled' && this.props.loan.flow === 'mncap') {
			history.push('/resume')
		}
		return this.props.loan.flow === 'afi-esca' ?
			<AfiEscaHealthQuestions update={this.update} updateCustomer={this.props.updateCustomer} isCoborrower={this.props.config.isCoborrower} updateConfig={this.props.updateConfig} borrower={this.props.borrower} coborrower={this.props.coborrower}
			                        afiEsca={this.props.afiEsca} loan={this.props.loan} afiEscaCoboLongProcess={this.props.afiEscaCoboLongProcess}/>
			:
			this.props.loan.flow === 'mncap' ?
				<div>
					<iframe width='100%' height="700" style={{border: 0, opacity:'0.9'}} onLoad={function () {
					}} src={this.props.borrower.mncap_url}/>
				</div>
				:
				<div>
					<ValidatorForm onSubmit={() => {
						this.props.updateConfig('loading', true);
						this.update('question_one', this.state.question_one);
						this.update('question_two', this.state.question_two);
						this.update('question_three', this.state.question_three);
						this.update('question_four', this.state.question_four);
						this.update('question_five', this.state.question_five);
						this.update('questions_filled', true);
						let flow = [this.state.question_one, this.state.question_two, this.state.question_three, this.state.question_four, this.state.question_five].every(q => q === 'false') ? 'mutlog' : 'afi-esca';
						this.props.updateLoan('flow', flow);
						this.props.updateCustomer(this.mapData(this.props.config.isCoborrower ? this.props.coborrower : this.props.borrower, flow))
							.then(response => {
								if (response.data && response.data.result) {
									if (this.props.config.isCoborrower) {
										history.push('co-numero');
									} else {
										if (flow === 'afi-esca') {
											this.props.afiEsca(mapData(Object.assign({}, this.props.borrower, {
												step: 'resume',
												health_selection: true
											}), this.props.coborrower, this.props.loan))
												.then(() => {
													return history.push('/migrate');
												});
										} else {
											return history.push('/resume');
										}

									}
								}
							});
					}}>
						<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
						<Row justify="center">
							<Col md={8} style={{marginTop: '35px'}}>
								<p className="focusText">
									<b><Translate value='step6.text' name={this.borrower.first_name}/></b>
								</p>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={12}>
								<p className='healthQuestion'><Translate value='step6.Q1' dangerousHTML/></p>
							</Col>
						</Row>
						<Row>
							<Col className="styleCheckBox" md={4}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_one'
								            value={this.state.question_one}
								            onChange={(event, value) => {
									            this.setState({question_one: value})
								            }}
								><YesNoRadio buttonClicked={this.state.question_one}/></RadioGroup>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={12}>
								<p className='healthQuestion'><Translate value='step6.Q2' dangerousHTML/></p>
							</Col>
						</Row>
						<Row>
							<Col className="styleCheckBox" md={4}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_two'
								            value={this.state.question_two}
								            onChange={(event, value) => {
									            this.setState({question_two: value})
								            }}
								><YesNoRadio buttonClicked={this.state.question_two}/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={12}>
								<p className='healthQuestion'><Translate value='step6.Q3' dangerousHTML/></p>
							</Col>
						</Row>
						<Row>
							<Col className="styleCheckBox" md={4}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_three'
								            value={this.state.question_three}
								            onChange={(event, value) => {
									            this.setState({question_three: value})
								            }}
								><YesNoRadio buttonClicked={this.state.question_three}/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={12}>
								<p className='healthQuestion'><Translate value='step6.Q4' dangerousHTML/></p>
							</Col>

						</Row>
						<Row>
							<Col className="styleCheckBox" justify='right' md={4}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_four'
								            value={this.state.question_four}
								            onChange={(event, value) => {
									            this.setState({question_four: value})
								            }}
								><YesNoRadio buttonClicked={this.state.question_four}/></RadioGroup>
							</Col>
						</Row>

						<Row justify="center">
							<Col md={12}>
								<p className='healthQuestion'><Translate value='step6.Q5' dangerousHTML/></p>
							</Col>
						</Row>
						<Row>
							<Col className="styleCheckBox" justify='right' md={4}>
								<RadioGroup className='radioGroup healthQuestion'
								            name='question_five'
								            value={this.state.question_five}
								            onChange={(event, value) => {
									            this.setState({question_five: value})
								            }}
								><YesNoRadio buttonClicked={this.state.question_five}/></RadioGroup>
							</Col>
						</Row>
						<Row justify="center" className="grayFrame">
							<Col md={12}>
								<p className='healthQuestion'><Translate value='step6.linkStatement' dangerousHTML/></p>
								<hr/>
							</Col>
						</Row>
						<Row justify="center" className="grayFrame">
							<Col md={11}>
								<p className='smallFont'><Translate value='step6.staticText'/></p>
							</Col>
						</Row>

						<Row justify='center'>
							<Col md={6} sm={12} xs={12}>
								<BackButton flow={this.props.loan.flow}/>
							</Col>
							<Col md={6} sm={12} xs={12}>
								<FormButton value={<Translate value='generic.follow'/>}/>
							</Col>
						</Row>
					</ValidatorForm>
				</div>

	}

}

export default connect()(Shell(HealthQuestions));
