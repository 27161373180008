
import Translate from "components/CustomTranslate";
import {CalendarField, DateField, NumberField, SelectField, SelectFieldItems} from "../Fields";
import React from "react";
import {coverage} from "../../../js/helpers/data";
import moment from 'moment';
import {TextField} from "@material-ui/core";
import DatePickerDialog from "../Fields/DatePickerDialog";

var I18n = require('react-redux-i18n').I18n;
let BirthDate = (props) => {
	let label = props.label || 'step2.birthDate';
	return <DatePickerDialog
		name='birthDate'
		disabled={props.disabled || false}
		label={<Translate value={label} dangerousHTML/>}
		placeholder='JJ/MM/AAAA'
		id={props.id}
		value={props.data.birth_date}
		onChange={(e, val) => props.update('birth_date', val)}
		validators={['required', 'notInFuture', 'hasLegalAge', 'isItRealDate']}
		className={'extraLongLabel'}
		fromYear={moment().subtract(85, 'years').year()}
		toYear={moment().subtract(21, 'years').year()}
		update={props.update}
		updateField='birth_date'
		errorMessages={[I18n.t('step1.errors.needsToBeFilled'), I18n.t('step1.errors.incorrectDate')]}
		/>

};

let Insurer = (props) => {
	return <SelectField name='insurer' label={<Translate value='step2.chooseInsurer'/>}
	                    value={props.data.insurer}
	                    validators={['required']}
	                    errorMessages={['Cette information doit être renseignée']}
	                    onChange={(e) => props.update('insurer', e.target.value)}
	                    disabled={props.disabled}
	                    className={'mediumLabel'}
	>{SelectFieldItems(props.insurers)}</SelectField>;
};

let Coverage    = (props) => {
	let min   = 100 - (isNaN(parseInt(props.check.coverage)) ? 0 : parseInt(props.check.coverage));
	let label = props.label || 'generic.your';
	return <SelectField label={props.label}
	                    value={props.data.coverage}
	                    onChange={(e) => {
		                    props.update('coverage', e.target.value);
	                    }}
	                    disabled={props.disabled}
	                    validators={['required', 'minNumber:' + min]}
	                    errorMessages={['Cette information doit être renseignée', <Translate value='step2.coverageError'/>]}
	                    helperText={props.helperText || ''}
	>{SelectFieldItems(coverage)}</SelectField>;
};
let SCICoverage = (props) => {
	// let min   = 100 - (isNaN(parseInt(props.check.coverage)) ? 0 : parseInt(props.check.coverage));
	// let label = props.label || 'generic.your';
	return <NumberField
	                    value={props.data.coverage}
	                    onValueChange={(e, val) => props.update('coverage', val)}
	                    margin="normal"
	                    type='tel'
	                    name='coverage'
	                    suffix='%'
	                    className={'extraLongLabel'}
	                    validators={['required', 'isFloatRequired', 'maxFloatNumber: 100', 'minFloatNumber: 0']}
	                    errorMessages={['Cette information doit être renseignée', 'La valeur doit être un nombre', `La valeur ne doit pas être supérieure à 3 000 000 EUR`, `Le montant doit être compris entre 1 000 EUR et 3 000 000 EUR`]}
	                    label={props.label}
	                    placeholder='EUR'
	                    disabled={props.disabled}/>;
};

let GlobalInsurance = (props) => {
	return <NumberField fixedDecimalScale
	                    value={props.data[props.loan].global_insurance}
	                    onValueChange={(e, val) => props.update([props.loan, 'global_insurance'], val)}
	                    margin="normal"
	                    name='global_insurance'
	                    suffix='€'
	                    className={'extraLongLabel'}
	                    decimalScale={2}
	                    validators={!props.skip && ['required', 'isFloatRequired', 'maxFloatNumber: 300000', 'minFloatNumber: 1000']}
	                    errorMessages={['Cette information doit être renseignée', 'La valeur doit être un nombre', `La valeur ne doit pas être supérieure à 3 000 000 EUR`, `Le montant doit être compris entre 1 000 EUR et 3 000 000 EUR`]}
	                    label={<Translate value='step2.globalInsurance'/>}
	                    placeholder='EUR'
	                    disabled={props.disabled}
	/>;
};

let InsurancePremium = (props) => {
	return <NumberField fixedDecimalScale
	                    value={props.data[props.loan].insurance_premium}
	                    onValueChange={(e, val) => props.update([props.loan, 'insurance_premium'], val)}
	                    margin="normal"
	                    name='insurancePremium'
	                    suffix='€'
	                    decimalScale={2}
	                    validators={!props.skip && ['required', 'isFloatRequired', 'maxFloatNumber: 10000', 'minFloatNumber: 1']}
	                    errorMessages={['Cette information doit être renseignée', 'La valeur doit être un nombre', `La valeur ne doit pas être supérieure à 10 000 EUR`, `Le montant doit être compris entre 1 EUR et 10 000 EUR`]}
	                    label={<Translate value='step2.insurancePremium'/>}
	                    placeholder='EUR par mois'
	                    disabled={props.disabled}
	/>;
};

let InsuranceRate = (props) => {
	let name = props.name || 'insuranceRate';
	return <NumberField fixedDecimalScale
	                    value={props.data[props.loan][name]}
	                    onValueChange={(e, val) => props.update([props.loan, name], val)}
	                    margin="normal"
	                    name={name}
	                    suffix='%'
	                    decimalScale={3}
	                    validators={!props.skip && ['required', 'isFloatRequired', 'maxFloatNumber: 3.00', 'minFloatNumber: 0.01']}
	                    errorMessages={['Cette information doit être renseignée', 'La valeur doit être un nombre', `La valeur ne doit pas être supérieure à 3.00%`, `Le montant doit être compris entre 0.01% EUR et 3.00%`]}
	                    label={<Translate value={`step2.${name}`}/>}
	                    placeholder='% par an'
	                    disabled={props.disabled}
	                    helperText={props.helperText || ""}
	/>;
};

let Taea = (props) => {
	let name = props.name || 'taea';
	return <NumberField fixedDecimalScale
	                    value={props.data[props.loan][name]}
	                    onValueChange={(e, val) => props.update([props.loan, name], val)}
	                    margin="normal"
	                    name={name}
	                    suffix='%'
	                    decimalScale={3}
	                    validators={!props.skip && ['required', 'isFloatRequired', 'maxFloatNumber: 3.00', 'minFloatNumber: 0.09']}
	                    errorMessages={['Cette information doit être renseignée', 'La valeur doit être un nombre', `La valeur ne doit pas être supérieure à 3.00%`, `Le montant doit être compris entre 0.09% et 3.00%`]}
	                    label={<Translate value={`step2.${name}`}/>}
	                    placeholder='% par an'
	                    disabled={props.disabled}
	/>;
};

export {BirthDate, Insurer, Coverage, GlobalInsurance, InsurancePremium, InsuranceRate, Taea, SCICoverage};
