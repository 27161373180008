import {all_equivalences, steps} from "../data";
import history from "../../../history";
import React from "react";
import {isValidPhoneNumber} from "react-phone-number-input";
import moment from 'moment';
import {I18n} from "react-redux-i18n";

function numberWithSpaces(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function mapData(borrower, coborrower, loan, comparison = []) {
	let data = {
		borrower: {
			...borrower
		},
		coborrower: {},
		comparison,
		loan: {...loan}
	};

	let guid = localStorage.getItem('guid');

	if (guid) data.guid = guid;

	if (borrower.has_coborrower) {
		data.coborrower = {
			...coborrower
		};
	}

	return data;
}

function installment(props) {
	let array = []
	const num = n => parseFloat((n.replace(' ', '')).split(',').join('.'));
	for (let i = 0; i < props.installments.length; i++) {
		if (array[props.installments[i].year - 1]) {
			let result                            = num(array[props.installments[i].year - 1]) + num(props.installments[i].total);
			array[props.installments[i].year - 1] = result.toFixed(2)
		} else {
			array[props.installments[i].year - 1] = num(props.installments[i].total).toFixed(2)
		}
	}
	return array
}


function parseInstallments(props) {
	let data_obj = []
	let array    = installment(props)

	for (let i = 0; i < array.length; i++) {
		let newItem = {year: i + 1, total: array[i]}
		data_obj.push(newItem)
	}
	return data_obj
}

function restructureData(borrower, coborrower, loan) {
	let data = {
		state: {borrower, coborrower, loan},
		borrower: {
			// step: "sante"
		},
		coborrower: {},
		has_coborrower: !!borrower.has_coborrower, // TODO: This should be fixed
		loan: {},
	};

	['borrower', 'coborrower'].forEach(p => {
		let person = p === 'borrower' ? borrower : coborrower;

		if (p === 'borrower' || (p === 'coborrower' && borrower.has_coborrower && coborrower)) {
			data[p] = {
				first_name: person.first_name,
				last_name: person.last_name,
				question_one: person.question_one,
				question_two: person.question_two,
				question_three: person.question_three,
				question_four: person.question_four,
				question_five: person.question_five,
				additional_address: person.additional_address,
				birth_date: person.birth_date,
				coverage: person.coverage,
				email: person.email,
				phone: person.phone,
				birth_city: person.birth_city,
				zip: person.postalCode,
				profession: person.profession,
				risky_profession: person.risky_profession,
				salutation: person.salutation,
				smoker: person.smoker,
				address: person.address,
				city: person.city,
			}
		}

		if (p === 'borrower') {
			data[p].has_coborrower = !!borrower.has_coborrower;
		}
	});

	return data;

	// BORROWER-COBORROWER
	// hasCoborrower: true //
	// insurancePremium: ""
	// insuranceRate: ""
	// insurance_rates: []
	// is_political: false // TODO: It's not used anywhere...
	// taea: ""
}

function getParameterByName(name, url) {
	name        = name.replace(/[[]]/g, '\\$&');
	let regex   = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
	    results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function parseJwt(token) {
	let base64Url   = token.split('.')[1];
	let base64      = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
}

function toFrenchNumber(n, no_round = false) {
	let parsed = no_round ? (Math.trunc(n * 100) / 100) : parseFloat(n);
	return parsed.toFixed(2).toString().replace(' ', '').replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',')
}

function getCookie(name) {
	let dc     = document.cookie;
	let prefix = 'automation_test=';
	let begin  = dc.indexOf('; ' + prefix);
	if (begin === -1) {
		begin = dc.indexOf(prefix);
		if (begin !== 0) return null;
	} else {
		begin += 2;
		var end = document.cookie.indexOf(';', begin);
		if (end === -1) {
			end = dc.length;
		}
	}
	return decodeURI(dc.substring(begin + prefix.length, end));
}

/**
 * @return {boolean}
 */
function AutomationTest() {
	console.log('Captcha', process.env.NODE_ENV, process.env.REACT_APP_ENV);
	if (['development', 'uat', 'STAGE'].indexOf(process.env.NODE_ENV) !== -1 ||
		['development', 'uat', 'STAGE'].indexOf(process.env.REACT_APP_ENV) !== -1
	) return true;

	if (['maisonetappart.wedou.fr', 'omniiz.wedou.fr'].indexOf(window.location.host) !== -1) return true;

	let getToken = getCookie('automation_test');
	if (getToken) {
		let decode = parseJwt(getToken);
		if (decode.name === 'Gr33n' && decode.value === 'f0r3s7') {
			return true;
		}
	} else return false;
}

function randomString(length) {
	const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
	let result    = '';

	while (length > 0) {
		const bytes  = new Uint8Array(16);
		const random = window.crypto.getRandomValues(bytes);

		random.forEach((c) => {
			if (length === 0) {
				return;
			}
			if (c < charset.length) {
				result += charset[c];
				length--;
			}
		});
	}
	return result;
}

function goBack(flow) {
	let entry = {};
	console.log(steps[flow], flow, 'meps molow')
	entry     = steps[flow].find(step => step.page === window.location.pathname);
	if (sessionStorage.getItem('hqp') === 'disabled' && window.location.pathname.indexOf('/resume') !== -1) {
		entry = {
			page: '/sante',
			back: "/profession"
		}
	}

	if (window.location.pathname === '/rendez-vous') {
		window.history.back();
	} else if (entry && entry.back !== null) {
		history.push(entry.back);
	}

}

function phoneValidation(phone, format = false) {
	phone                = (phone + '').replace(/\s|-/g, '');
	let is_valid_phone   = isValidPhoneNumber(phone);
	let is_french_mobile = false;

	if (['06', '07'].some(b => phone.indexOf(b) === 0 && phone.length === 10)) {
		is_french_mobile = true;
		if (format) {
			phone = phone.replace(/^.{1}/g, '+33');
		}
	} else if (['336', '337'].some(b => phone.indexOf(b) === 0 && phone.length === 11)) {
		is_french_mobile = true;
		if (format) {
			phone = phone.replace(/^.{2}/g, '+33');
		}
	} else if (['+336', '+337'].some(b => phone.indexOf(b) === 0 && phone.length === 12)) {
		is_french_mobile = true;
	} else if (phone.indexOf('+359') === 0 && phone.length === 13) {
		is_french_mobile = true; //Almost French... but eh.
	} else if (phone.indexOf('+32') === 0 && (phone.length === 12 || phone.length === 13)) {
		is_french_mobile = true; //Almost French... but eh.
	}
	return {check: is_valid_phone && is_french_mobile, phone: phone || ''};
}

function PMT(total_amount, interest_rate, months) {
	if (interest_rate > 0) {
		let R = parseFloat(interest_rate) / 100 / 12; //monthly interest rate percent + taea if present
		let N = parseInt(months); //total duration of loan in months
		let P = parseInt(total_amount); //total amount of the loan
		return (P * R) / (1 - Math.pow(1 + R, -N));
	} else {
		return parseInt(total_amount) / (parseInt(months));
	}
}

function remainingCapital(total_amount, interest_rate = 0, months, contract_date) {
	let monthly        = PMT(total_amount, interest_rate, months);
	let months_passed  = moment(new Date()).diff(moment(contract_date, 'DD/MM/YYYY'), 'month');
	let ending_balance = total_amount;
	let interest       = 0;
	let principal      = 0;

	for (let i = 0; i < months_passed; i++) {
		interest       = ending_balance * ((interest_rate / 100) / 12);
		principal      = monthly - interest;
		ending_balance = ending_balance - principal;
	}
	return ending_balance;
}

function calculateGuarantees(props, all_equivalences) {
	props.borrower.csp    = props.borrower.csp || 1;
	let csp_check         = eq => (props.flow === 'afi-esca' ? eq.csp_limit >= props.borrower.csp : true);
	let no_disability     = props.loan.project_type === 'investmentForRenting' || ['sansProfession', 'interimaire', 'preRetraite'].indexOf(props.borrower.profession) !== -1;
	let bank_equivalences = props.config.calculation.equivalence || {};
	let equivalences      = all_equivalences.filter(eq => !eq.include_only || eq.include_only.includes(bank_equivalences[eq.key]));
	const borrower_age    = moment().diff(moment(props.borrower.birth_date, 'DD/MM/YYYY'), 'years');
	let bank_guarantees   = equivalences.filter(eq => props.config.calculation && bank_equivalences && bank_equivalences[eq.key] && (borrower_age < eq.ageLimit) && csp_check(eq));
	let extra_guarantees  = equivalences.filter(eq => props.config.calculation && bank_equivalences && !bank_equivalences[eq.key] && (borrower_age < eq.ageLimit) && !eq.bankOnlyRule && csp_check(eq));

	function addPremium(premium_key) {
		let mno          = all_equivalences.find(eq => eq.key === premium_key && eq.include_only.includes(1));
		extra_guarantees = extra_guarantees.filter(eq => eq.key !== premium_key);
		bank_guarantees  = bank_guarantees.filter(eq => eq.key !== premium_key);
		extra_guarantees.push(mno);
	}

	if (borrower_age < 63 && props.borrower.csp <= 3) {
		//TODO: Don't cover MNO if ITT or IPT is not covered
		// When one of back or psychological illness is covered without condition the other one should also be covered without condition
		if (bank_equivalences['incap_back'] === 1 && bank_equivalences['incap_psychological'] !== 1) addPremium('incap_psychological');
		if (bank_equivalences['incap_back'] !== 1 && bank_equivalences['incap_psychological'] === 1) addPremium('incap_back');
		//
		// 	// Afi-Esca always covers MNO without condition.
		if (props.flow === 'afi-esca' && bank_equivalences.itt_covered) {
			if (bank_equivalences['incap_psychological'] !== 1) {
				addPremium('incap_psychological')
			}
			if (bank_equivalences['incap_back'] !== 1) {
				addPremium('incap_back')
			}
		}
	}
	// When project type is investment for renting only DC and PTIA are covered. Wedou always covers sport and travel
	if (no_disability) bank_guarantees = bank_guarantees.filter(p => (['death_covered', 'ptia_covered', 'sport_covered', 'travel_professional'].includes(p.key)));
	if (no_disability) extra_guarantees = extra_guarantees.filter(p => (['sport_covered', 'travel_professional'].includes(p.key)));

	return {bank_guarantees, extra_guarantees}
}

function parseDate(date) {
	if (date) {
		let birth_date = date;
		let delimiter  = date.indexOf('-') !== -1 ? '-' : '/';
		let format     = ['DD', 'MM', 'YYYY'];
		if (date.split(delimiter)[0].length === 4) {
			format = ['YYYY', 'MM', 'DD'];
		}

		return moment(birth_date, format.join(delimiter)).format('DD/MM/YYYY');
	} else {
		return date;
	}
}

function deepJsonDiff(o1, o2) {
	let diff = {};
	let tmp  = null;
	if (JSON.stringify(o1) === JSON.stringify(o2)) return;

	for (let k in o1) {
		if (Array.isArray(o1[k]) && Array.isArray(o2[k])) {
			tmp = o1[k].reduce(function (p, c, i) {
				let _t = deepJsonDiff(c, o2[k][i]);
				if (_t)
					p[i] = _t;
				return p;
			}, []);
			if (Object.keys(tmp).length > 0)
				diff[k] = tmp;
		} else if (typeof (o1[k]) === "object" && typeof (o2[k]) === "object") {
			tmp = deepJsonDiff(o1[k], o2[k]);
			if (tmp && Object.keys(tmp) > 0)
				diff[k] = tmp;
		} else if (o1[k] !== o2[k]) {
			diff[k] = o2[k]
		}
	}
	return Object.keys(diff).length > 0 ? diff : null;
}

function checkNatexoPixelFire(personal) {
	personal = personal || false;

	let source = sessionStorage.getItem('utm_source');
	let fired  = sessionStorage.getItem('natexo_retargeting');

	if (source === 'natexo' && ((!personal && !fired) || (personal && fired !== 'personal'))) {
		let script  = document.createElement('script');
		script.type = 'text/javascript';
		script.src  = "https://d.natexo-programmatic.com/compiled/LdrRtgJs.js?u=769&c=fr" + (personal ? "&e=lead" : "");
		document.body.appendChild(script);
		sessionStorage.setItem('natexo_retargeting', personal ? 'personal' : 'initial');
	}
}

function getLanguage() {
	let lang = 'fr';
	switch (window.location.hostname) {
		case 'app.wedou.bg':
			lang = 'bg';
			break;
		case 'app.wedou.care':
			lang = 'en';
			break;
		case 'es.wedou.care':
			lang = 'es';
			break;
		default:
			lang = 'fr';
			break;
	}
	return lang;
}

function getBackendUrl(lang = getLanguage()) {
	return process.env['REACT_APP_API_URL_' + lang.toUpperCase()];
}

function getWebsiteCountry(lang = 'fr') {
	switch (lang) {
		case 'es':
			return 'Spain';
		case 'bg':
			return 'Bulgaria';
		case 'en':
			return 'United Kingdom';
		default:
			return 'France'
	}
}

function translateString(translation) {
	if (I18n.t(translation).split(".").pop() === translation.split(".").pop()) {
		return null
	} else {
		return I18n.t(translation);
	}
}

export {
	numberWithSpaces,
	mapData,
	restructureData,
	getParameterByName,
	parseJwt,
	AutomationTest,
	randomString,
	goBack,
	phoneValidation,
	PMT,
	parseDate,
	remainingCapital,
	deepJsonDiff,
	installment,
	toFrenchNumber,
	calculateGuarantees,
	parseInstallments,
	checkNatexoPixelFire,
	getLanguage,
	getBackendUrl,
	translateString,
	getWebsiteCountry
};
