import React from 'react'
import {StringField} from "../../components/Form/Fields/index";
import {ValidatorForm} from 'react-material-ui-form-validator';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import FormButton from '../../components/Form/FormButton';
import moment from 'moment'
import './styles.css'

class Person_info extends React.Component {

    constructor(props) {
		super(props);

		this.state = {
            chosen_date: moment(this.props.chosenDate).format('DD/MM/YYYY'),
            chosen_hour: this.props.chosenHours
		};
    }

    submit() {
    }

    getStep() {
		return null;
	}

    render(){
        return(
            <div className='body'>
                <ValidatorForm>
                    <div className='container-info'>
                            <div className='label-info'>Parfait, nous avons bien noté le {this.state.chosen_date} à {this.state.chosen_hour}.</div>
                            <div className='label1-info'>Quel est votre numéro ?</div>
                        <div>
                            <StringField
                                label='Téléphone'
                                // onChange={(e) => this.props.updateBorrower('phone', e.target.value)}
                                // value={this.props.borrower.phone}
                                validators={['required']}
		                        errorMessages={['Cette information doit être renseignée']}
                            />
                        </div>
                        <div>
                            <StringField
                                label='Email'
                                onChange={(e) => this.props.updateBorrower('email', e.target.value)}
                                value={this.props.borrower.email}
                                validators={['required']}
		                        errorMessages={['Cette information doit être renseignée']}
                            />
                        </div>
                        <div>
                            <FormButton id='suivant-job' onClick={this.submit} value='Suivant' />
                        </div>
                    </div>
                </ValidatorForm>
            </div>
        )
    }
}

export default (Person_info);
