import {Col, Row} from "react-grid-system";
import style from "./style.module.css";
import React from "react";
import {calculateGuarantees} from "../../js/helpers/functions";
import {all_equivalences} from "../../js/helpers/data";
import ReactTooltip from "react-tooltip";
import { isMobile } from "react-device-detect";

class BankGuarantees extends React.Component {

	chunkArray      = (bank_guarantees) => {
		let chunked = [];
		while (bank_guarantees.length) {
			chunked.push(bank_guarantees.splice(0, 3));
		}

		return chunked;
	}

	showTable = (guaranties) => {
		return (<div style={{width: isMobile && '100%'}}>
						{guaranties.map(guarantie => {
							return (
							<>
								<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
									<Row style={{marginBottom:'2vh'}}>
										<Col className={style.guarantie}>
											<i style={{color: this.props.config.theme.primary}} className={`fa fa-check ${style.icon}`}/>
											{guarantie.name[this.props.translations.locale]}
											<i style={{color: this.props.config.theme.primary}} className={`fa fa-info ${style.icon}`} data-tip={guarantie.tip} data-multiline='true'/>
										</Col>
									</Row>
							</>
							)
						})}
					</div>)
	}

	guarantiesParse = () => {
		let {bank_guarantees} = calculateGuarantees(this.props, all_equivalences);
		let {extra_guarantees} = calculateGuarantees(this.props, all_equivalences);
		let mergeArrays = [...bank_guarantees, ...extra_guarantees]

		let chunks = this.chunkArray(mergeArrays)
		return (
			<div className="installments-table-wrapper">
				{chunks.map(guaranties => {
					return (
						this.showTable(guaranties)
					)
				})}
			</div>
		)
	}

	render() {
		return <div>
			{this.guarantiesParse()}
		</div>;
	}
}

export default BankGuarantees;
