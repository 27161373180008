import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";
import style from './style.css';
import Translate from "components/CustomTranslate";
import StandardButton from "../../components/Form/StandardButton";
import {Profession} from "../../components/Form/StepsFields/step5";
import {CustomValidatorForm} from "../../components/Form";
import RadioGroup from "@material-ui/core/RadioGroup";
import {DateField, NumberField, SelectField, SelectFieldItems, YesNoRadio} from "../../components/Form/Fields";
import {getBackendUrl, remainingCapital} from "../../js/helpers/functions";
import Switch from "react-switch";
import checkedIcon from "../../components/Form/InterestField/ptz.png";
import {BirthDate} from "../../components/Form/StepsFields/step2";
import {Slider} from "@material-ui/core";
import {ButtonGroup} from "@material-ui/core";
import {Button} from "@material-ui/core";
import {FormControlLabel} from "@material-ui/core";
import {Radio} from "@material-ui/core";
import Checkout from './Payments/checkout';
import successImage from './payment_success.svg';
import success from "../BravoProcess/success";
import history from "../../history";
import {post} from "../../js/helpers/rest";

var I18n = require('react-redux-i18n').I18n;

class IncomePaymentSuccess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}


	getStep() {
		return 'income_step_one';
	}

	render() {

		return (
			<div>
				<Container style={{color: '#000', textAlign: 'center', width: '90%',}}>
					<CustomValidatorForm>
						<Row justify="center" className="incomeRow">
							<Col md={8} style={{paddingTop: '20px', textAlign: 'center', fontWeight: 600, fontSize: '35px'}}>
								<p>И готово, вече сте наш клиент!</p>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={8} style={{paddingTop: '20px', textAlign: 'left'}}>
								<img src={successImage}/>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={8} style={{paddingTop: '20px', textAlign: 'center'}}>
								<StandardButton value="Към личното Ви пространство" onClick={async () => {
									post({}, getBackendUrl() + '/customer/add_certificate');
									await this.props.getContractData();
									history.push('/income_space')
								}}/>
							</Col>
						</Row>
					</CustomValidatorForm>
				</Container>
			</div>
		)
	}

}

export default connect()

(
	Shell(IncomePaymentSuccess)
)
;
