import React from 'react';
import Button from '@material-ui/core/Button';
import './style.css';
import {connect} from "react-redux";

class FormButton extends React.Component {

	render() {
		return (
			<Button {...this.props}
			        style={this.props.invertedColour ? {backgroundColor: "white", borderRadius: '26px'} : {backgroundColor: this.props.config.theme.primary, borderRadius: '26px'}}
					type='submit'
					className={`formButton ${this.props.className || ''}`}
					id={this.props.id ? this.props.id : 'buttonForConfirm'}
			>
				{this.props.value}
			</Button>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		config: state.rootReducer.config,
	};
};
export default connect(mapStateToProps)(FormButton);
