import React from "react";
import {Row, Col} from 'react-grid-system';
import Translate from "components/CustomTranslate";
import Paper from '@material-ui/core/Paper';
import history from "../../../history";
import moment from 'moment';
import './style.css';
import connect from "react-redux/es/connect/connect";
import Shell from "../../../components/Shell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormButton from "../../../components/Form/FormButton";
import Modal from "../../../components/Modal";
import {StringField} from "../../../components/Form/Fields";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {TiTick} from 'react-icons/ti';
import {FaSms, FaMailBulk, FaCheck} from 'react-icons/fa';
import {ValidatorForm} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import {PersonalSpaceHeader} from "../../../components/Header/PersonalSpaceHeader";
import {phoneValidation} from "../../../js/helpers/functions";
import AffiliateButtons from "../Partials/affiliate_buttons";
import {isMobile} from "react-device-detect";
const phases = {
	"Documentation": "En attente de documents",
	"InsurerHealthSelection": "En sélection médicale",
	"Finalization": "Contrat en cours",
	"UnsignedHealthSelection": "Questionnaire de risque incomplet",
	"Substitution": "Substitution",
	"CoborrowerSignature": "Signature du coemprunteur"
}

class Home extends React.Component {

	constructor() {
		super();

		this.state = {
			dialogOpen: false,
			renunciation_step: 1,
			renunciation_reason: '',
			renunciation_suggestion: '',
		};
	}

	getStep() {
		return 'personal.home';
	}


	render() {
		let finished = this.props.attachments.borrower.id_document &&
			this.props.attachments.borrower.contract &&
			this.props.attachments.borrower.isp;

		let documents = this.props.config.contract ? this.props.config.contract.map(contract => {
			return {
				name: "Wedou Emprunteur N°" + contract.id,
				signDate: moment(contract.sign_date, 'YYYY/MM/DD').format('DD/MM/YYYY'),
				state: contract.phase,
				bundle_sent: contract.bundle_sent
			};
		}) : [];

		let show_renunciation = false;
		if (documents && documents[0]) {
			if (documents[0].state !== 'Renonciation' && moment().diff(moment(documents[0].signDate, 'DD/MM/YYYY'), 'days') < 30) {
				show_renunciation = true;
			}
		}
		return (
			<>
				<div className="personalFrame">
					<PersonalSpaceHeader
						signed={this.props.borrower.has_signed}
						contract={this.props.config.contract}
						health_selection={this.props.borrower.health_selection}
						showResigning={this.props.attachments.documents_for_resigning && this.props.attachments.documents_for_resigning.length > 0}
						title={'personal.home.myContacts'}/>
					<div style={{backgroundColor: this.props.config.theme.primary}} className='personalHomeFrame'>
						{!!finished && (
							<p className='paper light'>
								<i style={{color: this.props.config.theme.primary}} className="fa fa-info-circle question-icon"/>
								<Translate value="personalSpace.finishedApplication" dangerousHTML/>
							</p>
						)}
						{!finished && (
							<p className='paper light'>
								<TiTick className="reactIcons tickIcon" size={25}/>
								<Translate value="personalSpace.finishApplication" dangerousHTML/>
							</p>
						)}
					</div>
					{/*<Paper className='personalPaper paperRows'>*/}
					{/*	{documents.length === 0 ?*/}
					{/*		<Row>*/}
					{/*			<Col md={5}><p>No contracts</p></Col>*/}
					{/*		</Row> :*/}
					{/*		documents.map((item) => {*/}
					{/*			return (*/}
					{/*				<Row key={item.name}>*/}
					{/*					<Col md={5}><p>{item.name}</p></Col>*/}
					{/*					<Col md={4}><p>{item.signDate}</p></Col>*/}
					{/*					<Col md={3}><p>{item.state}</p></Col>*/}
					{/*				</Row>*/}
					{/*			)*/}
					{/*		})*/}
					{/*	}*/}
					{/*</Paper>*/}

					<Paper className='personalPaper' style={{padding: '15px'}}>
						{isMobile ? documents.map((item) => {
								return (
									<div style={{fontSize: '12px', padding: '7px', color: '#00000B'}}>
										<Row justify="center" style={{marginBottom: '15px', paddingBottom: '10px', marginTop: '15px', borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
											<Col>
												<b>Numéro de contrat</b>
											</Col>
											<Col>
												{item.name}
											</Col>
										</Row>
										<Row style={{marginBottom: '15px', paddingBottom: '10px', borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
											<Col>
												<b>Date de signature</b>
											</Col>
											<Col>
												{item.signDate}
											</Col>
										</Row>
										<Row style={{paddingBottom: '10px'}}>
											<Col>
												<b>État du contrat</b>
											</Col>
											<Col>
												{item.state === 'Bravo' ? 'Documentation' : (phases[item.state] || item.state)}
											</Col>
										</Row>
									</div>
								)
							}) :
							<Table className='documentsTable'>
								<TableBody className='documentsBody' stripedRows showRowHover>
									<TableRow>
										<TableCell style={{textAlign: 'center'}}><b>Numéro de contrat</b></TableCell>
										<TableCell style={{textAlign: 'center'}}><b>Date de signature</b></TableCell>
										<TableCell style={{textAlign: 'center'}}><b>État du contrat</b></TableCell>

									</TableRow>
									{documents.length === 0 ?
										<TableRow>
											<TableCell><p>Pas de contrats</p></TableCell>
										</TableRow> :
										documents.map((item) => {
											return (
												<TableRow key={item.name}>
													<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{item.name}</TableCell>
													<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{item.signDate}</TableCell>
													<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{item.state === 'Bravo' ? 'Documentation' : (phases[item.state] || item.state)}</TableCell>
												</TableRow>
											)
										})
									}
								</TableBody>
							</Table>
						}

					</Paper>

					{this.props.config.contract && this.props.config.contract[0].bundle_sent &&
						<p>Demande de substitution adressée à la banque le {moment(this.props.config.contract[0].bundle_sent).format('DD/MM/YYYY')}</p>}


					<Modal minWidth="lg" maxWidth="lg" title={<Translate value='personal.resignate'/>}
					       dialogOpen={this.state.dialogOpen}
					       config={this.props.config}
					       onClose={() => this.setState({dialogOpen: false, renunciation_step: 1})}>
						{this.resignationForm()}
					</Modal>
				</div>

				<AffiliateButtons getAffiliateData={this.props.getAffiliateData} affiliateInvitation={this.props.affiliateInvitation}/>

				<div>
					{show_renunciation && <span className='renunciaton' onClick={() => {
						this.setState({dialogOpen: true})
					}}><Translate value='personal.resignate'/></span>}
				</div>
			</>
		)
	}

	resignationForm() {
		return <ValidatorForm onSubmit={() => {
			this.props.sendRenunciation({
				email: this.props.borrower.email,
				renunciation_reason: this.state.renunciation_reason,
				renunciation_verbal: this.state.renunciation_verbal,
				renunciation_suggestion: this.state.renunciation_suggestion,
			})
				.then(() => {
					window.location.reload();
				})
				.catch(console.error);
		}}>
			{this.state.renunciation_step === 1 ? <div>
					<Row justify="center">
						<Col>
							<p>Vous souhaitez vraiment renoncer à vos économies ?</p>
						</Col>
					</Row>
					<Row justify="center">
						<Col md={6}>
							<FormButton value={<Translate value='generic.yes'/>} onClick={() => this.setState({renunciation_step: 2})}/>
						</Col>
						<Col md={6}>
							<FormButton value={<Translate value='generic.no'/>} onClick={() => this.setState({dialogOpen: false, renunciation_step: 1})}/>
						</Col>
					</Row>
				</div> :
				<div>
					<Row justify="center">
						<Col md={10} className="centeredText">
							<p><b>Pour quelle raison souhaitez-vous renoncer ?</b></p>
						</Col>
					</Row>
					<Row justify="center">
						<Col md={6}>
							<RadioGroup className='radioGroup resignationQuestions'
							            name='renunciation_reason'
							            value={this.state.renunciation_reason}
							            onChange={(event, value) => this.setState({renunciation_reason: value})}
							>
								<FormControlLabel
									className='radioButton'
									control={<Radio required/>}
									value="J’hésite encore"
									label="J’hésite encore"/>
								<FormControlLabel
									className='radioButton'
									control={<Radio required/>}
									value='Ma banque m’a retenu'
									label="Ma banque m’a retenu"/>
								<FormControlLabel
									className='radioButton'
									control={<Radio required/>}
									value='Je vais vendre mon bien'
									label="Je vais vendre mon bien"/>
								<FormControlLabel
									className='radioButton'
									control={<Radio required/>}
									value='Je ne connais pas Wedou'
									label="Je ne connais pas Wedou"/>
								<FormControlLabel
									className='radioButton'
									control={<Radio required/>}
									value='L’assureur choisi ne me convient pas'
									label="L’assureur choisi ne me convient pas"/>
								<FormControlLabel
									className='radioButton'
									control={<Radio required/>}
									value='Autre'
									label="Autre"/>
							</RadioGroup>
						</Col>
					</Row>
					<Row justify="center">
						<Col md={10} className="centeredText">
							<p><b>Vos suggestions pour faire mieux :</b></p>
						</Col>
					</Row>
					<Row justify="center">
						<Col md={8}>
							<StringField value={this.state.renunciation_suggestion}
							             onChange={(e) => this.setState({renunciation_suggestion: e.target.value})}/>
						</Col>
						<Col md={8}>
							<FormButton value="Renoncer"/>
						</Col>
					</Row>
				</div>
			}
		</ValidatorForm>;
	}

}

export default connect()(Shell(Home));
