import {FaCheck, FaMailBulk, FaSms} from "react-icons/fa";
import {ValidatorForm} from "react-material-ui-form-validator";
import {Col, Row} from "react-grid-system";
import {StringField} from "../../../components/Form/Fields";
import {phoneValidation} from "../../../js/helpers/functions";
import Translate from "../../../components/CustomTranslate";
import Button from "@material-ui/core/Button";
import history from "../../../history";
import React from "react";
import FormButton from "../../../components/Form/FormButton";
import Modal from "../../../components/Modal";
import Friends from "./friends.png";
import Check from "./check.png";
import Circle from "./circle.png";
import * as style from "./affiliate_buttons.module.css";

export default class AffiliateButtons extends React.Component {

	constructor() {
		super();

		this.state = {
			phone: '',
			email: '',
			sent: false,
			sentStatus: null,
			affiliateOpen: false,
			affiliateSendType: 'sms',
			currentButtonText: 0,
		};
	}

	render() {
		return <>
			<Row justify="center" className="align-items-center" style={{marginTop: '40px'}}>
				{/*<Col md={2.5}/>*/}
				<Col md={6} lg={4}>
					<img src={Circle} alt="Light colored circle background for text." className={style.circle} />
					<h2><Translate value='personal.affiliate.affiliateInfoHeader'/></h2>
					<p className={style.listEntry}><Translate value='personal.affiliate.affiliateInfoParagraph1'/></p>
					<p className={style.listEntry}><Translate value='personal.affiliate.affiliateInfoParagraph2'/></p>
					<p className={style.listEntry}><Translate value='personal.affiliate.affiliateInfoParagraph3'/></p>
				</Col>
				<Col md={3} style={{alignSelf: "center"}}>
					<FormButton value={<p style={{padding: "15px 5px"}}><FaMailBulk style={{fontSize: '35px', marginLeft: '10px'}}/> <br/> Parrainez vos amis <br/> par Email</p>}
					            className={style.affiliateButtons} onClick={() => {
						            this.setState({affiliateOpen: true, affiliateSendType: 'email'});
					            }}/>
				</Col>
				<Col md={3} style={{alignSelf: "center"}}>
					<FormButton value={<p style={{padding: "15px 5px"}}><FaSms style={{fontSize: '35px', marginLeft: '10px'}}/> <br/> Parrainez vos amis <br/> par SMS</p>}
					            className={style.affiliateButtons} onClick={() => {
						            this.setState({affiliateOpen: true, affiliateSendType: 'sms'});
					            }}/>
				</Col>
				{/*<Col md={2.5}/>*/}
			</Row>

			<Modal minWidth="lg" maxWidth="lg" title={<Translate value='personal.header.affiliates'/>}
			       dialogOpen={this.state.affiliateOpen}
			       config={this.props.config}
			       image={Friends}
			       imageStyle={style.popupImageStyle}
			       onClose={() => this.setState({affiliateOpen: false})}>
				{this.affiliateShare()}
			</Modal>
		</>
	}

	affiliateShare() {
		let sendButtonTexts = [
			<Translate value='personal.affiliate.sendButton'/>,
			<FaCheck style={{fontSize: '18px', color: 'green'}}/>
		];

		return <ValidatorForm onSubmit={(e) => {
			e.preventDefault();
			this.sendAffiliateInvite(this.state.affiliateSendType, this.state.phone, this.state.email)
		}}>
			<div>
				<Row justify="center">
					<Col md={10}>
						<p className={"centeredText"} style={{fontSize: '18px'}}>
							<Translate value='personal.affiliate.buttonsHelper'/>
						</p>
					</Col>
				</Row>
				<Row justify="center">
					<Col md={10}>
						<p className={style.popupRewardText}>
							<Translate value='personal.affiliate.rewardInfo'/>
						</p>
					</Col>
				</Row>
				<Row justify="center" align="end">
					{this.state.affiliateSendType === 'sms' ?
						<Col md={7}>
							<StringField
								value={phoneValidation(this.state.phone, true).phone}
								onChange={e => {
									let format = phoneValidation(e.target.value, true);
									this.setState({phone: format.phone});
								}}
								name='phone'
								helperText={null}
								error={false}
								type={'tel'}
								// className='extraLongLabel'
								className={''}
								validators={['required', 'isMobileNumber']}
								errorMessages={['Cette information doit être renseignée', 'Ceci n\'est pas un n° de téléphone français.']}
								label={<Translate value='step9.phone'/>}
								placeholder='+33'
								disabled={this.state.currentButtonText !== 0}
							/>
						</Col> : <Col md={7}>
							<StringField
								label={<Translate value='step3.email'/>}
								onChange={(e) => this.setState({email: e.target.value})}
								value={this.state.email}
								name='email'
								validators={['required', 'isEmail']}
								className='longLabel'
								errorMessages={['Cette information doit être renseignée', 'L\'e-mail est incorrect']}
								disabled={this.state.currentButtonText !== 0}
								margin="normal"
							/>
						</Col>
					}
					<Col md={4} justifyContent="center">
						<Button style={{marginBottom: '16px', width: '100%', padding: "14px 7px", background: "rgb(246, 182, 13)", color: "#FFF", borderRadius: "30px"}} type='submit'>
							{sendButtonTexts[this.state.currentButtonText]}
						</Button>
					</Col>
				</Row>
				{this.state.sent && <Row justify="center">
					<Col md={10}>
						{this.state.sentStatus ?
							<p className='centeredText successMessage'>Expédié !</p>
							:
							<p className='centeredText errorMessage'>Une erreur s'est produite !</p>
						}
					</Col>
				</Row>}
				<Row justify="center">
					<Col md={10} className="centeredText">
						<a style={{fontSize: '16px'}} onClick={(e) => {
							e.preventDefault();
							this.setState({affiliateOpen: false});
							history.push('/personal_affiliate');
						}}><Translate value='personal.affiliate.viewAffiliates'/></a>
					</Col>
				</Row>
			</div>
		</ValidatorForm>;
	}

	sendAffiliateInvite(type, phone, email) {
		if (this.state.currentButtonText === 1) return;

		this.setState({currentButtonText: 1});

		setTimeout(() => {
			this.setState({currentButtonText: 0});
		}, 2 * 1000);

		this.props.affiliateInvitation({
			type: type,
			phone: phone,
			email: email
		}).then(() => {
			this.setState({sent: true, sentStatus: 'success'});
			this.props.getAffiliateData();
		}).catch((e) => {
			console.log(e);
			this.setState({sent: true, sentStatus: 'error'});
		}).then(() => {
			setTimeout(() => {
				this.setState({
					sent: false,
					sentStatus: null,
					phone: this.state.affiliateSendType === 'sms' ? '' : this.state.phone,
					email: this.state.affiliateSendType === 'email' ? '' : this.state.email
				});
			}, 2 * 1000);
		});
	}
}