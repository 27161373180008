import React from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-grid-system';
import FormButton from "../../components/Form/FormButton";
import Translate from "components/CustomTranslate";
import {ValidatorForm} from 'react-material-ui-form-validator';
import moment from 'moment';

import './style.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
	Coverage,
	GlobalInsurance,
	InsurancePremium,
	InsuranceRate,
	Taea
} from '../../components/Form/StepsFields/step2';

import {mapData, numberWithSpaces} from '../../js/helpers/functions'
import ReactTooltip from "react-tooltip";
import {isMobile} from "react-device-detect";

class InsuranceInfo extends React.Component {

	getStep() {
		return 'step2';
	}

	isBeforeEdge() {
		let date = moment(this.props.loan.contract_date, 'DD/MM/YYYY');
		let edge = moment('01/10/2015', 'DD/MM/YYYY');

		return date.isBefore(edge);
	}

	renderLoanData() {
		let rows = [];
		for (let i = 0; i < this.props.loan.total_loans; i++) {
			rows.push(
				<div key={'loan-' + i}>
					{this.props.loan.total_loans !== 1 &&
					<Row justify='center' className='loanSection'>
						<p>
							<span><Translate value='generic.loan'/> {i + 1}:</span>
							<span style={{color: this.props.config.theme.primary}} className='loanAmount'>
								{numberWithSpaces(this.props.loan.loans[i].amount)} €
							</span>
						</p>
					</Row>}
					<Row key={'loan-data-' + i} justify='center' className='firstRow'>
						{(this.props.loan.chosen_input === 'global_insurance') && (
							<Col md={6}>
								<GlobalInsurance data={this.props.loan.loans} update={this.props.updateLoan} loan={i}/>
							</Col>
						)}

						{(this.props.loan.chosen_input === 'insurance_premium') && (
							<Col md={6}>
								<InsurancePremium data={this.props.loan.loans} update={this.props.updateLoan} loan={i}/>
							</Col>
						)}

						{(this.props.loan.chosen_input === 'insurance_rate' && this.isBeforeEdge()) && (
							<Col md={6}>
								<InsuranceRate name="insuranceRate" data={this.props.loan.loans} update={this.props.updateLoan} loan={i}
											   helperText={this.props.borrower.has_coborrower ? <Translate value='generic.your'/> : ''} />
							</Col>
						)}
						{(this.props.loan.chosen_input === 'taea' && !this.isBeforeEdge()) && (
							<Col md={6}>
								<Taea name="taea" data={this.props.loan.loans} update={this.props.updateLoan} loan={i}/>
							</Col>
						)}

						{(!!this.props.borrower.has_coborrower && this.props.loan.chosen_input === 'insuranceRate' && this.isBeforeEdge()) && (
							<Col md={6}>
								<InsuranceRate name="coInsuranceRate" data={this.props.loan.loans} update={this.props.updateLoan} loan={i}
											   helperText={<Translate value='generic.ofCoborrower'/>} />
							</Col>
						)}
						{(!!this.props.borrower.has_coborrower && this.props.loan.chosen_input === 'taea' && !this.isBeforeEdge()) && (
							<Col md={6}>
								<Taea name="coTaea" data={this.props.loan.loans} update={this.props.updateLoan} loan={i}/>
							</Col>
						)}
					</Row>
				</div>
			);
		}
		return rows;
	}

	render() {

		return (
			<div className={isMobile ? 'mobileStep' : 'insurancePopup'}>
				<ValidatorForm onSubmit={() => {
					this.props.updateConfig('loading', true);
					this.props.insuranceCalculation(
						mapData(this.props.borrower, this.props.coborrower, this.props.loan)
					).then((data) => {
						this.props.updateConfig('loading', false);
						this.props.updateOdometers();
						this.props.popupClose();
						// history.push('/resultat');
					});
				}}>
					<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip" multiline={true}/>

					<Row justify='center' className="sectionHeading">
						<Col md={12} style={{marginTop: "13px"}}>
							<p><Translate value='sections.3'/></p>
						</Col>
					</Row>

					{/*Row justify='center'>*/}
					{/*<Col md={6}>*/}
					{/*<Insurer data={this.props.loan} insurers={this.props.config.insurers} update={this.props.updateLoan}/>*/}
					{/*</Col>*/}
					{/*</Row>*/}

					{/*<Row justify={'center'}>*/}
					{/*<Col>*/}
					{/*<p className='centeredText'><Translate value='step2.inputOfChoice'/></p>*/}
					{/*</Col>*/}
					{/*</Row>*/}

					<Row justify='center'>
						<RadioGroup
							className='radioGroup'
							name="chosen_input"
							value={this.props.loan.chosen_input}
							onChange={(e, value) => this.props.updateLoan('chosen_input', value)}>
							<Col md={4} lg={3} xl={3} sm={12} xs={12} className={'radioInput'}>
								{this.isBeforeEdge() ?
									<FormControlLabel
										className='radioButton'
										value="insurance_rate" control={<Radio/>}
										label={<Translate value='step2.choiceInsuranceRate'/>}/> :
									<FormControlLabel
										className='radioButton'
										value="taea" control={<Radio/>}
										label={<Translate value='step2.choiceTaea' dangerousHTML/>}/>
								}
							</Col>
							<Col md={4} lg={4} xl={4} sm={12} xs={12} className={'radioInput'}>
								<FormControlLabel
									className='radioButton'
									value="global_insurance" control={<Radio/>}
									label={<Translate value='step2.choiceGlobalInsurance' dangerousHTML/>}/>
							</Col>

								<Col md={4} lg={5} xl={5} sm={12} xs={12} className={'radioInput'}>
									<FormControlLabel
										className='radioButton'
										value="insurance_premium" control={<Radio/>}
										label={<Translate value='step2.choiceInsurancePremium'/>}/>
								</Col>
						</RadioGroup>
					</Row>

					{this.renderLoanData()}

					{!!this.props.borrower.has_coborrower && (
						<Row justify='center' className='firstRow'>
							<Col md={6}>
								<Coverage data={this.props.borrower} update={this.props.updateBorrower}
										  check={this.props.coborrower}
								          label={<Translate value='generic.coverage'/>}
										  helperText={<Translate value='generic.your'/>}/>
							</Col>
							<Col md={6}>
								<Coverage data={this.props.coborrower} update={this.props.updateCoBorrower}
										  check={this.props.borrower}
										  label={<Translate value='generic.coverage'/>}
										  helperText={<Translate value='generic.ofCoborrower'/>}/>
							</Col>
						</Row>
					)}

					<Row justify="center" className="buttonGroup">
						<Col md={6} xs={8}>
							<FormButton value={<Translate value='step2.continueButton'/>}/>
						</Col>
					</Row>

				</ValidatorForm>
			</div>
		)
	}
}

export default connect()(InsuranceInfo);
