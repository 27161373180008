import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";
import {CustomValidatorForm} from "../../components/Form";
import paymentImage from './payment.svg';
import Checkout from './Payments/checkout';

var I18n = require('react-redux-i18n').I18n;

class IncomeStepOne extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonClicked: false,
			price: 20.00,
			percentage: 50,
			ittSelected: 1,
			iptSelected: 1,
			mno: 1
		}
	}


	getStep() {
		return 'income_step_one';
	}

	render() {

		return (
			<div>
				<Container style={{color: '#000', textAlign: 'center', width: '90%',}}>
					<CustomValidatorForm>
						<Row justify="center" className="incomeRow">
							<Col md={6} style={{marginTop: '-30px', textAlign: 'center'}}>
								<p className="titlePrets">Последна стъпка!</p>
							</Col>
						</Row>
						<Row justify="center" style={{marginTop: '-40px', marginBottom: '-100px', textAlign: 'left'}}>
						<Col md={8}>
							<img src={paymentImage}/>
						</Col>
					</Row>
						<Row justify="center" className="incomeRow">
							<Col md={8} style={{paddingTop: '20px', textAlign: 'left'}}>
								<Checkout/>
							</Col>
						</Row>
					</CustomValidatorForm>
				</Container>
			</div>
		)
	}

}

export default connect()

(
	Shell(IncomeStepOne)
)
;
