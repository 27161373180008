import {BORROWER_UPDATE, BORROWER_INITIAL_FETCH, BORROWER_SET, BORROWER_IBAN, INSURANCE_CALCULATION, UPDATE_INCOME_CUSTOMER,MNCAP_QUESTIONNAIRE, CHECK_LEMOINE} from "../../constants/action-types";
import {post, put} from "js/helpers/rest";
import {getBackendUrl} from "js/helpers/functions";

const api_url               = getBackendUrl();
export const updateBorrower = (key, value) => {
	return dispatch => {
		dispatch({
			type: BORROWER_UPDATE,
			key,
			value
		});
	}
};

export const borrowerFetch = (data) => {
	return dispatch => {
		dispatch({
			type: BORROWER_INITIAL_FETCH,
			data
		});
	}
};

export const borrowerIban = (data) => {
	return dispatch => {
		return dispatch(action => {
			return post(data, api_url + '/customer/iban')
				.then(res => {
					return dispatch({
						type: BORROWER_IBAN,
						data: res
					});
				});
		});
	};
};

export const mncapQuestionnaire = (data) => {
	return dispatch => {
		return dispatch(action => {
			return post(data, api_url + '/customer/mncap_questionnaire')
				.then(res => {
					return dispatch({
						type: MNCAP_QUESTIONNAIRE,
						data: res
					});
				});
		});
	};
};

export const checkLemoine = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/check_lemoine').then(res => {
				return dispatch({
					type: CHECK_LEMOINE,
					data: res
				});
			})
		});
	};
};
export const saveLemoine  = (params, coborrower = false) => {
	if (coborrower) {
		params.coborrower = true;
	}
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/save_lemoine').then()
		});
	};
};
export const setBorrower  = (data) => {
	return dispatch => {
		dispatch({
			type: BORROWER_SET,
			data
		});
	}
};

export const updateWellness = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/update_wellness');
		});
	};
};

export const affiliateInvitation = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/share_affiliate_link');
		});
	};
}

export const updateIncomeCustomer = (params) => {
	return dispatch => {
		return dispatch(() => {
			return put(params, api_url + '/customer/update').then(res => {
				return dispatch({
					type: UPDATE_INCOME_CUSTOMER,
					data: res
				});
			})
		});
	};
};
