import {
	GET_UUID,
	JOB_INFO,
	INSURANCE_CALCULATION,
	UPDATED_PRICING,
	INITIAL_CALCULATION,
	INITIAL_CALCULATION_NO_INSURANCE,
	BRAVO_CONTACT,
	BRAVO_DOCUMENTS,
	CONTRACT_DATA,
	AFFILIATE_DATA,
	UPDATE,
	DOCUMENTS,
	SIGN,
	SETUP,
	HAS_SIGNED,
	CONFIG_UPDATE,
	BORROWER_SET,
	COMPARE_INSURERS,
	BORROWER_INITIAL_FETCH,
	RESIGN_DOCUMENTS,
	UPDATE_AFIESCA_IBAN,
	COBORROWER_INITIAL_FETCH,
	LOAN_INITIAL_FETCH,
	CALCULATION_FETCH,
	RECOVER_APPICATION,
	COBORROWER_REMINDER,
	INSURER_SPECIFICS,
	CHECK_SMS_CODE,
	CHECK_LEMOINE,
	SMS_CONFIRMATION,
	ADD_LEAD,
	SET_STATE, COBORROWER_SET, RE_INITIALIZE_ALL, LOAN_UPDATE
} from '../../constants/action-types';
import {post, get} from '../../helpers/rest';
import {getBackendUrl, randomString} from "../../helpers/functions";

const api_url                   = getBackendUrl();
export const initialCalculation = (params) => {
	return dispatch => {
		return dispatch(action => {
			//TODO: add error handling
			return post(params, api_url + '/calculate/initial')
				.then(res => {
					return dispatch({
						type: INITIAL_CALCULATION,
						data: res
					});
				})
				.catch(error => {
					return dispatch({
						type: INITIAL_CALCULATION,
						data: false
					})
				});
		});
	};
};

export const insuranceCalculation = (params) => {
	return dispatch => {
		return dispatch(action => {
			//TODO: add error handling
			return post(params, api_url + '/calculate/compare')
				.then(res => {
					return dispatch({
						type: INSURANCE_CALCULATION,
						data: res
					});
				});
		});
	};
};
export const compareInsurers      = (params) => {
	return dispatch => {
		return dispatch(action => {
			//TODO: add error handling
			return post(params, api_url + '/calculate/compare_insurers')
				.then(res => {
					console.log(res.effective_date, 'asdasd')
					dispatch({type: LOAN_UPDATE, key: 'effective_date', value: res.effective_date});
					return dispatch({
						type: COMPARE_INSURERS,
						data: res
					});
				});
		});
	};
};
export const insurerSpecifics     = (flow) => {
	return dispatch => {
		return dispatch(action => {
			return post({flow}, api_url + '/config/insurer_specifics')
				.then(res => {
					return dispatch({
						type: INSURER_SPECIFICS,
						data: res
					});
				});
		});
	};
};

export const initialCalculationNoInsurance = (params) => {
	return dispatch => {
		return dispatch(action => {
			//TODO: add error handling
			return post(params, api_url + '/calculate/new').then(res => {
				return dispatch({
					type: INITIAL_CALCULATION_NO_INSURANCE,
					data: res
				});
			});
		});
	};
};

export const afiEsca = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/calculate/afi-esca');
		});
	};
};

export const afiEscaCoboLongProcess = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/calculate/afi-esca-cobo-long-process');
		});
	};
};

export const recoverApplication = (params) => {
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/recover').then(res => {
				return dispatch({
					type: RECOVER_APPICATION,
					data: res
				});
			});
		});
	};
};

export const recoverStaticApplication = (data) => {
	return dispatch => {
		return dispatch(action => {
			return dispatch({
				type: RECOVER_APPICATION,
				data: data
			});
		});
	};
};

export const finalCalculation = (params) => {
	// if (params.loan.loans[2] && !params.loan.loans[2].amount) params.loan.loans.splice(2, 1);
	// if (params.loan.loans[1] && !params.loan.loans[1].amount) params.loan.loans.splice(1, 1);
	return dispatch => {
		return dispatch(action => {
			//TODO: add error handling
			return post(params, api_url + '/calculate/final').then(res => {
				return dispatch({
					type: INITIAL_CALCULATION,
					data: res
				});
			});
		});
	};
};

export const updatedPricing = (params) => {
	return dispatch => {
		return dispatch(action => {
			//TODO: add error handling
			return post(params, api_url + '/calculate/updated_pricing').then(res => {
				return dispatch({
					type: UPDATED_PRICING,
					data: res
				});
			});
		});
	};
};

export const setup = (lang) => {
	let payload  = {};
	payload.body = {lang};
	return dispatch => {
		return dispatch(action => {
			//TODO: add error handling
			return get(payload, api_url + '/config/setup').then(res => {
				return dispatch({
					type: SETUP,
					data: res
				});
			});
		});
	};
};

export const reCheckState = () => {
	return dispatch => {
		return dispatch(action => {
			let state = {};
			try {
				state = JSON.parse(localStorage.getItem('state'));
			} catch (e) {
			}
			if (state && state.borrower) {
				let under = 0;
				let camel = 0;
				Object.keys(state.borrower).forEach(key => {
					key.indexOf('_') !== -1 ? under++ : camel++;
				});
				if (camel > under) {
					return post(state, api_url + '/customer/update_state').then(res => {
						return dispatch({
							type: SET_STATE,
							data: res
						});
					});
				} else {
					return Promise.resolve();
				}
			}
		});
	};
};

export const hasSigned = (params) => {
	params = params || {};
	return dispatch => {
		return dispatch(action => {
			return get(params, api_url + '/customer/has_signed').then(res => {
				return dispatch({
					type: HAS_SIGNED,
					data: res
				});
			});
		});
	};
};

export const updateCustomer = (params) => {
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/update').then(res => {
				return dispatch({
					type: UPDATE,
					data: res
				});
			});
		});
	};
};

export const updateProgress = (params) => {
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/update_progress').then(res => {
				return dispatch({
					type: UPDATE,
					data: res
				});
			});
		});
	};
};

export const bravoDocuments = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/bravo_documents').then(res => {
				return dispatch({
					type: BRAVO_DOCUMENTS,
					data: res
				});
			});
		});
	};
};
export const bravoContact   = (params) => {
	return dispatch => {
		return dispatch(() => {
			let url = api_url + '/customer' + (params.type === 'meeting' ? '/create_meeting' : '/bravo_contact')
			return post(params, url).then(res => {
				return dispatch({
					type: BRAVO_CONTACT,
					data: res
				});
			});
		});
	};
};
export const addLead        = (params) => {
	return dispatch => {
		return dispatch(() => {
			let url = api_url + '/external/leads';
			return post(params, url, true).then(res => {
				return dispatch({
					type: ADD_LEAD,
					data: res
				});
			});
		});
	};
};

export const jobInfo   = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/job_info').then(res => {
				return dispatch({
					type: JOB_INFO,
					data: res
				});
			});
		});
	};
};
export const login     = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/login').then(res => loginUpdate(dispatch, res))
		});
	};
};
export const leadLogin = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/lead_login').then(async (res) => {
				if (res.props && res.props.guid && (!localStorage.guid || localStorage.guid === 'undefined')) {
					localStorage.setItem('guid', res.props.guid);
				}
				await dispatch({type: RE_INITIALIZE_ALL});

				return Promise.all([
					dispatch({type: CONFIG_UPDATE, key: 'loggedIn', value: false}),
					dispatch({type: BORROWER_SET, data: res.props.borrower}),
					dispatch({type: COBORROWER_SET, data: res.props.coborrower || {}}),
					dispatch({type: LOAN_INITIAL_FETCH, data: res.props.loan || {loans: []}}),
					dispatch({type: CALCULATION_FETCH, data: {}}),
				]);
			})
		});
	};
};

export const registration = (params) => {
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/register').then(res => loginUpdate(dispatch, res))
		});
	};
};

export const incomeRegistration = (params) => {
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/register').then(res => loginIncomeUpdate(dispatch, res))
		});
	};
};

function loginIncomeUpdate(dispatch, res, logged_in = true) {
	console.log('res from login', res);
	if (res.result) {
		let promises = [
			dispatch({type: CONFIG_UPDATE, key: 'loggedIn', value: logged_in}),
			dispatch({type: BORROWER_INITIAL_FETCH, data: res.customer})
		];
		return Promise.all(promises);
	} else {
		return Promise.reject('Authentication failed.');
	}
}

function loginUpdate(dispatch, res, logged_in = true) {
	if (res.result) {
		if (res.props && res.props.guid && (!localStorage.guid || localStorage.guid === 'undefined')) {
			localStorage.setItem('guid', res.props.guid);
		}

		let calculation = {};
		if (res.props.total_savings instanceof Object) {
			calculation = res.props.total_savings;
		} else if (res.props.calculation instanceof Object) {
			calculation = res.props.calculation;
		}
		let updates = [
			dispatch({type: COMPARE_INSURERS, data: {insurers: res.props.comparison}}),
			dispatch({type: CONFIG_UPDATE, key: 'loggedIn', value: logged_in}),
			dispatch({type: BORROWER_INITIAL_FETCH, data: res.props.borrower}),
			dispatch({type: COBORROWER_INITIAL_FETCH, data: res.props.coborrower}),
			dispatch({type: LOAN_INITIAL_FETCH, data: res.props.loan}),
			dispatch({type: CALCULATION_FETCH, data: calculation}),
		];
		return Promise.all(updates);
	} else {
		return Promise.reject('Authentication failed.');
	}
}

export const logout = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/logout').then(res => {
				dispatch({type: CONFIG_UPDATE, key: 'loggedIn', value: !res.result});
				let url     = '/prets';
				let id_hint = localStorage.getItem('id_hint');
				if (id_hint) {
					url = process.env.REACT_APP_FRANCE_CONNECT_URL + '/api/v1/logout';
					url += '?id_token_hint=' + id_hint;
					url += '&post_logout_redirect_uri=' + encodeURIComponent(api_url + '/customer/france_disconnect');
					url += '&state=' + randomString(50);
				}

				localStorage.removeItem('state');
				localStorage.removeItem('id_hint');

				window.location = url;
			});
		});
	};
};

export const getContractData        = (params) => {
	params = params || {};
	return dispatch => {
		return dispatch(action => {
			return get(params, api_url + '/customer/get_contract').then(res => {
				return dispatch({
					type: CONTRACT_DATA,
					data: res
				});
			});
		});
	};
};
export const getAffiliateData       = (params) => {
	params = params || {};
	return dispatch => {
		return dispatch(action => {
			return get(params, api_url + '/customer/get_affiliate_data').then(res => {
				return dispatch({
					type: AFFILIATE_DATA,
					data: res
				});
			});
		});
	};
}
export const updateAfiescaIban      = (params) => {
	params = params || {};
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/afiesca_iban').then(res => {
				return dispatch({
					type: UPDATE_AFIESCA_IBAN,
					data: res
				});
			});
		});
	};
};
export const sendCoborrowerReminder = (params) => {
	params = params || {};
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/coborrower_reminder').then(res => {
				return dispatch({
					type: SETUP,
					data: res
				});
			});
		});
	};
};

export const documents       = (params) => {
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/documents').then(res => {
				return dispatch({
					type: DOCUMENTS,
					data: res
				});
			});
		});
	};
};
export const resignDocuments = (params) => {
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/resign_documents').then(res => {
				return dispatch({
					type: RESIGN_DOCUMENTS,
					data: res
				});
			});
		});
	};
};

export const sign         = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/sign').then(res => {
				return dispatch({
					type: SIGN,
					data: res
				});
			});
		});
	};
};
export const updateConfig = (key, value) => {
	return dispatch => {
		dispatch({
			type: CONFIG_UPDATE,
			key,
			value
		});
	};
};

export const ConfirmSMS   = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/sms_confirmation').then(res => {
				return dispatch({
					type: SMS_CONFIRMATION,
					data: res
				});
			});
		});
	};
};
export const checkSMSCode = (params) => {
	return dispatch => {
		return dispatch(() => {
			return post(params, api_url + '/customer/check_sms').then(res => {
				return dispatch({
					type: CHECK_SMS_CODE,
					data: res
				});
			});
		});
	};
};

export const uuid = (params) => {
	return dispatch => {
		return dispatch(action => {
			return post(params, api_url + '/customer/identity').then(res => {
				return dispatch({
					type: GET_UUID,
					data: res
				});
			});
		});
	};
};

export const callMeBack = (params) => {
	return dispatch => {
		return dispatch(action => post(params, api_url + '/customer/requestCallBack'));
	};
};

export const sendRenunciation = (params) => {
	return dispatch => {
		return dispatch(action => post(params, api_url + '/customer/sendRenunciation'));
	};
};
