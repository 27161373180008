import React from 'react';
import {Container, Row, Col} from 'react-grid-system';
import './header.css';
import history from '../../history';
import {steps} from '../../js/helpers/data/index';
import Translate from "components/CustomTranslate";
import Button from '@material-ui/core/Button';
import person from './person.png';

const Header = (props) => {
	let logo = props.config.theme.logoHeader;
	return (
		<div>
			{['/rendez-vous-page', '/quick-pricing'].indexOf(window.location.pathname) === -1 &&
			<div style={{marginBottom: '0px'}} id={window.location.pathname === '/rendez-vous-page' ? '' : 'header'}>
				<Container className="avatarContainer">
					<Row style={{backgroundImage: props.config.theme.sidebar}} justify='center' className="logoContainer">
						<Col xs={4} sm={4} md={4}>
						</Col>
						<Col md={4} sm={4} xs={4} justify="center" className="centeredText">
							<img src={logo} className="weDouLogo" alt="logo"
							     onClick={() => {
								     window.location.pathname === steps[props.flow][0] ? window.location.href = `https://${props.config.theme.website}` : history.push('/prets');
							     }
							     }/>
						</Col>

						<Col xs={4} sm={4} md={4} className="accountContainer">
							{['/rendez-vous-page', '/quick-pricing'].indexOf(window.location.pathname) === -1 ?
								<Button style={{backgroundColor: props.config.theme.primary}} className={'loginLogoutButton'} name={'logButton'} onClick={() => {
									props.config.loggedIn ? props.openAuthModal('login') : props.updateConfig('sideMenu', true);
								}
								}>
									<img height="25px" src={person}/>
								</Button> : ''
							}
						</Col>
					</Row>
				</Container>
			</div>}
		</div>
	);
};
export default Header;
