import axios from 'axios';
import store from "../../store/index";
import {CONFIG_UPDATE} from "../../constants/action-types";

function jsonToGetParams(json) {
	return json ? ('?' + Object.keys(json).map((key) => {
		return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
	}).join('&')) : '';
}

function handleResponse(res) {
	if (!window.__MIRROR_AGENT && window.__MIRROR_LISTENING && window.__MIRROR_REQUEST_RESPONSE) {
		window.__MIRROR_REQUEST_RESPONSE(res);
	}
	if (res.status === 200) {
		return res.data;
	} else {
		return Promise.reject(res);
	}
}

function handleError(error) {
	console.log('Response error:', error, 'Mock:', window.__MIRROR_AGENT && !!window.__MIRROR_REQUEST_MOCK);
	if (!window.__MIRROR_AGENT && window.__MIRROR_LISTENING && window.__MIRROR_REQUEST_RESPONSE) {
		window.__MIRROR_REQUEST_RESPONSE({error: true, response: error});
	}
	if (error && error.response && error.response.status === 401) {
		store.dispatch({type: CONFIG_UPDATE, key: 'loading', value: false});
		store.dispatch({type: CONFIG_UPDATE, key: 'sessionExpired', value: true});
	} else {
		throw error;
	}
}

export async function get(payload, url) {
	let config = {
		headers: payload.headers,
		withCredentials: true
	};
	if (payload.responseType) config.responseType = payload.responseType;

	if (window.__MIRROR_AGENT && window.__MIRROR_REQUEST_MOCK) {
		return window.__MIRROR_REQUEST_MOCK(url).then(handleResponse).catch(handleError);
	} else {
		return axios.get(url + jsonToGetParams(payload.body), config).then(handleResponse).catch(handleError);
	}
}

export function post(params, url, raw = false) {
	params      = params || {};
	let headers = params.headers || {"Content-Type": "application/json"};
	delete params.headers;
	console.log('Will mock post request', window.__MIRROR_AGENT && !!window.__MIRROR_REQUEST_MOCK);
	if (window.__MIRROR_AGENT && window.__MIRROR_REQUEST_MOCK) {
		return window.__MIRROR_REQUEST_MOCK(url).then(handleResponse).catch(handleError);
	} else {
		return axios({
			method: 'post',
			withCredentials: true,
			url,
			data: raw ? params : {
				params
			},
			headers: headers
		}).then(handleResponse).catch(handleError);
	}
}
export function put(params, url, raw = false) {
	params      = params || {};
	let headers = params.headers || {"Content-Type": "application/json"};
	delete params.headers;
		return axios({
			method: 'put',
			withCredentials: true,
			url,
			data: raw ? params : {
				params
			},
			headers: headers
		}).then(handleResponse).catch(e => {
			console.log('error with request',e)
		});
}

export function sendFile(payload, url) {
	return axios.post(url, payload.body, {
		withCredentials: true,
		headers: payload.headers
	}).then(res => {
		if (res.status === 200) {
			return res.data;
		} else {
			throw new Error('Status not 200!');
		}
	}).catch(error => {
		if (error && error.response && error.response.status === 401) {
			store.dispatch({type: CONFIG_UPDATE, key: 'loading', value: false});
			store.dispatch({type: CONFIG_UPDATE, key: 'sessionExpired', value: true});
		} else {
			throw new Error(error.message);
		}
	});
}
