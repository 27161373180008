import React, {useState} from 'react';
import history from "../../history";
import ReactTooltip from "react-tooltip";
import {Col, Row} from "react-grid-system";
import Translate from "components/CustomTranslate";
import RadioGroup from "@material-ui/core/RadioGroup";
import {StringField, YesNoRadio} from "../../components/Form/Fields";
import FormButton from "../../components/Form/FormButton";
import {ValidatorForm} from "react-material-ui-form-validator";
import {connect} from 'react-redux';
import {mapData} from "../../js/helpers/functions";
import {Button} from "@material-ui/core";
import BackButton from "../../components/Form/BackButton";
import Modal from "../../components/Modal";
import {isMobile} from "react-device-detect";
import moment from "moment";
import {ConfirmSMS} from "../../js/actions/config";

const AfiEscaHealthQuestions = (props) => {
	const [clickedButton, setClickedButton] = useState(false);
	const [healthModal, setHealthModal]     = useState(false);
	let data                                = props.isCoborrower ? props.coborrower : props.borrower;
	const borrower_age                      = moment().diff(moment(data.birth_date, 'DD/MM/YYYY'), 'years');

	const mapUpdateData = (b, flow) => {
		let key         = props.config.isCoborrower ? 'coborrower' : 'borrower';
		let data        = {state: {}, flow};
		data.state[key] = props[key];
		data[key]       =
			{
				question_one: b.afiesca_question_two,
				question_two: b.afiesca_question_three,
				question_three: b.afiesca_question_four,
				question_four: b.afiesca_question_five,
				height: b.height,
				weight: b.weight
			};
		if (key === 'borrower') {
			data[key].step  = 'sante';
			data[key].email = b.email;
		}
		return data;
	}
	return (
		<Row justify="center">
			<Col className="incomeRow" md={10}>


				<ValidatorForm onSubmit={() => {
					let risky_profession_extra = props.config.risky_profession_afiesca.find(p => p.key === props.borrower.risky_profession_afiesca);
					data.question_one          = "false"; // afiesca lets people with yes on the 1st question
					let bmi                    = data.weight / ((data.height / 100) * (data.height / 100));
					let health_questions       = [data.afiesca_question_two, data.afiesca_question_three, data.afiesca_question_four, data.afiesca_question_five]
					let additional_conditions  = [
						(bmi < 18 || bmi > 33),
						data.sport !== 'none_of_the_above',
						data.foreign_travel,
						data.foreign_country,
						borrower_age > 84,
						borrower_age > 46 && props.config.calculation.total_amount > 500000,
						borrower_age > 55 && props.config.calculation.total_amount > 250000,
						borrower_age > 65 && props.config.calculation.total_amount > 150000,
						props.config.calculation.total_amount > 1000001,
					]
					props.updateCustomer(mapUpdateData(props.config.isCoborrower ? props.coborrower : props.borrower, 'afi-esca'))
					if (!health_questions.every(q => q === "false") || !additional_conditions.every(c => c === false || c === null) || (risky_profession_extra && risky_profession_extra.long_process)) {
						setHealthModal(true);
					} else {
						props.update('health_selection', false);
						if (props.isCoborrower) {
							return history.push('/co-numero');
						} else {
							props.afiEsca(mapData(Object.assign({}, data, {step: 'resume', health_selection: false}), props.coborrower, props.loan));
							return history.push('/resume');
						}
					}
				}}>
					<Modal
						title="Votre souscription"
						dialogOpen={healthModal}
						config={props.config}
						maxWidth={'sm'}
						onClose={() => setHealthModal(false)}
					>
						<Row justify='center'>
							<Col md={10}>
								<p>Merci de bien vérifier vos réponses. Vous allez être redirigé vers le site d'AFI-Esca.</p>
							</Col>
						</Row>
						<Row justify='center'>
							<Col md={10}>
								<Button style={{backgroundColor: props.config.theme.primary}} className='formButton' onClick={async () => {
									if (!props.isCoborrower) {
										props.afiEsca(mapData(Object.assign({}, data, {step: 'resume', health_selection: true}), props.coborrower, props.loan));
									} else {
										props.afiEscaCoboLongProcess(mapData(Object.assign({}, data, {step: 'resume', health_selection: true}), props.coborrower, props.loan));
									}
									props.update('health_selection', true);
									return history.push('/bravo_signature');
								}}>
									<Translate value='generic.follow'/>
								</Button>
							</Col>
						</Row>
					</Modal>
					<ReactTooltip style={{backgroundColor: props.config.theme.primary}} className="step2-tooltip"/>
					<Row justify="center">
						<Col md={8} style={{marginTop: '35px'}}>
							<p className="focusText">
								<b><Translate value='step6.text' name={data.first_name}/></b>
							</p>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							<p className='healthQuestion'>
								<p>Bénéficiez-vous ou avez-vous bénéficié au cours de ces 10 dernières années d’une surveillance médicale (nécessité de consulter un médecin au mois une
									fois par an pour une pathologie et/ou un accident ne nécessitant pas de traitement) et/ou, d’au moins une de ces prescriptions médicales, du fait
									d’une pathologie ou d’un accident :</p>
								<ul>
									<li>un traitement médicamenteux de plus de 21 jours consécutifs,</li>
									<li>un traitement spécialisé tel que radiothérapie, chimiothérapie, immunothérapie, biothérapie ou interféron,</li>
									<li>un arrêt de travail de plus de 21 jours consécutifs,</li>
									<li>une hospitalisation, un séjour en établissement de soins ou une intervention chirurgicale (sauf pour maternité, appendicite, amygdales,
										végétations, chirurgie dentaire ou prélèvement pour don d’organe),
									</li>
									<li>une rééducation, - un appareillage ?</li>
								</ul>
							</p>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="questCol">
							<RadioGroup className='radioGroup healthQuestion'
							            name='Q2'
							            value={data.afiesca_question_two}
							            onChange={(event, value) => {
								            props.update('afiesca_question_two', value);
							            }}
							><YesNoRadio name="Q2" buttonClicked={data.afiesca_question_two}/></RadioGroup>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<p className='healthQuestion'>
								Présentez-vous des séquelles d’accident ou de maladie ou êtes-vous titulaire d’une pension, rente d’allocation au titre d’une inaptitude au travail ,
								d’une allocation aux adultes handicapés (AAH) ou d’une invalidité ?
							</p>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="questCol">
							<RadioGroup className='radioGroup healthQuestion'
							            name='Q3'
							            value={data.afiesca_question_three}
							            onChange={(event, value) => {
								            props.update('afiesca_question_three', value);
							            }}
							><YesNoRadio name="Q3" buttonClicked={data.afiesca_question_three}/></RadioGroup>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							<p className='healthQuestion'>
								Au cours des 5 dernières années, avez-vous fait l’objet d’examens médicaux dont les résultats ont comporté une ou plusieurs anomalie(s) ou
								attendez-vous des résultats d’examens médicaux ?
							</p>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="questCol">
							<RadioGroup className='radioGroup healthQuestion'
							            name='Q4'
							            value={data.afiesca_question_four}
							            onChange={(event, value) => {
								            props.update('afiesca_question_four', value);
							            }}
							><YesNoRadio name="Q4" buttonClicked={data.afiesca_question_four}/></RadioGroup>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<p className='healthQuestion'>
								Au cours des 6 prochains mois, devez-vous
								consulter un médecin spécialiste,
								être hospitalisé(e), faire l’objet d’une intervention chirurgicale
								ou faire l’objet d’examens médicaux ?
							</p>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="questCol">
							<RadioGroup className='radioGroup healthQuestion'
							            name='Q5'
							            value={data.afiesca_question_five}
							            onChange={(event, value) => {
								            props.update('afiesca_question_five', value);
							            }}
							><YesNoRadio name="Q5" buttonClicked={data.afiesca_question_five}/></RadioGroup>
						</Col>
					</Row>
					<Row justify="center" style={{color: '#2e305f'}} className='blueLabel'>
						<Col md={3}>
							<StringField
								onChange={(e) => props.update('height', e.target.value)}
								value={data.height}
								validators={['required', 'isNumber', 'minFloatNumber:30', 'maxFloatNumber:250']}
								errorMessages={['Cette information doit être renseignée', 'Cette information doit être renseignée']}
								label='Taille'
								margin="normal"
								placeholder="cm"
								className='longLabel'
								name={'height'}
								disabled={props.disabled}
							/>
						</Col>
						<Col md={3}>
							<StringField
								onChange={(e) => props.update('weight', e.target.value)}
								value={data.weight}
								validators={['required', 'isNumber', 'minFloatNumber:30', 'maxFloatNumber:250']}
								errorMessages={['Cette information doit être renseignée', 'Cette information doit être renseignée']}
								label='Poids'
								margin="normal"
								placeholder="kg"
								className='longLabel'
								name={'weight'}
								disabled={props.disabled}
							/>
						</Col>
					</Row>
					<Row justify='center' style={{marginTop: '3vh'}}>
						<Col md={3}>
							<BackButton flow={props.loan.flow}/>
						</Col>
						<Col md={5}>
							<FormButton value={<Translate value='generic.follow'/>}/>
						</Col>
					</Row>
				</ValidatorForm>
			</Col>
		</Row>
	);
};
const mapStateToProps        = (state) => state.rootReducer;
export default connect(mapStateToProps, {ConfirmSMS})(AfiEscaHealthQuestions);
