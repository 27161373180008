import React, {useEffect, useState} from 'react';
import Modal from "../../components/Modal";
import Button from '@material-ui/core/Button';
import {Row, Col} from 'react-grid-system';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Translate from "components/CustomTranslate";
import CookieBanner, {Cookies} from 'react-cookie-banner';
import ReactTooltip from "react-tooltip";
import imgCookie from './cookie.png'
import './style.css';

const I18n = require('react-redux-i18n').I18n;

const CookieBar = (props) => {
	const cookies                       = new Cookies;
	let cookie                          = cookies.get('accepts-cookies');
	const [openModal, setOpenModal]     = useState(false);
	const [statistical, setStatistical] = useState(!cookie || cookie.statistical);
	const [promotional, setPromotional] = useState(!cookie || cookie.promotional);

	const modal = () => {
		return (

			<div className="modalCookie">
				<Modal
					className={"modalCookie"}
					title={<><img height="130px" style={{display: 'block', margin: 'auto', position: 'relative', top: '-20px'}} src={imgCookie}/><Translate value="generic.cookieYourChoice"/></>}
					dialogOpen={openModal}
					config={props.config}
					style={{backgroundColor: 'red'}}
					onClose={() => setOpenModal(false)}>

					<Row justify="center">
						<Col style={{marginBottom: '20px'}}>
							<Translate value="generic.cookiePersonaliseMessage"/>
						</Col>
					</Row>
					<Row justify="center">
						<Col md={4} xs={12} sm={12}>
							<Checkbox
								checked="ture"
								value="true"
								color="primary"
								name="essentiels"
							/> <Translate value="generic.cookieEssential"/>
							<i className={`fa fa-info`} style={{color: props.config.theme.primary, display: 'inline-block', paddingLeft: '5px'}} data-tip data-for="info-essentiales" data-multiline='true'/>
						</Col>
						<ReactTooltip id="info-essentiales" type="info">
						<span>
							Les cookies "essentiels" sont obligatoires pour le fonctionnement de l'application Wedou.
							Ce sont des cookies fonctionnels qui sont utilisés par Wedou pour:
						</span>
							<ul>
								<li>Authentification - Les cookies sont utilisés pour fournir des fonctionnalités de connexion / d'enregistrement et d'authentification générique dans l'application Wedou.</li>
								<li>Persistance des données - Les cookies sont utilisés pour synchroniser et conserver les données affichées dans l'application Wedou.</li>
								<li>UI et apparences - Les cookies peuvent être utilisés par Wedou pour la visualisation et les implémentations d'interface</li>
							</ul>
						</ReactTooltip>
						<Col md={4} xs={12} sm={12}>
							<Checkbox
								onChange={(e, value) => setStatistical(!statistical)}
								checked={statistical}
								value={statistical}
								name="statistical"
								color="primary"
							/> <Translate value="generic.cookieStatistics"/>
							<i className={`fa fa-info`} style={{color: props.config.theme.primary, display: 'inline-block', paddingLeft: '5px'}} data-tip data-for="info-statistiques" data-multiline='true'/>
							<ReactTooltip id="info-statistiques" type="info">
							<span>
								Les cookies "statistiques" ne sont pas obligatoires mais recommandés pour offrir la meilleure expérience Wedou.
								<br/>Ce sont des cookies utilisés en interne par Wedou pour créer des données statistiques sur l'utilisation de l'application Wedou et améliorer tous ses composants.
							</span>
								<ul>
									<li>Statistiques utilisateur - Les cookies sont utilisés pour collecter uniquement les données non personnelles "statistiques" et fournissent une base pour les <br/>modèles analytiques utilisés
										par Wedou pour améliorer le parcours de l'utilisateur à l'intérieur de l'application.
									</li>
									<li>Statistiques d'utilisation - Les cookies sont utilisés pour surveiller l'utilisation de l'application et fournir une meilleure expérience utilisateur avec des fonctionnalités nouvelles ou
										améliorées.
									</li>
									<li>Cookies tiers - Les cookies tiers sont utilisés pour relier les données statistiques fournies par des tiers et enrichir les modèles analytiques de Wedou</li>
								</ul>
							</ReactTooltip>
						</Col>
						<Col md={4} xs={12} sm={12}>
							<Checkbox
								onChange={(e, value) => setPromotional(!promotional)}
								checked={promotional}
								value={promotional}
								name="promotional"
								color="primary"
							/> <Translate value="generic.cookiePromotional"/>
							{/*<i className={`fa fa-info`} style={{color: props.config.theme.primary, display:'inline-block', paddingLeft:'5px'}} data-tip data-for="info-statistiques" data-multiline='true'/>*/}
							{/*<ReactTooltip id="info-statistiques" type="info">*/}
							{/*	<span>*/}
							{/*		Les cookies "statistiques" ne sont pas obligatoires mais recommandés pour offrir la meilleure expérience Wedou.*/}
							{/*		<br/>Ce sont des cookies utilisés en interne par Wedou pour créer des données statistiques sur l'utilisation de l'application Wedou et améliorer tous ses composants.*/}
							{/*	</span>*/}
							{/*	<ul>*/}
							{/*		<li>Statistiques utilisateur - Les cookies sont utilisés pour collecter uniquement les données non personnelles "statistiques" et fournissent une base pour les <br/>modèles analytiques utilisés par Wedou pour améliorer le parcours de l'utilisateur à l'intérieur de l'application.</li>*/}
							{/*		<li>Statistiques d'utilisation - Les cookies sont utilisés pour surveiller l'utilisation de l'application et fournir une meilleure expérience utilisateur avec des fonctionnalités nouvelles ou améliorées.</li>*/}
							{/*		<li>Cookies tiers - Les cookies tiers sont utilisés pour relier les données statistiques fournies par des tiers et enrichir les modèles analytiques de Wedou</li>*/}
							{/*	</ul>*/}
							{/*</ReactTooltip>*/}
						</Col>
					</Row>
					<Row justify="center">
						<Col md={6} xs={12} sm={12}>
							<Button style={{backgroundColor: props.config.theme.primary, borderRadius: '20px'}} className='cookieButton' onClick={(e) => {
								handleClickCheckBox()
							}}><Translate value="generic.acceptAll"/></Button>
						</Col>
					</Row>
					<Row justify="center">
						<Col md={6} xs={12} sm={12}>
							<Button className='cookieButtonOther' onClick={() => {
								handleClickCheckBox()
							}}><Translate value="generic.save"/></Button>
						</Col>
					</Row>
				</Modal>
			</div>
		)
	}

	const handleClickCheckBox = () => {
		setStatistical(true);
		setPromotional(true);
		setOpenModal(false);
		cookies.set('accepts-cookies', JSON.stringify({generic: true, promotional, statistical}));
	}

	const handleClick = () => {
		setOpenModal(true);
	}

	let link = (
		<>
			<a style={{display: 'inline-block', color: 'black'}} href='http://wedou.fr/politique-de-confidentialite' target='_blank' rel="noopener noreferrer">
				<Translate value='generic.cookiePolicyMessage'/>
			</a>
			<a onClick={handleClick} style={{color: 'black', display: 'inline-block', padding: '10px', textDecoration: "underline"}}>
				<Translate value="generic.personalise"/>
			</a>
		</>
	);

	return (
		<>
			{!openModal && <>
				<CookieBanner
					{...props}
					styles={{
						banner: {backgroundColor: '#F3F0B0'},
						message: {color: 'black',fontSize: '12px'}
					}}
					message={<><img style={{position: 'relative', top: "-15px"}} height="150px" src={imgCookie}/><p style={{lineHeight: '15px'}}><Translate value="generic.cookieMessage" dangerousHTML/></p></>}
					link={link}
					cookie="accepts-cookies"
					onAccept={() => {
						cookies.set('accepts-cookies', JSON.stringify({generic: true, promotional, statistical}));
					}}
					className='cookieBar'
					dismissOnScroll={false}
					id="acceptButton"
					buttonMessage={I18n.t('generic.accept')}
				/>
				<div id='cookieBackground'/>
				{/*  Must be right after CookieBanner	*/}

			</>}
			{modal()}
		</>
	);
};

export default CookieBar;
