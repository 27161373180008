import React from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";

import Shell from '../../components/Shell';
import {Col, Row} from "react-grid-system";
import {StringField} from "../../components/Form/Fields";
import Translate from "components/CustomTranslate";
import FormButton from "../../components/Form/FormButton";
import {ValidatorForm} from "react-material-ui-form-validator";
import {login, updateConfig} from "../../js/actions/config";
import {borrowerFetch} from "../../js/actions/borrower";
import {coborrowerFetch} from "../../js/actions/coborrower";
import {loanFetch} from "../../js/actions/loan";
import {post} from "../../js/helpers/rest";
import history from "../../history";
import {getBackendUrl} from "../../js/helpers/functions";

class ChangePassword extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			password: '',
			passwordRepeat: '',
			sent: false,
			error: false,
		};

		this.props.updateConfig('loading', false);
	}


	getStep() {
		return 'changePassword';
	}

	render() {
		return (
			<div>
				<ValidatorForm onSubmit={() => {
					this.props.updateConfig('loading', true);
					let params = {
						token: this.props.token,
						email: this.props.email,
						password: this.state.password
					};

					return post(params, getBackendUrl() + "/customer/password_change")
						.then(() => this.setState({sent: true, error: false}))
						.catch(() => this.setState({sent: true, error: true}))
						.then(() => this.props.login({email: this.props.email, password: this.state.password}))
						.then(() => {
							let redirect = this.props.borrower.has_signed ? '/personal_home' : '/donnes_personales';
							history.push(redirect);
						});
				}}>

					<Row justify="center" className='firstRow'>
						<Col md={8}>
							<StringField
								name='password'
								onChange={(e) => this.setState({password: e.target.value})}
								value={this.state.password}
								type='password'
								validators={['passwordPattern']}
								errorMessages={['Veuillez insérer au moins 8 caractères, 1 majuscule, 1 miniscule et 1 chiffre pour votre mot de passe']}
								label={<Translate style={{color: this.props.config.theme.primary}} value='step3.password'/>}
								margin="normal"
							/>
						</Col>
					</Row>

					<Row justify="center">
						<Col md={8}>
							<StringField
								onChange={(e) => this.setState({passwordRepeat: e.target.value})}
								value={this.state.passwordRepeat}
								name='passwordRepeat'
								type='password'
								validators={['isMatch:' + this.state.password]}
								errorMessages={['Les mots de passe ne correspondent pas']}
								label={<Translate style={{color: this.props.config.theme.primary}} value='step3.passwordRepeat'/>}
								margin="normal"
							/>
						</Col>
					</Row>

					<Row justify='center'>
						<Col md={4} lg={4} sm={12} xs={12}>
							<FormButton value={<Translate value='generic.follow'/>} disabled={this.state.sent && !this.state.error}/>
						</Col>
					</Row>

				</ValidatorForm>
			</div>
		)
	}
}

export default connect()(Shell(ChangePassword));
