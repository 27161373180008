import React from 'react';
import Shell from '../../components/Shell';
import connect from 'react-redux/es/connect/connect';
import {isMobile} from 'react-device-detect';
import {Row, Col} from 'react-grid-system';
import {getBackendUrl, getParameterByName, parseJwt} from "js/helpers/functions";

import style from './style.css';
import axios from "axios";

const api_url = getBackendUrl();

class SCISignature extends React.Component {

	constructor() {
		super();
		this.state = {signLink: false};
	}

	getStep() {
		return 'sci_signing';
	}

	componentDidMount() {
		let token  = getParameterByName('t', window.location.search);
		let params = parseJwt(token);
		if (params && params.sci) {
			axios({
				method: 'post',
				url: api_url + '/customer/prepare_sci_document',
				data: params.proposition
			}).then(response => {
					axios({
						method: 'post',
						url: api_url + '/customer/sign_sci_document',
						data: {
							params: {
								documents: response.data,
								sci: params,
								document_id: params.proposition.id
							}
						}
					}).then(response => {
						this.props.updateConfig('loading', false)
						this.setState({signLink: response.data.signLink})
					})
				}
			)
		} else {
			window.location.href = '/prets';
		}

	}

	render() {

		let web_signature, mobile_signature;
		web_signature    = process.env.REACT_APP_DESKTOP_SIGNATURE;
		mobile_signature = process.env.REACT_APP_MOBILE_SIGNATURE;
		let signature    = isMobile ? mobile_signature : web_signature;
		return (
			<Row>

				<Col md={12} style={{justifyContent: 'center', alignItems: 'center', display: 'flex', minHeight: '600px'}}>
					{!this.state.signLink ? <div className="lds-roller">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div> :
						<iframe title="wedou-sign" className='sci-signing' src={this.state.signLink + signature}/>}
				</Col>
			</Row>
		);
	}

}

export default connect()(Shell(SCISignature));
