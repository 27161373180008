import React from 'react';
import Button from '@material-ui/core/Button';
import './style.css';

import history from '../../../history';
import {connect} from "react-redux";

class StandardButton extends React.Component {

	render() {
		return (
			// TODO:theme
			// <Button style={{width: '100%', backgroundColor: this.props.config.theme.primary, ...this.props.style }}
			<Button style={{width: '100%', background: '#F6B60D 0% 0% no-repeat padding-box', borderRadius: '26px', ...this.props.style, ...this.props.additionalStyle }}
			        type='button'
			        onClick={() => {
				        history.push(this.props.to)
			        }}
			        {...this.props}
			        className={`formButton ${this.props.className || ''}`}
			>
				{this.props.value}
			</Button>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		config: state.rootReducer.config,
	};
};
export default connect(mapStateToProps)(StandardButton);
