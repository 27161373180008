import {COBORROWER_UPDATE, COBORROWER_INITIAL_FETCH, GET_COBORROWER, COBORROWER_SET, RE_INITIALIZE_ALL, SET_STATE, RELOAD_STATE, CHECK_LEMOINE} from '../../constants/action-types';
import {loadState} from '../../localStorage';

const persistedState = loadState();

let initialState = {
	birth_country: 'France',
	birth_country_id: 67,
	first_name: '',
	last_name: '',
	birth_date: '',
	coverage: 50,
	taea: '',
	salutation: '',
	zip: '',
	csp: 1,
	insurancePremium: null,
	smoker: false,
	manual_work: false,
	question_one: 'false',
	question_two: 'false',
	question_three: 'false',
	question_four: 'false',
	question_five: 'false',
	lemoine: false,
	insured_by_bank: true,
	payment_iban: ''
};
let defaultState = Object.assign({}, initialState, persistedState.coborrower);
export default (state = defaultState, action) => {
	switch (action.type) {
		case SET_STATE:
			return action.data.coborrower;
		case RE_INITIALIZE_ALL:
			return initialState;
		case RELOAD_STATE:
			return {
				...state,
				...action.payload.coborrower
			};
		case COBORROWER_INITIAL_FETCH:
			return {
				...action.data
			};
		case COBORROWER_UPDATE:
			return {
				...state,
				[action.key]: action.value
			};
		case GET_COBORROWER:
			return {
				...action.data
			};
		case COBORROWER_SET:
			return {
				...state,
				...action.data
			};
		case CHECK_LEMOINE:
			return {
				...state,
				lemoine: action.data && action.data.coborrower ? action.data.coborrower : {}
			};
		default:
			return state;
	}

}
