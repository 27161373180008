import React, {useState, useEffect} from 'react';
import {Select, MenuItem, FormControl, InputLabel} from '@material-ui/core'
import './styles.css'
import countriesJson from './countries.json'
import {useLocation} from 'react-router';


import Translate from "components/CustomTranslate";
const Index = ({update, data}) => {
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		setCountries(countriesJson);
	}, [])

	const updateValue = (e) => {
		let country = countries.find(c => c.Nom === e.target.value);
		update("birth_country", e.target.value);
		update('birth_country_id', country.PaysId)
	}

	return (
		<div className='countries'>
			<FormControl className="form">
				<InputLabel><Translate value="step4.country" /></InputLabel>
				<Select id="countries" value={data.birth_country}
				        displayEmpty
				        onChange={updateValue}
				>
					<MenuItem value="France">
						France
					</MenuItem>
					{countries.map((country) => (
						<MenuItem value={country.Nom}>
							{country.Nom}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}

export default Index;
