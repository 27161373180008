import React from 'react';
import connect from "react-redux/es/connect/connect";

import Shell from '../../components/Shell';
import {Col, Row} from "react-grid-system";
import Translate from "components/CustomTranslate";
import {Phone} from '../../components/Form/StepsFields/step4'
import FormButton from "../../components/Form/FormButton";
import {ValidatorForm} from "react-material-ui-form-validator";
import history from "../../history";
import france_connect from "./france_connect_logo.png";
import google_logo from './google_logo.png'
import facebook_logo from './facebook_logo.png'
import facebook from './facebook.png'
import css from "./welcome.module.css";
import { isMobile } from 'react-device-detect';

class WelcomeScreen extends React.Component {

	componentWillMount() {
		if(this.props.config.login_type !== 'google' && this.props.config.login_type !== 'facebook'){
			this.props.updateConfig('loading', true);

			let options = {
				type: this.props.type,
				email: this.props.email,
				accessToken: this.props.code,
				password: ''
			};

			if (this.props.error) {
				this.props.updateConfig('loading', false);
			} else {
				this.props.login(options).then(response => {
					this.props.updateConfig('login_type', this.props.type);
					this.props.updateConfig('loading', false);
				});
			}
		}
	}

	getStep() {
		return 'welcomeScreen';
	}

	getLogo() {
		let type = france_connect;
		let heightLogo = '125px';
		if(this.props.config.login_type === 'google') {
			type = google_logo;
			heightLogo = isMobile?'60px':'90px';
		} else if(this.props.config.login_type === 'facebook') {
			type = facebook;
			heightLogo = isMobile?'60px':'95px';
		}
		return(
			<Row justify="center" style={{marginTop:'30px'}}>
				<Col className="centeredText">
					<img alt="France Connect Logo" height={heightLogo} src={type}/>
				</Col>
			</Row>
		)
	}

	render() {
		return (
			<div>
				<ValidatorForm onSubmit={(data) => {
					this.props.updateCustomer({borrower: {phone: this.props.borrower.phone, email:this.props.borrower.email}, state: {borrower: this.props.borrower}})
					.then(response => {
						if(this.props.borrower.has_signed) {
							history.push('/personal_home')
						} else if(this.props.borrower.step){
							history.push(this.props.borrower.step)
						} else {
							history.push('/resultat')
						}
					})

				}}>
					{this.getLogo()}

					<Row style={{marginTop:'30px'}} justify='center'>
						<Col md={4}>
							<Phone className="social_phone" update={this.props.updateBorrower} borrower={this.props.borrower} data={this.props.borrower}/>
						</Col>
					</Row>

					{this.props.error && window.location.pathname === 'france_connect' ? (
						<div>
							<Row justify="center">
								<Col md={6} lg={6} sm={12} xs={12}>
									<p className="centeredText">Ce compte n'est pas enregistré.<br/>
										Vous pouvez vous inscrire en suivant</p>
								</Col>
							</Row>
							<Row justify="center">
								<Col md={6} lg={4} sm={12} xs={12}>
									<FormButton value="Calculer mon économie" onClick={() => this.props.logout()}/>
								</Col>
							</Row>
						</div>
					) : (
						<div>
							<Row justify="center" className={this.props.borrower.first_name && 'firstRow'}>
								<Col md={6} lg={6} sm={12} xs={12}>
									<p className="centeredText">
										<Translate
											value="welcomeScreen.welcome"/> {this.props.borrower.first_name? this.props.borrower.salutation === 'mr' ? 'Monsieur' : 'Madame':''} {this.props.borrower.first_name} {this.props.borrower.last_name}
									</p>
								</Col>
							</Row>

							<Row justify='center'>
								<Col md={6} lg={4} sm={12} xs={12}>
									<FormButton className={css.continueButton}
												value={<Translate value="generic.continue"/>}/>
								</Col>
							</Row>
						</div>
					)}

				</ValidatorForm>
			</div>
		)
	}
}

export default connect()(Shell(WelcomeScreen));
