import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {StringField} from '../Fields';
import InputMask from 'react-input-mask'
import {ValidatorForm} from 'react-form-validator-core';

let DateField = (props) => {
	let shouldFormat              = false; //true
	let shouldUpdate              = true;
	let {withoutDay, ...newProps} = props;
	withoutDay                    = withoutDay || false;

	// Polyfills to support IE 11
	Number.isNaN     = Number.isNaN || function (value) {
		return (value !== value); //eslint-disable-line
	};
	Number.isInteger = Number.isInteger || function (value) {
		return typeof value === "number" &&
			isFinite(value) &&
			Math.floor(value) === value;
	};

	ValidatorForm.addValidationRule('noSunday', (value) => {
		let d = moment(value, 'DD/MM/YYYY');
		if (d.isoWeekday() !== 7) return true;
	});

	ValidatorForm.addValidationRule('isItRealDate', (value) => {
		let d = moment(value, 'DD/MM/YYYY');
		if (d.isValid()) return true;
	});

	ValidatorForm.addValidationRule('finalOfferValidation', (value) => {
		let d = moment(value, 'DD/MM/YYYY');
		return d.isSameOrAfter(moment().subtract(1, 'day')) && moment().add('6', 'months').isSameOrAfter(d);
	});

	ValidatorForm.addValidationRule('date', (value) => {
		if (!value) return true;
		if (!withoutDay && value.length < 10) return false; // A valid date should have 10 chars
		if (withoutDay && value.length < 7) return false; // A valid date should have 10 chars
		let date = new Date(value.split('/').reverse().join('-'));
		return !Number.isNaN(date.getTime());
	});

	// Add not more than 30 years ago
	ValidatorForm.addValidationRule('notInFuture', (value) => {
		if (!value) return true;
		return new Date(value.split('/').reverse().join('-')) < Date.now();
	});

	ValidatorForm.addValidationRule('afterYear2000', (value) => {
		if (!value) return true;
		return value.split('/')[2] >= 2000;
	});

	ValidatorForm.addValidationRule('sixMonthsInFuture', (value) => {
		return moment(value, 'DD/MM/YYYY').isSameOrBefore(moment().add(6, 'months'));
	})

	ValidatorForm.addValidationRule('notInPast', (value) => {

		if (!value) return true;
		return new Date(value.split('/').reverse().join('-')) >= Date.now();
	});

	ValidatorForm.addValidationRule('diff30', (value) => {
		if (!value) return true;
		const date      = new Date(value.split('/').reverse().join('-'));
		const now       = Date.now();
		let nowMinus30Y = new Date(now);
		nowMinus30Y.setFullYear(nowMinus30Y.getFullYear() - 30);
		return date >= nowMinus30Y;
	});

	ValidatorForm.addValidationRule('diff10', (value) => {
		if (!value) return true;
		const date      = new Date(value.split('/').reverse().join('-'));
		const now       = Date.now();
		let nowMinus10Y = new Date(now);
		nowMinus10Y.setFullYear(nowMinus10Y.getFullYear() - 10);
		return date >= nowMinus10Y;
	});

// ValidatorForm.addValidationRule('invalidMonth', (value) => {
//     if (!value) return true;
//     const date = new Date(value.split('/').reverse().join('-'));
//     const month = date.getUTCMonth() + 1;
//     return (month > 12 || (month) < 1);
// })

// ValidatorForm.addValidationRule('invalidDay', (value) => {
//     if (!value) return false;
//     const date = new Date(value.split('/').reverse().join('-'));
//     return (date.getUTCDate()) > 31 || (date.getUTCDate()) < 1;
// })

// Handle legal age (18 y.o.) requirement
	ValidatorForm.addValidationRule('hasLegalAge', (value) => {
		if (!value) return true;
		const date      = new Date(value.split('/').reverse().join('-'));
		let nowMinus18Y = new Date(Date.now());
		nowMinus18Y.setFullYear(nowMinus18Y.getFullYear() - 18);
		return (date <= nowMinus18Y);
	});

	ValidatorForm.addValidationRule('notBefore', (value, incomingDate) => {
		if (!value) return true;
		// console.log('NOT BEFORE incoming: ', incomingDate)
		const date       = new Date(value.split('/').reverse().join('-'));
		const beforeDate = new Date(incomingDate.split('/').reverse().join('-'));
		// console.log('NOT BEFORE: ', date, beforeDate)
		return (date >= beforeDate);
	});

	if (props.validators) props.validators.push('date');
	if (props.errorMessages) props.errorMessages.push('La date est incorrecte');

	let changeHandler = (e) => {
		let val = e.target.value;
		if (!shouldUpdate) return false;
		if (!withoutDay && val.length > 10) return false; // Limit to 10
		if (withoutDay && val.length > 7) return false; // Limit to 8 for dates without a day.
		val = shouldFormat ? formatDate(val, withoutDay) : val;
		props.onChange(props.name, val);
	};
	let keyHandler    = (e, val) => {
		// Handle browsers not properly supporting keyboard events
		// An issue remains with older iOS versions
		if ((typeof e.key !== 'undefined') && (e.key !== 'Unidentified')) {
			shouldFormat = (e.key !== 'Backspace') && (e.key !== 'Delete');
			shouldUpdate = Number.isInteger(parseInt(e.key, 10)) || (e.key === 'Backspace') || (e.key === 'Delete');
		} else {
			shouldFormat = false;
			shouldUpdate = true;
		}
	};

	return !props.hidden ? <InputMask mask='99/99/9999' maskPlaceholder='DD/MM/YYYY'
				alwaysShowMask={true} label={withoutDay ? 'MM/AAAA' : 'JJ/MM/AAAA'}  {...newProps}
				onChange={changeHandler}
				style={props.style}>
					{(newProps) => <StringField {...newProps}/>}
				</InputMask> : null;
};

DateField.propTypes = {
	style: PropTypes.string,
	onChange: PropTypes.func,
	hidden: PropTypes.bool,
	validators: PropTypes.array,
	errorMessages: PropTypes.array,
	withoutDay: PropTypes.bool
};

export default DateField;

export function formatDate(string, withoutDay = false) {
	// We need this sequence and not unified if's
	// if (string.length === 2) {
	// 	string += '/';
	// }
	// if (string.length > 2 && string[2] !== '/') {
	// 	string = (string.slice(0, 2) + '/' + string.slice(2));
	// }
	// if (string.length === 5) {
	// 	string += '/';
	// }
	// if (string.length > 5 && string[5] !== '/') {
	// 	string = string.slice(0, 5) + '/' + string.slice(5);
	// }

	return string;
}

// export function formatDate(string, withoutDay = false) {
// 	if (string.match(/^\d{2}$/) !== null) {
// 		return string + '/';
// 	} else if (string.match(/^\d{2}\/\d{2}$/) !== null && !withoutDay) {
// 		return string + '/';
// 	} else {
// 		return string;
// 	}
// };
