import React from 'react';
import Shell from 'components/Shell';
import {Row, Col, Container} from 'react-grid-system';
import Translate from "components/CustomTranslate";

// Form
import {ValidatorForm} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormButton from '../../components/Form/FormButton';
// Redux
import connect from 'react-redux/es/connect/connect';

import history from '../../history';

// Styles
import './style.css';
import 'react-day-picker/dist/style.css';
import {
	YourBank,
	DelayedLoan,
	LoanContractDate,
	LoanDurationYears,
	LoanDurationMonths,
	ProjectType,
	LoanAmount,
	LoanType,
	InterestRate
} from 'components/Form/StepsFields/step1';
import ReactTooltip from 'react-tooltip';
import {Coverage, BirthDate, SCICoverage} from 'components/Form/StepsFields/step2';
import {getBackendUrl, getParameterByName, mapData} from 'js/helpers/functions';
import Modal from "components/Modal";
import {CalendarField, NumberField, SelectField, StringField} from "components/Form/Fields";
import Button from "@material-ui/core/Button";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import {post} from "js/helpers/rest";
import {BravoDate, BravoHoursDropdown} from "components/Form/StepsFields/bravo";
import BackButton from "components/Form/BackButton";
import {isMobile} from 'react-device-detect';
import {ButtonGroup, Slider} from "@material-ui/core";
import {Timeline, TimelineSeparator, TimelineItem, TimelineDot, TimelineConnector, TimelineContent} from "@material-ui/lab";
import girlImage from './femme.svg';
import clockImage from './clock.svg';
import {DayPicker} from "react-day-picker";
import DatePickerDialog from "../../components/Form/Fields/DatePickerDialog";
import {Link, animateScroll as scroll, scroller} from "react-scroll";
import {I18n} from "react-redux-i18n";

class LoanInfo extends React.Component {

	constructor(props) {
		super(props);
		if (this.props.openLogin) {
			this.props.openAuthModal('login');
		}
		this.state = {dialogOpen: false, bravo: false, showEffectiveDateModal: false, splitLoanModal: false, currentOpenModal: 0};
	}

	getStep() {
		return 'step1';
	}

	updateCoverage(first, second, update) {
		update('coverage', second + (100 - (first + second)));
	}

	handleSubmit() {
		this.props.updateConfig('loading', true);
		let data = mapData(this.props.borrower, this.props.coborrower, this.props.loan);

		this.props.compareInsurers(data).then((result) => {
			this.props.updateConfig('loading', false);
			if (result && result.data) {
				if (!result.data.result || Object.keys(result.data.insurers).length === 0) this.setState({dialogOpen: true, bravo: true})
				else {

					this.props.updateConfig('calculation', {...this.props.config.calculation, equivalence: result.data.equivalence, simulation_id: result.data.simulation_id})
					history.push('/guaranties');
				}
			} else this.setState({dialogOpen: true});
		});
	}

	scrollToMain() {
		scroller.scrollTo('scroll_to_element', {
			duration: 1500,
			delay: 100,
			smooth: true,
			offset: 40,
		})
	}

	componentWillMount() {
		const queryParams = new URLSearchParams(window.location.search);
		const amount      = queryParams.get('amount');
		const birth_date  = queryParams.get('birth_date');
		const duration    = queryParams.get('duration');
		if (amount && birth_date && duration) {
			this.props.updateBorrower('birth_date', birth_date);
			this.props.updateLoan([0, 'amount'], amount);
			this.props.updateLoan([0, 'duration_months'], duration);

		}
		if (!this.props.loan.loan_status) {
			history.push('/prets');
		}
		if (!this.props.loan.flow) {
			this.props.updateLoan('flow', 'afi-esca');
		}
		this.props.updateConfig('partner_source', window.location.hostname.split('.')[0])
		console.log(this.props.config.partner_source, 43434)
	}

	splitLoanRow(loan_number = 0, payment_number = 0) {
		return (
			<Row key={payment_number}>
				<Col md={5.5}>
					<StringField
						className="mediumLabel"
						name={'split_payment_duration'}
						value={this.props.loan.loans[loan_number].split_payments && this.props.loan.loans[loan_number].split_payments[payment_number].duration ? this.props.loan.loans[loan_number].split_payments[payment_number].duration : 0}
						label="Durée"
						helperText={"en mois"}
						onChange={(e) => {
							let payments                      = this.props.loan.loans[loan_number].split_payments;
							payments[payment_number].duration = e.target.value;
							this.props.updateLoan([loan_number, 'split_payments'], payments);
						}}
					/>
				</Col>
				<Col md={5.5}>
					<StringField
						className="mediumLabel"
						name={'split_payment_duration'}
						value={this.props.loan.loans[loan_number].split_payments && this.props.loan.loans[loan_number].split_payments[payment_number].amount ? this.props.loan.loans[loan_number].split_payments[payment_number].amount : 0}
						label="Montant"
						helperText={"en EUR"}
						onChange={(e) => {
							let payments                    = this.props.loan.loans[loan_number].split_payments;
							payments[payment_number].amount = e.target.value;
							this.props.updateLoan([loan_number, 'split_payments'], payments);
						}}
					/>
				</Col>
				<Col md={1}>
					<i className='fa fa-minus' style={{color: 'red', marginTop: '50px', cursor: 'pointer'}}
					   onClick={() => {
						   let payments = this.props.loan.loans[loan_number].split_payments;
						   payments.splice(payment_number, 1)
						   this.props.updateLoan([loan_number, 'split_payments'], payments);
					   }}></i>
				</Col>
			</Row>
		)
	}

	render() {
		let first_row_md = this.props.translations.locale === 'fr' ? 4 : 6;
		return (
			<div style={{maxWidth: '80vw'}}>
				<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>
				<Modal minWidth="lg" maxWidth="lg" title={<Translate value="generic.oops"/>}
				       dialogOpen={this.state.dialogOpen}
				       config={this.props.config}
				       onClose={() => this.setState({dialogOpen: false})}>
					{this.state.bravo ? this.bravoMessage() : this.contactForm()}
				</Modal>
				{/*<Modal title="Votre date d'effet"*/}
				{/*       dialogOpen={this.state.showEffectiveDateModal}*/}
				{/*       onClose={() => {*/}
				{/*	       this.setState({showEffectiveDateModal: false})*/}
				{/*       }}>*/}
				{/*	/!*Vous bénéficiez d’une résiliation à date anniversaire (amendement Bourquin), la date d’effet sera le 10/10/2021*!/*/}
				{/*	Vous bénéficiez d’une résiliation en loi <b>Hamon</b>, la date d’effet du nouveau contrat sera le <b>10/10/2021</b>*/}
				{/*	<Row justify='center'>*/}
				{/*		<Col md={4}>*/}
				{/*			<Button style={{padding: 0, marginTop: '20px'}} className='formButton emailButton'>C'est compris</Button>*/}
				{/*		</Col>*/}
				{/*	</Row>*/}
				{/*</Modal>*/}

				<ValidatorForm onSubmit={() => this.handleSubmit()}>
					<Modal title="Prêt par paliers"
					       dialogOpen={this.state.splitLoanModal}
					       config={this.props.config}
					       onClose={() => {
						       this.setState({splitLoanModal: false});
					       }}>
						{this.props.loan.loans[this.state.currentOpenModal].split_payments.map((payment, i) => this.splitLoanRow(this.state.currentOpenModal, i))}
						<p style={{cursor: 'pointer'}}
						   onClick={() => this.props.updateLoan([this.state.currentOpenModal, 'split_payments'], this.props.loan.loans[this.state.currentOpenModal].split_payments.concat({
							   amount: 0,
							   duration: 0
						   }))}>
							<i className={`fa fa-plus`} style={{color: 'green'}}/> Ajouter un palier
						</p>
					</Modal>
					<Row justify="center" style={{textAlign: 'center'}}>
						<Col md={10}>
							<span className="titlePrets"><Translate value="step1.title"/></span><br/>
							<img style={{width: '48px'}} src={clockImage}/><span
							style={{color: '#000', fontWeight: 600, fontFamily: 'Poppins-Medium', display: 'inline-block', position: 'relative', bottom: '22px'}}><Translate value="step1.subtitle"/></span>
						</Col>
					</Row>
					<Row justify="center">
						<Col md={6}>
							<img src={this.props.config.theme.frontLoanImage || girlImage} style={{maxHeight: '450px', maxWidth: '400px', float: 'right'}}/>
						</Col>
						<Col md={6} style={{alignSelf: 'center'}}>
							<Timeline align="left">
								<TimelineItem>
									<TimelineSeparator>
										<TimelineDot color="success">
											<i className="fa fa-file" style={{color: 'rgb(50, 206, 155) '}}/>
										</TimelineDot>
										<TimelineConnector/>
									</TimelineSeparator>
									<TimelineContent style={{justifyContent: 'left'}}> <Translate value="step0.first_benefit"/></TimelineContent>
								</TimelineItem>
								<TimelineItem>
									<TimelineSeparator>
										<TimelineDot color="success">
											<i className="fa fa-print" style={{color: 'rgb(50, 206, 155) '}}/>
										</TimelineDot>
										<TimelineConnector/>
									</TimelineSeparator>
									<TimelineContent> <Translate value="step0.second_benefit"/></TimelineContent>
								</TimelineItem>
								<TimelineItem>
									<TimelineSeparator>
										<TimelineDot color="success">
											<i className="fa fa-check" style={{color: 'rgb(50, 206, 155) '}}/>
										</TimelineDot>
									</TimelineSeparator>
									<TimelineContent><Translate value="step0.third_benefit"/></TimelineContent>
								</TimelineItem>
							</Timeline>
						</Col>
					</Row>
					<Row justify="center" style={{textAlign: "center"}}>
						<Col md={6}>
							<p className="subtitle"><Translate value="step0.loan_type"/></p>
						</Col>
					</Row>
					<Row justify="center" style={{textAlign: 'center'}}>
						<Col md={12}>
							<ButtonGroup variant="contained" aria-label="outlined primary button group"
							             style={{backgroundColor: 'rgb(246,246,246)', boxShadow: 'none', borderRadius: '50px'}}>
								<Button style={{
									color: this.props.config.theme.primary,
									backgroundColor: this.props.loan.loan_status === "in_progress" && this.props.config.theme.buttonFirst ? this.props.config.theme.buttonFirst : ''
								}} id="in_progress_button"
								        className={this.props.loan.loan_status === "in_progress" ? "optionsButton selected" : "optionsButton"}
								        onClick={async () => {
									        await this.props.updateLoan("loan_status", "in_progress");
									        this.scrollToMain();
								        }}> <Translate value="step0.existing_loan"/></Button>
								<Button
									style={{color: this.props.config.theme.primary, backgroundColor: this.props.loan.loan_status === "new" && this.props.config.theme.buttonFirst ? this.props.config.theme.buttonFirst : ''}}
									id="new_button"
									className={this.props.loan.loan_status === "new" ? "optionsButton selected" : "optionsButton"}
									onClick={async () => {
										await this.props.updateLoan("loan_status", "new");
										this.scrollToMain();
									}}>Nouveau prêt</Button>
							</ButtonGroup>
						</Col>
					</Row>
					{this.props.loan.loan_status && <div>
						<Row justify="center" id="scroll_to_element">
							<Col md={11} lg={11} xl={9} >
								<Row>
									<Col style={{textAlign: 'center'}}>
										<span className="titlePrets"><Translate value="generic.yourCredit"/></span>
									</Col>
								</Row>
								<Row style={{marginBottom: '20px'}}>
									<Col sm={12} xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
										<p className="title">
											<Translate value="step1.number_of_loans"/>
										</p>
										<ButtonGroup variant="contained" aria-label="outlined primary button group"
										             style={{backgroundColor: 'rgb(246,246,246)', boxShadow: 'none', borderRadius: '50px'}}>
											<Button style={{
												color: this.props.config.theme.primary,
												backgroundColor: this.props.loan.total_loans === 1 && this.props.config.theme.buttonFirst ? this.props.config.theme.buttonFirst : ''
											}}
											        className={this.props.loan.total_loans === 1 ? "optionsButton selected" : "optionsButton"}
											        onClick={() => this.props.updateLoan('total_loans', 1)}>1</Button>
											<Button style={{
												color: this.props.config.theme.primary,
												backgroundColor: this.props.loan.total_loans === 2 && this.props.config.theme.buttonFirst ? this.props.config.theme.buttonFirst : ''
											}}
											        className={this.props.loan.total_loans === 2 ? "optionsButton selected" : "optionsButton"}
											        onClick={() => this.props.updateLoan('total_loans', 2)}>2</Button>
											<Button style={{
												color: this.props.config.theme.primary,
												backgroundColor: this.props.loan.total_loans === 3 && this.props.config.theme.buttonFirst ? this.props.config.theme.buttonFirst : ''
											}}
											        className={this.props.loan.total_loans === 3 ? "optionsButton selected" : "optionsButton"}
											        onClick={() => this.props.updateLoan('total_loans', 3)}>3</Button>
										</ButtonGroup>
									</Col>
								</Row>

								<div>
									<Row justify='center' className="block">
										{this.props.translations.locale === 'fr' && <Col md={first_row_md}>
											<ProjectType update={this.props.updateLoan} projects={this.props.config.project_types} data={this.props.loan}
											             flow={this.props.loan.flow}/>
										</Col>}
										<Col md={first_row_md}>
											<YourBank update={this.props.updateLoan} banks={this.props.config.banks}
											          data={this.props.loan}/>
										</Col>
										{this.props.translations.locale === 'fr' && <Col md={first_row_md}>
											<LoanContractDate update={this.props.updateLoan} data={this.props.loan} flow={this.props.loan.flow} config={this.props.config}
											                  updateBorrower={this.props.updateBorrower} updateCoBorrower={this.props.updateCoBorrower}
											                  showModal={true}
											                  showModalCallback={(val) => {
												                  // this.setState({showEffectiveDateModal: true})
											                  }}
											                  loan_status={this.props.loan.loan_status}/>
										</Col>}

									</Row>

									{this.additionalLoan(0)}
									{this.props.loan.total_loans > 1 && this.additionalLoan(1)}
									{this.props.loan.total_loans > 2 && (this.additionalLoan(2))}

								</div>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={9} className="incomeRow">
								{this.props.loan.project_type === 'regrouping_credit' && <Row justify="center" style={{textAlign: 'center'}}>
									<Col md={10} style={{paddingTop: '15px'}}>
										<FormControlLabel
											id={'realEstateAbove60'}
											control={
												<Checkbox
													checked={this.props.loan.real_estate_60}
													value={this.props.loan.real_estate_60}
													onChange={(e, val) => this.props.updateLoan('real_estate_60', val)}
													style={{color: this.props.config.theme.primary}}
												/>
											}
											label={<Translate value="step1.real_estate_60" dangerousHTML/>}
										/>
									</Col>
								</Row>}
								<Row justify="center" style={{textAlign: 'center'}}>
									<Col md={3} style={{paddingTop: '15px'}}>
										<FormControlLabel
											id={'sciCheckbox'}
											control={
												<Checkbox
													checked={this.props.loan.sci}
													value={this.props.loan.sci}
													onChange={(e, val) => this.props.updateLoan('sci', val)}
													style={{color: this.props.config.theme.primary}}
												/>
											}
											label={<Translate value="step1.sci" dangerousHTML/>}
										/>
									</Col>
								</Row>
								<Row justify='center'>
									<Col md={6}>
										<p className="titlePrets">
											{!this.props.loan.sci ? 'Les emprunteurs' : 'Les assurés'}
										</p>
									</Col>
								</Row>
								<Row justify='center'>
									<Col md={this.props.borrower.has_coborrower ? 4 : 6}>
										<BirthDate
											id='borrower_birth_date'
											label="step1.birth_date"
											data={this.props.borrower} update={this.props.updateBorrower}/>
									</Col>

									{!!this.props.borrower.has_coborrower && (
										<Col md={4}>
											<BirthDate
												id='coborrower_birth_date'
												label='step2.coBirthDate'
												data={this.props.coborrower} update={this.props.updateCoBorrower}/>
										</Col>
									)}
								</Row>
								{!!this.props.loan.sci && (
									<Row justify='center'>
										<Col md={6}>
											<SCICoverage data={this.props.borrower} update={this.props.updateBorrower}
											             check={this.props.coborrower}
											             label='Votre quotité'
											/>
										</Col>
									</Row>
								)}
								{!!this.props.borrower.has_coborrower && (
									<Row justify='center'>
										<Col md={4} style={{padding: '0 20px'}}>
											<p><Translate value="step2.borrowerCoverage" className="inputLabel"/></p>
											<Slider
												aria-label="Custom marks"
												value={this.props.borrower.coverage}
												step={5}
												onChange={(e, val) => {
													if (val > 4) {
														if ((val + this.props.coborrower.coverage) < 100) this.updateCoverage(val, this.props.coborrower.coverage, this.props.updateCoBorrower);
														this.props.updateBorrower('coverage', val)
													}
												}}
												style={{marginTop: '18px'}}
												valueLabelDisplay="on"
												marks={[
													{
														value: 0,
														label: '0%',
													},
													{
														value: 50,
														label: '50%',
													},
													{
														value: 100,
														label: '100%',
													},
												]}
											/>
										</Col>
										<Col md={4} style={{padding: '0 20px'}}>

											<p><Translate value="step2.coBorrowerCoverage" className="inputLabel"/></p>
											<Slider
												aria-label="Custom marks"
												value={this.props.coborrower.coverage}
												step={5}
												style={{marginTop: '18px'}}
												onChange={(e, val) => {
													if (val > 4) {
														if ((val + this.props.borrower.coverage) < 100) this.updateCoverage(val, this.props.borrower.coverage, this.props.updateBorrower);
														this.props.updateCoBorrower('coverage', val)
													}
												}}
												valueLabelDisplay="on"
												marks={[
													{
														value: 0,
														label: '0%',
													},
													{
														value: 50,
														label: '50%',
													},
													{
														value: 100,
														label: '100%',
													},
												]}
											/>
											{/*<Coverage data={this.props.coborrower} update={this.props.updateCoBorrower}*/}
											{/*          check={this.props.borrower}*/}
											{/*          label={<Translate value="step2.coBorrowerCoverage"/>}*/}
											{/*/>*/}
										</Col>
									</Row>
								)}

								{!this.props.loan.sci && <Row className="checkboxes" justify={isMobile ? 'left' : 'center'} style={{textAlign: 'center'}}>
									<Col sm={12} xs={12} md={8}>
										<FormControlLabel
											id='hasCoborrowerCheckbox'
											control={
												<Checkbox
													checked={!!this.props.borrower.has_coborrower}
													onChange={(e, val) => this.props.updateBorrower('has_coborrower', val)}
													style={{color: this.props.config.theme.primary}}
												/>
											}
											label={<Translate value='step1.hasCoBorrower'/>}
										/>
									</Col>
								</Row>}
							</Col>
						</Row>
						<Row justify="center" className='buttonGroup' style={{marginTop: '20px'}}>
							<Col md={4} lg={4} sm={12} xs={12}>
								<FormButton id='stepConfirm1' value={<Translate value='step1.button'/>} onClick={() => {
									// window.gtag && window.gtag('event', 'conversion', {
									// 	'send_to': 'AW-820054480/uZeiCPzNkeUDENCThIcD'
									// });
								}}/>
							</Col>
						</Row>
					</div>}
				</ValidatorForm>
			</div>
		);
	}

	additionalLoan(j) {

		return <div>

			{this.props.loan.total_loans > 1 && <p className='separatorHeading pretSeparator'><Translate value="generic.loan"/> #{j + 1}</p>}
			<Row className={`additionalLoan additionalLoan${j} block`} justify='center'>
				{this.props.translations.locale === 'fr' &&
					<Col md={3}>
						<LoanType update={this.props.updateLoan} data={this.props.loan.loans} loan={j}
						          showSplitLoan={true}
						          splitLoanCallback={(loan_type) => {
							          if (loan_type === 'split_loan') this.setState({splitLoanModal: true})
						          }}/>
						{this.props.loan.loans[j].type === 'split_loan' &&
							<p onClick={() => this.setState({splitLoanModal: true, currentOpenModal: j})}
							   style={{marginTop: '-7px', fontSize: '13px', cursor: 'pointer', marginLeft: '15px', textDecoration: 'underline'}}>modifier les paliers</p>}
					</Col>}
				<Col md={3}>
					{this.props.loan.loans[j].type === 'lease' ?
						<Row>
							<Col md={6}>
								<NumberField
									label={"Valeur Residuelle"}
									placeholder={I18n.t('generic.currency')}
									validators={['required', 'isFloatRequired', 'maxFloatNumber: 1000000', `minFloatNumber: 100`]}
									errorMessages={[I18n.t('step1.errors.needsToBeFilled'), 'La valeur doit être un nombre', I18n.t('step1.errors.amountMax'), 'le montant doit être compris entre 100 et 100 000 EUR']}

									value={this.props.loan.loans[j].residual_value} onValueChange={(e, v) => {
									this.props.updateLoan([0, 'residual_value'], v);
								}
								}/>

							</Col>
							<Col md={6}>
								<LoanAmount update={this.props.updateLoan} contract_date={this.props.loan.contract_date} data={this.props.loan.loans} loan={j}
								            loan_status={this.props.loan.loan_status}/>
							</Col>
						</Row> :
						<Col md={12}>
							<LoanAmount update={this.props.updateLoan} contract_date={this.props.loan.contract_date} data={this.props.loan.loans} loan={j}
							            loan_status={this.props.loan.loan_status}/>
						</Col>}
				</Col>
				<Col md={3}>
					<Row justify="center">
						<Col md={5} xs={5} sm={5}>
							<LoanDurationYears update={this.props.updateLoan} data={this.props.loan.loans} loan={j} loan_status={this.props.loan.loan_status}
							                   checkDuration={true} contract_date={this.props.loan.contract_date} flow={this.props.loan.flow}/>
						</Col>
						<Col md={0.5} xs={0.5} sm={0.5} style={{paddingLeft: '5px', paddingRight: '5px'}}>
							<span className="orOther"><Translate value={"generic.or"}/></span>
						</Col>
						<Col md={5} xs={5} sm={5}>
							<LoanDurationMonths update={this.props.updateLoan} data={this.props.loan.loans} loan={j} loan_status={this.props.loan.loan_status}
							                    checkDuration={true} contract_date={this.props.loan.contract_date} flow={this.props.loan.flow}/>
						</Col>
						{/*<Col md={12} style={{marginTop: '-15px'}}>*/}
						{/*	<p style={{fontSize: '15px', fontWeight: 500, marginBottom: '25px'}}>Durée en mois</p>*/}
						{/*	<Slider*/}
						{/*		aria-label="Custom marks"*/}
						{/*		defaultValue={this.props.loan.loans[j].duration_months}*/}
						{/*		step={1}*/}
						{/*		onChange={(e, val) => {*/}
						{/*			this.props.updateLoan([j, 'duration_months'], val)*/}
						{/*		}}*/}
						{/*		valueLabelDisplay="auto"*/}
						{/*		marks={[*/}
						{/*			{*/}
						{/*				value: 12,*/}
						{/*				label: '12 mois',*/}
						{/*			},*/}
						{/*			{*/}
						{/*				value: 360,*/}
						{/*				label: '360 mois',*/}
						{/*			},*/}
						{/*		]}*/}
						{/*	/>*/}
						{/*</Col>*/}
					</Row>
				</Col>
				{this.props.translations.locale === 'fr' &&
					<Col md={3}>
						<InterestRate update={this.props.updateLoan} data={this.props.loan.loans} loan={j} loan_status={this.props.loan.loan_status}
						              contract_date={this.props.loan.contract_date}/>
					</Col>}
			</Row>
			<Row justify='center'>
				{this.props.loan.loans[j].deferred &&
					<Col md={6}>
						<DelayedLoan update={this.props.updateLoan} data={this.props.loan.loans} loan={j}
						             loan_status={this.props.loan.loan_status}/>
					</Col>}
			</Row>
			{this.props.translations.locale === 'fr' &&
				<Row justify='center'>
					<Col sm={12} xs={12} md={6} className={!isMobile && 'centeredText'}>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.props.loan.loans[j].deferred}
									value={this.props.loan.loans[j].deferred}
									onChange={(e, val) => this.props.updateLoan([j, 'deferred'], val)}
									style={{color: this.props.config.theme.primary}}
								/>
							}
							label={<Translate value='credit.deffered'/>}
						/>
					</Col>

				</Row>
			}
		</div>;
	}

	bravoMessage() {
		return <div style={{margin: '15px 0 40px 0', width: '400px'}}><Translate value="step1.errors.noSimulation"/></div>
	}

	contactForm() {
		return (
			<ValidatorForm
				ref='form'
				onSubmit={() => {
					let params = {
						hours: this.props.borrower.meeting_hours,
						date: this.props.borrower.meeting_date,
						email: this.props.borrower.email,
						phone: this.props.borrower.phone
					};

					let hours = this.props.borrower.meeting_hours.split('-');
					let sh    = parseInt(hours[0].replace('h', '').trim());
					let eh    = parseInt(hours[1].replace('h', '').trim());

					params.start = moment(`${this.props.borrower.meeting_date} ${sh}:00`, 'DD/MM/YYYY HH:mm').format();
					params.end   = moment(`${this.props.borrower.meeting_date} ${eh}:00`, 'DD/MM/YYYY HH:mm').format();

					post(params, getBackendUrl() + '/customer/first_page_contact').then(res => {
						this.setState({submitted: true});
					});
				}}
				onError={errors => console.log(errors)}>
				<Container>
					<Row style={{textAlign: 'center', justifyContent: 'center'}}>
						<Col md={7} className='bravoMessage'>
							<p>Nous n'avons pas de solution en ligne adaptée a votre situation, nous allons vous rappeler</p>
						</Col>
					</Row>
					<Row style={{justifyContent: 'center'}}>
						<Col md={5}>

							<StringField
								label="Votre email"
								name="email"
								floatingLabelText="Votre email"
								value={this.props.borrower.email}
								onChange={(e) => this.props.updateBorrower('email', e.target.value)}
								errorMessages={['Cette information doit être renseignée', 'Le format est incorrect']}
								maxLength="100"
							/>
						</Col>
						<Col md={5}>
							<StringField
								label="Votre n° de téléphone"
								name="mobileNumber"
								floatingLabelText="Votre n° de téléphone"
								value={this.props.borrower.phone}
								onChange={(e) => {
									this.props.updateBorrower('phone', e.target.value);
								}}
								errorMessages={['Cette information doit être renseignée', 'Le format est incorrect']}
								maxLength="100"
							/>
						</Col>

					</Row>
					<Row style={{justifyContent: 'center'}}>
						<Col md={5}>
							<BravoHoursDropdown data={this.props.borrower} update={this.props.updateBorrower}/>
						</Col>
						<Col md={5}>
							<BravoDate data={this.props.borrower} update={this.props.updateBorrower}/>
						</Col>

					</Row>
					<Row style={{marginTop: '1rem', justifyContent: 'center'}}>
						<Col md={4}>
							{this.state.submitted ?
								<Row style={{textAlign: 'center', justifyContent: 'center'}}>
									<Col md={12} className='bravoMessage'>
										<p> C'est bien noté merci, à bientôt.</p>
									</Col>
								</Row>
								:
								<Button style={{padding: 0, minWidth: '250px', backgroundColor: this.props.config.theme.primary}} type='submit' className='formButton emailButton'>
									<p>Suivant</p></Button>

							}
						</Col>
					</Row>
				</Container>
			</ValidatorForm>
		);
	}
}

export default connect()

(
	Shell(LoanInfo)
)
;
