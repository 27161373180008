import React from 'react';
import style from './style.module.css';
import {Row, Col} from 'react-grid-system';

const Price = (props) => {
	if(!props.value)
		return <></>
	let split = props.value.split(',');
	let euros = split[0];
	let cents = split[1].substr(0,2);
	return (
		<div className={style.container}>
			<div className={style.big}>
				{euros}
			</div>
			<div className={style.medium}>
				€
			</div>
			<div>
				<span className={style.medium} style={{position: 'relative', right: '4px'}}>{cents}</span><br />
				<span className={style.small}>/{props.periodicity}</span>
			</div>
		</div>
	);
};

export default Price;
