import React from 'react';
import style from './style.module.css';
import Translate from "components/CustomTranslate";
import {getBackendUrl, randomString} from '../../js/helpers/functions';
import {Row, Col} from "react-grid-system";

class FranceConnect extends React.Component {

	render() {
		return (
			<Row className="franceConnect" justify="center">
				<p className={style.preButtonText}>
					<Translate dangerousHTML
						value={this.props.type === 'login' ? 'franceConnect.loginText' : 'franceConnect.registrationText'}/>
				</p>
				<Col lg={8}>
					<div className={style.buttonImage} onClick={() => {
						window.location.href = this.generateURL();
					}}>
						<img alt='FranceConnect Button' className={style.svg} src="./FCboutons-10.svg"/>
					</div>

					<a className={style.whatIsFranceConnect} target="_blank" rel="noopener noreferrer"
					   href="https://franceconnect.gouv.fr/">
						Qu'est-ce que FranceConnect ?
					</a>
				</Col>
			</Row>
		);
	}

	generateURL() {
		let redirect_uri = getBackendUrl() + '/customer/france_connect?type=' + this.props.type;
		if (this.props.type === 'registration') {
			redirect_uri += '&simulation_id=' + this.props.config.calculation.simulation_id
		}

		let url = process.env.REACT_APP_FRANCE_CONNECT_URL + '/api/v1/authorize';
		url += '?response_type=code';
		url += '&client_id=' + process.env.REACT_APP_FRANCE_CONNECT_CLIENT_ID;
		url += '&redirect_uri=' + encodeURIComponent(redirect_uri);
		url += '&scope=openid profile identite_pivot email address phone';
		url += '&state=' + randomString(50);
		url += '&nonce=' + randomString(50);
		url += '&acr_values=eidas1';

		return url;
	}
}

export default FranceConnect;
