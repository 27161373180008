import style from './style.module.css';
import React from "react";
import {restructureData} from '../../js/helpers/functions';
import Modal from "../../components/Modal";
import Translate from 'components/CustomTranslate';
import FormButton from "../Form/FormButton";
import {ValidatorForm} from 'react-material-ui-form-validator';
import {Button} from "@material-ui/core";
import {Col, Row} from "react-grid-system";
import {FaPhoneAlt} from "react-icons/fa";

class SaveProgress extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			show: false,
			disabled: false,
			showModal: false
		};
	}

	render() {
		return (
			<>
				<Modal
					type="reprendre"
					className="reprendreModal"
					dialogOpen={this.state.showModal}
					config={this.props.config}
					minWidth="lg"
					maxWidth="lg"
					onClose={() => this.setState({showModal: false})}>
					<div style={{fontSize: '24px', fontWeight: '600', color: '#18143b'}}>Très bien, nous vous avons adressé un sms et un <br/>mail avec votre lien de connexion. À bientôt !</div>
					<button onClick={() => this.setState({showModal: false})} className={style.button}>Ok</button>
				</Modal>
				{/*TODO:theme*/}
				{/*<div style={{margin: 'auto',  fontSize: '17px', backgroundColor: this.props.config.theme.secondary}}*/}
				<div style={{margin: 'auto', fontSize: '17px', backgroundColor: this.props.config.theme.buttonFirst || '#F6B60D'}}
				     onClick={(e) => {
					     e.preventDefault();
					     this.setState({showModal: true})
					     e.stopPropagation();
					     this.updateProgress();
				     }}
				     className={style.saveProgressButton}>
					<i className='fa fa-check' style={{marginLeft: '15px'}}/> <Translate value="header.sidebar.later"/>
				</div>
			</>
		)
	}

	updateProgress() {
		if (!this.state.disabled) {
			this.setState({disabled: true});
			setTimeout(() => {
				this.setState({show: false, disabled: false});
			}, 1500);

			let data = restructureData(this.props.borrower, this.props.coborrower, this.props.loan);
			this.props.updateProgress(data)
				.then(response => {
					if (response && response.data && response.data.result) {
					}
				});
		}
	}
}

export default SaveProgress;
