import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import Window from '../../containers/Window/index';
import {getParameterByName} from "../../js/helpers/functions";

class BravoSignature extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			bravo: {},
			submitted: false
		}
	}

	getStep() {
		return 'bravo';
	}

	render() {
		return (
			<div>
				<Window data={this.props.borrower} update={this.props.updateBorrower} config={this.props.config} updateConfig={this.props.updateConfig} bravoContact={this.props.bravoContact} />
			</div>
		)
	}

}

export default connect()(Shell(BravoSignature));
