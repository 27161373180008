import {Translate} from 'react-redux-i18n';
import React, {useEffect} from "react";

export default function CustomTranslate(props) {

	return (
		<span data-tip={localStorage.getItem('translate') ? props.value : ''}>
			<Translate {...props} className={`${props.className || ''} key_${props.value} custom_translate_item`} dangerousHTML/>
		</span>
	)
}
