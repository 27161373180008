import React from "react";

import {Row, Col} from 'react-grid-system';
import history from "../../../history";
import Translate from "components/CustomTranslate";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import './style.css';
import connect from "react-redux/es/connect/connect";
import ReactTooltip from "react-tooltip";

import Shell from "../../../components/Shell";
import {PersonalSpaceHeader} from "../../../components/Header/PersonalSpaceHeader";
import AffiliateButtons from "../Partials/affiliate_buttons";

class Affiliates extends React.Component {

	getStep() {
		return 'personal.documents';
	}

	render() {
		return (
			<>
				<div className='personalFrame'>
					<PersonalSpaceHeader

						contract={this.props.config.contract}
						health_selection={this.props.borrower.health_selection}
						signed={this.props.borrower.has_signed}
						showResigning={this.props.attachments.documents_for_resigning && this.props.attachments.documents_for_resigning.length > 0}
						title={'personal.affiliate.title'}/>
					<br/>
					<ReactTooltip style={{backgroundColor: this.props.config.theme.primary}} className="step2-tooltip"/>

					<Row justify='center'>
						<Col md={10}>
							<p className='centeredText'>
								{!this.props.config.affiliate_data || this.props.config.affiliate_data.signed === 0 ?
									<Translate value='personal.affiliate.empty_subheader' /> :
									<Translate value='personal.affiliate.subheader'
									           signed={this.props.config.affiliate_data ? this.props.config.affiliate_data.signed : 0}
									           total={this.props.config.affiliate_data ? this.props.config.affiliate_data.total : 0}
									/>
								}
							</p>
						</Col>
					</Row>

					<Paper className='personalPaper' style={{marginTop: '40px'}}>
						<Table className='documentsTable'>
							<TableBody className='documentsBody' stripedRows showRowHover>
								<TableRow>
									<TableCell style={{textAlign: 'center'}}><b>#</b></TableCell>
									<TableCell style={{textAlign: 'center'}}><b><Translate value={'personal.affiliate.tableType'} dangerousHTML/></b></TableCell>
									<TableCell style={{textAlign: 'center'}}><b><Translate value={'personal.affiliate.tableRecipient'} dangerousHTML/></b></TableCell>
									<TableCell style={{textAlign: 'center'}}><b><Translate value={'personal.affiliate.tableStatus'} dangerousHTML/></b></TableCell>
									<TableCell style={{textAlign: 'center'}}><b><Translate value={'personal.affiliate.tableRewards'} dangerousHTML/></b></TableCell>
								</TableRow>
								{this.props.config.affiliate_data && this.props.config.affiliate_data.invitations.length === 0 ?
									<TableRow>
										<TableCell style={{borderBottom: 'none', textAlign: 'center'}}><Translate value={'personal.affiliate.tableNoResult'} dangerousHTML/></TableCell>
										<TableCell style={{borderBottom: 'none', textAlign: 'center'}}><Translate value={'personal.affiliate.tableNoResult'} dangerousHTML/></TableCell>
										<TableCell style={{borderBottom: 'none', textAlign: 'center'}}><Translate value={'personal.affiliate.tableNoResult'} dangerousHTML/></TableCell>
										<TableCell style={{borderBottom: 'none', textAlign: 'center'}}><Translate value={'personal.affiliate.tableNoResult'} dangerousHTML/></TableCell>
										<TableCell style={{borderBottom: 'none', textAlign: 'center'}}><Translate value={'personal.affiliate.tableNoResult'} dangerousHTML/></TableCell>
									</TableRow> :
									this.props.config.affiliate_data && this.props.config.affiliate_data.invitations.map((item, i) => {
										return (
											<TableRow key={item.recipient}>
												<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{i + 1}</TableCell>
												<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{item.type}</TableCell>
												<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{item.recipient}</TableCell>
												<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{item.status}</TableCell>
												<TableCell style={{borderBottom: 'none', textAlign: 'center'}}>{item.status === 'signed' ? '30 EUR' : '-'}</TableCell>
											</TableRow>
										)
									})
								}
							</TableBody>
						</Table>
					</Paper>
				</div>
				<AffiliateButtons getAffiliateData={this.props.getAffiliateData} affiliateInvitation={this.props.affiliateInvitation}/>
			</>
		)
	}
}

export default connect()(Shell(Affiliates));
