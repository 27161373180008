import React from 'react';
import Shell from 'components/Shell';
import {Row, Col} from 'react-grid-system';
import FormButton from 'components/Form/FormButton';
import Translate from "components/CustomTranslate";
import connect from 'react-redux/es/connect/connect';
import history from '../../../history';
import {ValidatorForm} from 'react-material-ui-form-validator';
import Paper from '@material-ui/core/Paper';
import CountriesField from 'components/Form/CountryField/index'
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
	AdditionalDetails,
	BirthPlace,
	FirstName,
	LastName,
	PostalCode,
	Salutations,
	StreetDetails,
	Town,
	Phone,
	Email,
} from 'components/Form/StepsFields/step4';


import {getWebsiteCountry, goBack, mapData, phoneValidation} from '../../../js/helpers/functions';
import BackButton from "components/Form/BackButton";
import {NumberField, StringField} from "../../../components/Form/Fields";
import {Button} from "@material-ui/core";

class PersonalInfo extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			validated: false,
			showAddressError: false,
			sameAddress: true,
			uniqueEmailError: '',
			click_id: sessionStorage.getItem('click_id'),
			source: sessionStorage.getItem('utm_source'),
			source_tracked: false,
		};
	}

	getStep() {
		return 'step4';
	}

	personalData(coBorrower = false) {
		let update = coBorrower ? this.props.updateCoBorrower : this.props.updateBorrower;
		let data   = coBorrower ? this.props.coborrower : this.props.borrower;

		return (<>
				{/*TODO:theme*/}
				<div className="incomeRow">
					<Row justify='center'>
						<Col md={6} style={{textAlign: 'center'}}>
							<p className='titlePrets'>
								<Translate value={'step4.personalInfo'}/>
							</p>
						</Col>
					</Row>

					<Row justify='center'>
						<Col md={4}>
							<Salutations lang={this.props.translations.locale} id={coBorrower ? 'cobo-profession' : 'salutation'} update={update} data={data}
							             disabled={this.props.config.login_type === 'france_connect' && !!data.salutation}/>
						</Col>
						<Col md={4}>
							<FirstName id={coBorrower ? 'cobo-name' : 'name'} update={update} data={data}
							           disabled={this.props.config.login_type === 'france_connect' && !!data.first_name}/>
						</Col>
						<Col md={4}>
							<LastName id={coBorrower ? 'cobo-lastName' : 'lastName'} update={update} data={data}
							          disabled={this.props.config.login_type === 'france_connect' && !!data.last_name}/>
						</Col>
					</Row>


					{coBorrower &&
						<Row justify='center'>
							<Col md={6}>
								<Email update={update} data={data} borrower={this.props.borrower} uniqueEmailError={this.state.uniqueEmailError}
								       updateUniqueEmail={(value) => this.setState({uniqueEmailError: value})}/>
							</Col>
						</Row>}
				</div>
			</>
		);
	}

	addressData(coBorrower = false) {
		let update = (key, value) => {
			if (!coBorrower) {
				this.props.updateBorrower(key, value);
				if (this.props.borrower.has_coborrower && this.state.sameAddress) {
					this.props.updateCoBorrower(key, value);
				}
			} else {
				this.props.updateCoBorrower(key, value);
			}
		};
		let data   = coBorrower ? this.props.coborrower : this.props.borrower;

		return (
			<div>
				<Row justify='center'>
					<Col md={6}>
						<StreetDetails update={update} data={data}/>
					</Col>
					<Col md={6}>
						<AdditionalDetails update={update} data={data}/>
					</Col>
				</Row>

				<Row justify='center'>
					<Col md={6}>
						<NumberField value={this.props.borrower.zip} onValueChange={(e, val) => {
							this.props.updateBorrower("zip", val)
						}} label={<Translate value="step4.postalCode"/>}/>
					</Col>
					<Col md={6}>
						<Town update={update} data={data}/>
					</Col>
				</Row>
			</div>
		);
	}


	mapData(b, cb, l) {
		let data = {
			borrower:
				{
					email: b.email,
					first_name: b.first_name,
					last_name: b.last_name,
					city: b.city,
					salutation: b.salutation,
					zip: b.zip,
					address: b.address,
					birth_city: b.birth_city,
					additional_address: b.additional_address,
					step: 'income_personal',
				}
		};
		return data;
	}

	render() {
		return (
			<div>
				<ValidatorForm onSubmit={async () => {
					await this.props.updateIncomeCustomer(mapData(this.props.borrower));
					history.push('income_hq')
				}}>
					{this.personalData(false)}

					<div className="incomeRow">
						<Row justify='center'>
							<Col md={6}>
								<p className='titlePrets'><Translate value='step4.streetAddress'/></p>
							</Col>
						</Row>

						{this.addressData(false)}

						{!!this.props.borrower.has_coborrower && (
							<Row justify='center'>
								<Col md={8} className='centeredText'>
									<FormControlLabel
										control={
											<Checkbox
												checked={this.state.sameAddress}
												style={{color: this.props.config.theme.primary}}
												onChange={(e, value) => this.checkAddressData(value)}
											/>
										}
										label={<Translate value='step4.sameAddress'/>}
									/>
								</Col>
							</Row>
						)}
					</div>

					<div style={{marginTop: '20px'}}>
						<Row justify="center" style={{marginTop: '10px'}}>
							<Col md={5}>
								<Button className='formButton backButton' style={{borderRadius: '26px', backgroundColor: '#292F44', color: '#fff'}} onClick={() => history.push('/income')}><Translate
									value="generic.back"/></Button>
							</Col>
							<Col md={5}>
								<FormButton name='suivant' value={<Translate value='generic.follow'/>}/>
							</Col>
						</Row>
					</div>
				</ValidatorForm>
			</div>
		);
	}
}

export default connect()(Shell(PersonalInfo));
