import React from 'react';
import Shell from '../../components/Shell';
import {connect} from 'react-redux';
import {Container, Row, Col} from "react-grid-system";
import style from './style.css';
import Translate from "components/CustomTranslate";
import StandardButton from "../../components/Form/StandardButton";
import {Profession} from "../../components/Form/StepsFields/step5";
import {CustomValidatorForm} from "../../components/Form";
import RadioGroup from "@material-ui/core/RadioGroup";
import {DateField, NumberField, SelectField, SelectFieldItems, YesNoRadio} from "../../components/Form/Fields";
import {remainingCapital} from "../../js/helpers/functions";
import Switch from "react-switch";
import checkedIcon from "../../components/Form/InterestField/ptz.png";
import {BirthDate} from "../../components/Form/StepsFields/step2";
import history from "../../history";
var I18n = require('react-redux-i18n').I18n;

class IncomeStepOne extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonClicked: false,
		}
	}


	getStep() {
		return 'income_step_one';
	}

	render() {

		return (
			<div>
				<Row style={{backgroundColor: this.props.config.theme.primary, padding: 0, marginBottom: '80px', paddingBottom: '20px', textAlign: 'center'}} justify="center">
					<Col md={12}>
						<Row>
							<Col md={12}>
								<h1>Top départ ! Calculons votre offre ensemble.</h1>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={8}>
								<p>
									L’assurance prévoyance Wedou vous protège des différents aléas de la vie. Nous complétons la couverture de votre régime obligatoire et protégeons vos revenus.
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
				<Container style={{color: '#000', textAlign: 'center', width: '48%',}}>
					<CustomValidatorForm>
						<Row justify="center" className="incomeRow">
							<Col md={6} style={{paddingTop: '20px'}}>
								<p>Quel est votre type d’entreprise ?</p>
							</Col>
							<Col md={5}>
								<h2>
									<SelectField
										onChange={(e) => {
											this.props.updateBorrower('profession', e.target.value);
											let profession = this.props.config.professions.filter(p => p.flow === this.props.loan.flow).find(p => p.key === e.target.value);
											this.props.updateBorrower('csp', profession.csp);
										}}
										value={this.props.borrower.profession}
										validators={['required']}
										errorMessages={['Cette information doit être renseignée']}
										margin="normal"
										// className="extraLongLabel"
									>{SelectFieldItems(this.props.config.professions.filter(p => p.flow === this.props.loan.flow))}</SelectField>
								</h2>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={12}>
								<Row>
									<Col md={6}>
										<p>Travaillez-vous principalement dans un bureau avec un ordinateur ?</p>
									</Col>
									<Col md={5}>
										<h2>
											<RadioGroup className='radioGroup healthQuestion'
											            name='question_one'
											            value={this.props.borrower.has_coborrower}
											            onChange={(event, value) => this.update('question_one', value)}
											><YesNoRadio/></RadioGroup>
										</h2>
									</Col>
								</Row>
								<hr/>
								<Row>
									<Col md={6}>
										<p>Effectuez-vous plus de 20 000 km/an de déplacements professionnels ?</p>
									</Col>
									<Col md={5}>
										<h2>
											<RadioGroup className='radioGroup healthQuestion'
											            name='question_one'
											            value={this.props.borrower.has_coborrower}
											            onChange={(event, value) => this.update('question_one', value)}
											><YesNoRadio/></RadioGroup>
										</h2>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={6} style={{paddingTop: '20px'}}>
								<p>Quel est votre régime obligatoire ?</p>
							</Col>
							<Col md={5}>
								<h2>
									<SelectField
										id='sports'
										onChange={(e) => this.props.updateBorrower('sport', e.target.value)}
										value={this.props.borrower.sport}
										validators={['required']}
										errorMessages={['Cette information doit être renseignée']}
										margin="normal"
										className="longLabel"
									>{SelectFieldItems(this.props.config.sports, 'sports')}</SelectField>
								</h2>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={6} style={{paddingTop: '10px'}}>
								<p>Quelle est votre rémunération nette mensuelle en moyenne ?</p>
							</Col>
							<Col md={5} style={{paddingTop: '20px', paddingBottom: '10px'}}>
								<NumberField
									shrink={true}
									name={'amount'}
									id='loan_amount'
									placeholder={I18n.t('generic.currency')}
									decimalScale={2}
									fixedDecimalScale
									type='tel'
									validators={['required', 'isFloatRequired', 'maxFloatNumber: 10000000', `minFloatNumber: 5`]}
									errorMessages={[I18n.t('step1.errors.needsToBeFilled'), 'La valeur doit être un nombre', I18n.t('step1.errors.amountMax'), I18n.t('step1.errors.amountLimits')]}
									value={this.props.borrower.amount}
									suffix={I18n.t('generic.currencySymbol')}
									InputProps={{
										endAdornment: <span>&euro;</span>,
									}}
									onValueChange={(event, value) => {
										this.props.updateBorrower('amount', value);
									}}
								/>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={6} style={{paddingTop: '20px'}}>
								<p>Quelle est votre date de naissance ?</p>
							</Col>
							<Col md={5} style={{paddingTop: '20px', paddingBottom: '10px'}}>
								<DateField
									label={false}
									id='borrower_birth_date'
									name='birthDate'
									placeholder='JJ/MM/AAAA'
									value={this.props.borrower.birth_date}
									onChange={(e, val) => this.props.updateBorrower('birth_date', val)}
									validators={['required', 'notInFuture', 'hasLegalAge', 'isItRealDate']}
									className={'extraLongLabel'}
									errorMessages={[I18n.t('step1.errors.needsToBeFilled'), I18n.t('step1.errors.incorrectDate')]}
								/>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={6} style={{paddingTop: '10px'}}>
								<p>Avez-vous des enfants ou personnes à charge financièrement ?</p>
							</Col>
							<Col md={5}>
								<h2>
									<RadioGroup className='radioGroup healthQuestion'
									            name='question_one'
									            value={this.props.borrower.has_coborrower}
									            onChange={(event, value) => this.updateBorrower('question_one', value)}
									><YesNoRadio/></RadioGroup>
								</h2>
							</Col>
						</Row>
						<Row justify="center" className="incomeRow">
							<Col md={6} style={{paddingTop: '20px'}}>
								<p>Souhaitez-vous recevoir cette offre par mail ?</p>
							</Col>
							<Col md={5}>
								<h2>
									<RadioGroup className='radioGroup healthQuestion'
									            name='question_one'
									            value={this.props.borrower.has_coborrower}
									            onChange={(event, value) => this.update('question_one', value)}
									><YesNoRadio/></RadioGroup>
								</h2>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={12} style={{color: '#666666'}}>
								<p>Ces données sont utilisées afin de vous proposer l’offre la plus adaptée. Elles seront transmises à l’assureur, si vous souscrivez à notre
									prévoyance. Vous pouvez retrouver à tout moment notre politique des données et celle de l'assureur ici.</p>
							</Col>
						</Row>
						<Row justify="center">
							<Col md={8}>
								<StandardButton onClick={() => {
									history.push("/income_result")
								}} style={{borderRadius: '25px'}} value="CONTINUER VERS LES COUVERTURES"/>
							</Col>
						</Row>
					</CustomValidatorForm>
				</Container>
			</div>
		)
	}

}

export default connect()(Shell(IncomeStepOne));
