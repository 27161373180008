import React from 'react';
import {connect} from 'react-redux';
import Loader from '../../components/Loader';
import CookieBar from '../../components/CookieBar';
import {Col, Row} from 'react-grid-system';
import ReactTooltip from "react-tooltip";
import {loadTranslations, setLocale} from 'react-redux-i18n';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {
	isFloatRequired,
	isFloatNotRequired,
	requiredOnCondition,
	isInteger,
	isIntegerNotRequired,
	isMobileNumber,
	isName,
	isMatch,
	isNotMatch,
	minFloatNumber,
	maxFloatNumber,
	isPasswordLong,
	passwordPattern,
	yearsBiggerThanRemaining,
	monthsBiggerThanRemaining,
	postalValidation,
	nameValidation,
	nameValidationLength,
	hasNoWhitespace, sixCharacters
} from '../../js/helpers/validators';
import './style.css';

import {updateBorrower, borrowerFetch, borrowerIban, mncapQuestionnaire, saveLemoine, checkLemoine, updateWellness, affiliateInvitation, updateIncomeCustomer} from '../../js/actions/borrower';
import {updateCoBorrower, coborrowerFetch} from '../../js/actions/coborrower';
import {updateLoan, loanFetch} from '../../js/actions/loan';
import {reInitializeState, reloadState} from '../../js/actions';
import {downloadAttachment, addAttachments, getAttachments} from '../../js/actions/attachments';
import {
	sign,
	uuid,
	login,
	logout,
	afiEsca,
	addLead,
	documents,
	hasSigned,
	callMeBack,
	ConfirmSMS,
	bravoContact,
	registration,
	updateConfig,
	checkSMSCode,
	updateCustomer,
	updateProgress,
	updatedPricing,
	bravoDocuments,
	compareInsurers,
	getContractData,
	resignDocuments,
	insurerSpecifics,
	getAffiliateData,
	sendRenunciation,
	finalCalculation,
	updateAfiescaIban,
	incomeRegistration,
	initialCalculation,
	insuranceCalculation,
	afiEscaCoboLongProcess,
	sendCoborrowerReminder,
	initialCalculationNoInsurance,

} from '../../js/actions/config';

import IBAN from 'iban';
import history from '../../history';
import Translate from "components/CustomTranslate";
import {translations} from '../../js/helpers/translations';
import SideMenu from '../SideMenu';
import {Helmet} from 'react-helmet';
import Footer from '../Footer';
import Header from '../Header';
import Modal from '../Modal';
import FormButton from '../Form/FormButton';
import {getParameterByName, goBack, checkNatexoPixelFire} from '../../js/helpers/functions';
import Authentication from '../Authentication';
import SideEar from '../SideEar';
import SideBar from "../SideBar";
import {isMobile} from 'react-device-detect';
import coinImage from '../FinalOffer/coin.png';

ValidatorForm.addValidationRule('isFloatNotRequired', isFloatNotRequired);
ValidatorForm.addValidationRule('nameValidation', nameValidation);
ValidatorForm.addValidationRule('nameValidationLength', nameValidationLength);
ValidatorForm.addValidationRule('requiredOnCondition', requiredOnCondition);
ValidatorForm.addValidationRule('isFloatRequired', isFloatRequired);
ValidatorForm.addValidationRule('isInteger', isInteger);
ValidatorForm.addValidationRule('isIntegerNotRequired', isIntegerNotRequired);
ValidatorForm.addValidationRule('isMobileNumber', isMobileNumber);
ValidatorForm.addValidationRule('isName', isName);
ValidatorForm.addValidationRule('isMatch', isMatch);
ValidatorForm.addValidationRule('isNotMatch', isNotMatch);
ValidatorForm.addValidationRule('minFloatNumber', minFloatNumber);
ValidatorForm.addValidationRule('maxFloatNumber', maxFloatNumber);
ValidatorForm.addValidationRule('isPasswordLong', isPasswordLong);
ValidatorForm.addValidationRule('passwordPattern', passwordPattern);
ValidatorForm.addValidationRule('moreThan18', isPasswordLong);
ValidatorForm.addValidationRule('sixCharacters', sixCharacters);
ValidatorForm.addValidationRule('postalValidation', postalValidation);
ValidatorForm.addValidationRule('IBAN', (value) => IBAN.isValid(value) || !value);
ValidatorForm.addValidationRule('hasNoWhitespace', hasNoWhitespace);
ValidatorForm.addValidationRule('lessThan26characters', value => value && value.length <= 26);

function Shell(Component) {
	class HOC extends React.Component {

		constructor(props) {
			super(props);
			this.step = Component.prototype.getStep(this.props.config.isCoborrower);
			ValidatorForm.addValidationRule('yearsBiggerThanRemaining', (value) => yearsBiggerThanRemaining(value, this.props.loan.contract_date));
			ValidatorForm.addValidationRule('monthsBiggerThanRemaining', (value) => monthsBiggerThanRemaining(value, this.props.loan.contract_date));

			this.meta  = translations.fr.metaTags[this.step] || {};
			this.state = {
				showMenu: false,
				dialogOpen: false,
				isPersonal: window.location.pathname.indexOf('/personal_') !== -1,
				isAffiliate: window.location.pathname.indexOf('/personal_affiliate') !== -1,
				// route: window.location.pathname,
				authEmail: '',
				authOpened: false,
				authType: 'login',

				route: window.location.href.split('/')[3]
			};

			if (this.state.isPersonal) {
				checkNatexoPixelFire(true);
				this.props.getAttachments();
				this.props.getContractData();
				this.props.getAffiliateData();
			}

		}

		componentDidMount() {
			history.listen(() => {
				this.setState({
					isPersonal: window.location.pathname.indexOf('/personal_') !== -1,
					isAffiliate: window.location.pathname.indexOf('/personal_affiliate') !== -1,
				});
			});
			if (this.props.config.loggedIn) {
				if (this.props.borrower.has_signed && window.location.pathname.indexOf('/personal_') === -1 ||
					(window.location.pathname.indexOf('/personal_') === -1 && this.props.borrower.health_selection && this.props.loan.flow === 'afi-esca')) {
					let open_calendar = window.location.pathname.indexOf('/rendez-vous') !== -1;
					if (open_calendar) {
						this.props.updateConfig('openCalendar', true);
					}
				}
			} else if (this.props.mustBeLogged && !window.__TRANSLATION_VIEW) {
				this.openAuthModal('login');
			}
			if (window.location.pathname.indexOf('/personal_') !== -1 && !this.props.borrower.has_signed && window.location.pathname.indexOf('/personal_affiliate') === -1
				&& (!this.props.borrower.health_selection && this.props.loan.flow !== 'afi-esca')) {
				history.push(this.props.borrower.step || '/données_personnelles');
			}

			window.scrollTo(0, 0);

			this.props.updateConfig('loading', false);
			this.props.updateConfig('sessionExpired', false);

			//Syncing for mirroring
			window.addEventListener('updateState', (data) => {
				let state = {};
				if (data.detail.key === 'all') {
					state = data.detail.state;
				} else {
					state[data.detail.key] = data.detail.state;
				}
				this.props.reloadState(state);
				if (data.detail.page && window.location.pathname !== data.detail.page) {
					let url = data.detail.page.indexOf('http') !== -1 ? new URL(data.detail.page).pathname : data.detail.page;
					history.push(url);
				}
			});
			window.addEventListener('changePage', (data) => {
				if (data.detail.page) {
					history.push(data.detail.page);
				}
			});
		}

		render() {
			let showHeader = (this.step === 'step3' || this.step === 'step8') ? typeof this.props.config.calculation === 'undefined' ? true : !!this.props.config.calculation.result : true;
			if (this.step === 'quick_pricing') {
				document.body.classList.add('quick-pricing-prevent');
			}
			let customer = this.props.config.isCoborrower ? this.props.coborrower : this.props.borrower;
			customer     = customer || {};

			let title = Component.prototype.getTitle ? Component.prototype.getTitle() || this.meta.title || 'Wedou' : 'Wedou';
			// let backgroundImgOrColour = {backgroundColor: '#fff'};
			// TODO: add in theme
			//  let backgroundImgOrColour = (this.props.config.theme.backgroundImage !== '') ? {backgroundImage: "url(" + this.props.config.theme.backgroundImage + ")", backgroundSize: '100%', backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat'} : {background: this.props.config.theme.backgroundColour};
			let backgroundImgOrColour = {backgroundImage: "url(" + coinImage + ")", backgroundSize: '30%', backgroundPosition: 'left top', backgroundRepeat: 'no-repeat'}
			return (
				<div className="App PersonalSpace">
					<Helmet>
						<title>{title}</title>
						<meta property="description" content={this.meta.description || 'Wedou'}/>
						<meta property="og:url" content={`https://app.wedou.fr/${this.meta.url}`}/>
						<meta property="og:title" content={this.meta.title || 'Wedou'}/>

						<meta property="og:description" content={this.meta.description || 'Wedou'}/>
						<meta property="og:image" content="https://uploads-ssl.webflow.com/5a8d6cbc1a5b5000018f02f4/5dd50f3c9fa9b15d456d53b5_HERO.png"/>
					</Helmet>

					<Header
						step={this.step}
						email={customer.email}
						name={customer.first_name}
						flow={this.props.loan.flow}
						config={this.props.config}
						updateConfig={this.props.updateConfig}
						estimation={this.props.estimation}
						showModal={(data) => this.showModal(data)}
						openAuthModal={(type) => this.openAuthModal(type)}
					/>

					<main>
						<div className='mainContainer mainPersonalContainer'>
							<ReactTooltip/>
							<Row className={['rendez-vous-page', 'quick-pricing'].indexOf(this.state.route) === -1 && 'mainRow'}>
								{['rendez-vous-page', 'quick-pricing'].indexOf(this.state.route) === -1 &&
									<SideBar
										updateProgress={this.props.updateProgress}
										bravoContact={this.props.bravoContact}
										isPersonal={this.state.isPersonal}
										updateBorrower={this.props.updateBorrower}
										callMeBack={this.props.callMeBack}
										borrower={this.props.borrower}
										config={this.props.config}
										flow={this.props.loan.flow}
										updateConfig={this.props.updateConfig}
										translations={this.props.translations}
										openAuthModal={(type) => this.openAuthModal(type)}
									/>
								}

								{this.state.isPersonal &&
									<div className={`navBar navBarMobile ${this.state.showMenu ? 'active' : ''}`}>
										<i className="fa fa-times hide-menu" onClick={() => this.setState({showMenu: false})}/>
										<i className="fa fa-bars show-menu" onClick={() => this.setState({showMenu: true})}/>

										{this.personalMenu()}
									</div>
								}

								<div
									style={['step6', 'quick_pricing'].indexOf(this.step) === -1 ? {backgroundColor: '#fff', ...backgroundImgOrColour} : {backgroundColor: ['dev.wedou.fr', 'staging.homelifeinsurance.fr', 'app.wedou.fr', 'stage.wedou.fr'].indexOf(window.location.hostname) == -1 ? this.props.config.theme.primary : '#fff'}}
									className={this.state.route === 'rendez-vous-page' ? 'white-no-header' : this.state.route === 'quick-pricing' ? 'content-no-header' : 'content'}>

									<div className="contentWrapper">
										{window.location.pathname !== '/prets' && showHeader && this.step &&
											<div className="headerTitle">
												{/* <p>
												{['rendez-vous', 'bravo_signature', 'rendez-vous-page', 'co-sante-deja', 'offre'].indexOf(this.state.route) === -1 &&
												<Translate value={this.step + '.title'} name={customer.first_name}
												           flow={this.props.loan.flow}
												           logo={this.props.loan.flow === 'axa' ? axa : mutlog} dangerousHTML/>
												}
											</p> */}
											</div>
										}
										<Component {...this.props}
										           showModal={(data) => this.showModal(data)}
										           openAuthModal={(type) => this.openAuthModal(type)}/>

										{this.sessionModal()}
										{(['rendez-vous-page', 'quick-pricing'].indexOf(this.state.route) === -1) ?
											<Authentication
												income={this.state.route.indexOf('income') !== -1}
												borrower={this.props.borrower}
												coborrower={this.props.coborrower}
												registration={this.props.registration}
												incomeRegistration={this.props.incomeRegistration}
												config={this.props.config}
												login={this.props.login}
												logout={this.props.logout}
												loan={this.props.loan}
												updateConfig={this.props.updateConfig}
												updateBorrower={this.props.updateBorrower}
												authOpened={this.state.authOpened}
												authType={this.state.authType}
												authEmail={this.state.authEmail}
												authClose={() => this.closeAuthModal()}
												translations={this.props.translations}
												changeType={(type) => this.setState({authType: type})}
											/> : ''
										}

									</div>
								</div>
								{['rendez-vous-page', 'quick-pricing'].indexOf(this.state.route) === -1 ?
									<Footer type={'standard'} config={this.props.config} email={this.props.borrower.email}/>
									: ''}
							</Row>
							{['rendez-vous-page', 'quick-pricing'].indexOf(this.state.route) === -1 && isMobile && <SideEar
								updateConfig={this.props.updateConfig}
								config={this.props.config}
								borrower={this.props.borrower}
								updateBorrower={this.props.updateBorrower}
								callMeBack={this.props.callMeBack}
								showModal={(data) => this.showModal(data)}
							/>}
						</div>
					</main>

					{['rendez-vous-page', 'quick-pricing'].indexOf(this.state.route) === -1 ? <CookieBar config={this.props.config}/> : ''}
					<Loader config={this.props.config}/>
					<SideMenu
						logout={this.props.logout}
						config={this.props.config}
						borrower={this.props.borrower}
						coborrower={this.props.coborrower}
						updateCustomer={this.props.updateCustomer}
						updateConfig={this.props.updateConfig}
						updateBorrower={this.props.updateBorrower}
						reInitializeState={this.props.reInitializeState}
						openAuthModal={(type) => this.openAuthModal(type)}
					/>
				</div>
			);
		}

		showModal(data) {
			let {dialogOpen, dialogTitle, dialogContent} = data;
			this.setState({dialogOpen, dialogTitle, dialogContent});
		}

		personalMenu() {
			return <ul className='menu'>
				<li className={history.location.pathname === '/personal_home' ? 'active' : ''}
				    onClick={() => history.push('/personal_home')}>
					<Translate value='personal.header.home'/>
				</li>
				<li className={history.location.pathname === '/personal_steps' ? 'active' : ''}
				    onClick={() => history.push('/personal_steps')}>
					<Translate value='personal.header.steps'/>
				</li>
				<li className={history.location.pathname === '/personal_documents' ? 'active' : ''}
				    onClick={() => history.push('/personal_documents')}>
					<Translate value='personal.header.documents'/>
				</li>
			</ul>;
		}

		sessionModal() {
			let logout = () => {
				localStorage.removeItem('state');
				localStorage.removeItem('id_hint');
				this.openAuthModal('login');
			};
			return (
				<Modal title='Votre session a expiré, veuillez vous reconnecter'
				       dialogOpen={!this.state.authOpened && this.props.config.sessionExpired}
				       config={this.props.config}
				       onClose={() => logout()}>
					<FormButton onClick={() => logout()} value='Reconnecter'/>
				</Modal>
			);
		}

		openAuthModal(type) {
			let email = getParameterByName('existing_email', window.location.href);
			if (email) {
				window.history.replaceState(null, document.title, window.location.href.split('?')[0]);
			}
			this.setState({
				authEmail: email || '',
				authOpened: true,
				authType: type
			});
		}

		closeAuthModal() {
			this.setState({
				authOpened: false
			});
		}
	}

	const mapStateToProps = (state) => {
		return {
			loan: state.rootReducer.loan,
			config: state.rootReducer.config,
			borrower: state.rootReducer.borrower,
			coborrower: state.rootReducer.coborrower,
			attachments: state.rootReducer.attachments,
			translations: state.i18n
		};
	};

	return connect(
		mapStateToProps,
		{
			uuid,
			sign,
			login,
			logout,
			addLead,
			afiEsca,
			documents,
			setLocale,
			hasSigned,
			loanFetch,
			ConfirmSMS,
			callMeBack,
			updateLoan,
			saveLemoine,
			reloadState,
			borrowerIban,
			checkLemoine,
			bravoContact,
			updateConfig,
			registration,
			checkSMSCode,
			borrowerFetch,
			addAttachments,
			bravoDocuments,
			getAttachments,
			updateCustomer,
			updateProgress,
			updatedPricing,
			updateWellness,
			updateBorrower,
			coborrowerFetch,
			resignDocuments,
			compareInsurers,
			getContractData,
			loadTranslations,
			updateCoBorrower,
			insurerSpecifics,
			getAffiliateData,
			sendRenunciation,
			finalCalculation,
			reInitializeState,
			updateAfiescaIban,
			mncapQuestionnaire,
			initialCalculation,
			downloadAttachment,
			incomeRegistration,
			affiliateInvitation,
			updateIncomeCustomer,
			insuranceCalculation,
			afiEscaCoboLongProcess,
			sendCoborrowerReminder,
			initialCalculationNoInsurance
		}
	)(HOC);
}

export default Shell;

