import React from 'react';
import Translate from "components/CustomTranslate";
import {DateField, NumberField, SelectField, CalendarField, SelectFieldItems, StringField} from '../Fields';
import InterestField from '../InterestField';
import moment from 'moment';
import InputMask from 'react-input-mask'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {numberWithSpaces, parseDate, remainingCapital} from '../../../js/helpers/functions';
import Switch from "react-switch";
import {InputAdornment} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import {minFloatNumber} from "../../../js/helpers/validators";
import DatePickerDialog from "../Fields/DatePickerDialog";
var I18n = require('react-redux-i18n').I18n;
let YourBank = (props) => {
	return <Autocomplete
		options={props.banks}
		getOptionLabel={(option) => option.name}
		onChange={(e, value) => {
			let insurer = '';
			props.banks.forEach(bank => {
				if (bank.key === (value ? value.key : null)) insurer = bank.default_insurer;
			});
			props.update('bank', value && value.key);
			props.update('bank_search', value);
			props.update('insurer', insurer);
		}}
		value={props.data.bank_search && props.data.bank_search.key ? props.data.bank_search : props.banks.find(b => b.key === props.data.bank)}
		margin="normal"
		id='bank_select'
		variant='outlined'
		className='mediumLabel'
		disabled={props.disabled}
		validators={['required']}
		errorMessages={[I18n.t('step1.errors.needsToBeFilled')]}
		renderInput={(params) => <StringField {...params}
		                                      value={props.data.bank_search}
		                                      validators={['required']} errorMessages={[I18n.t('step1.errors.needsToBeFilled')]}
		                                      label={<Translate value='step1.chooseBank'/>}/>}

	/>;
};

let CustomMask = () => {
	return <InputMask
		mask='99/99/9999'
	/>
}

let LoanContractDate = (props) => {
	let validators = ['required', 'isItRealDate'];
	let errors     = [I18n.t('step1.errors.needsToBeFilled'), 'La date est incorrecte'];
	if (props.loan_status === "new") {
		validators.push('notInPast');
		errors.push("La date doit être dans le futur");
		validators.push('sixMonthsInFuture');
		errors.push('Votre souscription doit intervenir au plus tard dans six mois.');
	} else {
		validators.push('notInFuture', 'afterYear2000');
		errors.push('La date est incorrecte')
	}
	let helperText = '';
	let yearOlder;
	let dayOlder;
	if (props.data.contract_date) {
		let momentValue = moment(props.data.contract_date, 'DD/MM/YYYY');
		let now         = moment();
		dayOlder        = momentValue.isBefore(now, 'day');
		yearOlder       = momentValue.add(1, 'year').isBefore(now, 'day');
		helperText      = yearOlder ? <Translate value="step1.borquin"/> : dayOlder ? <Translate value="step1.hamon"/> : <Translate value="step1.new_loan"/>;
	}

	return <DatePickerDialog
		id='contractDate'
		label={<Translate value='step1.loanContractDate'/>}
		// onChange={(e, val) => {
		// 	props.update('contract_date', val)
		// 	let completed_date = new RegExp(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/).test(val);
		// 	if (props.showModal && completed_date) props.showModalCallback(val)
		//
		// }}
		validators={validators}
		errorMessages={errors}
		value={props.data.contract_date}
		update={props.update}
		placeholder='JJ/MM/AAAA'
		// helperText={helperText}
		updateField="contract_date"
		className='longLabel'
		name={'contractDate'}
		fromYear={moment().subtract(10, 'year').year()}
		toYear={moment().add(1, 'year').year()}
		margin="normal"
		inputComponent={CustomMask}
		disabled={props.disabled}/>
}
let EffectiveDate = (props) => {
	let validators = ['required', 'isItRealDate', 'notInPast'];
	let errors     = [I18n.t('step1.errors.needsToBeFilled'), 'La date est incorrecte'];

	return <DatePickerDialog
		id='contractDate'
		label={<Translate value='step1.effective_date'/>}
		validators={validators}
		errorMessages={errors}
		value={props.data.effective_date}
		update={props.update}
		placeholder='JJ/MM/AAAA'
		updateField="effective_date"
		helperText={"set this as date"}
		className='longLabel'
		name={'contractDate'}
		fromYear={moment().subtract(10, 'year').year()}
		toYear={moment().add(1, 'year').year()}
		margin="normal"
		inputComponent={CustomMask}
		disabled={props.disabled}/>
}
let DelayedLoan = props => {
	let max = props.data[props.loan].duration_months || 120;
	return <NumberField
		name={'loanDurationYears'}
		type='tel'
		id='delayed_months'
		label="Durée du différé en mois"
		value={props.data[props.loan].delayed_months}
		margin="normal"
		validators={['required', 'isInteger', `maxFloatNumber: ${max}`, 'minFloatNumber: 1']}
		errorMessages={[I18n.t('step1.errors.needsToBeFilled'), 'La valeur doit être un nombre', `La valeur ne doit pas être supérieure à ${max} mois`, `La valeur doit être compris entre 1 et ${max} mois`]}
		onValueChange={(event, value) => {
			props.update([props.loan, 'delayed_months'], parseInt(value));
		}}
	/>;
};


let LoanDurationYears = (props) => {
	let validators    = ['requiredOnCondition:' + !props.data[props.loan].duration_months, 'isIntegerNotRequired', 'maxNumber:30'];
	let errorMessages = [I18n.t('step1.errors.yearsOrMonths'), 'integer', I18n.t('step1.errors.maxYear')];

	if (props.checkDuration && props.loan_status !== "new") {
		validators.push('yearsBiggerThanRemaining');
		errorMessages.push('Votre prêt est achevé ou presque!');
	}
	return <StringField
		className="mediumLabel"
		name={'loanDurationYears'}
		validators={validators}
		type='tel'
		id='duration_years'
		errorMessages={errorMessages}
		label={<Translate value="step1.durationOfLoan" />}
		helperText={<Translate value="step1.durationYears" />}
		disabled={!!props.disabled || (!!props.data[props.loan].duration_months && !props.data[props.loan].duration_years)}
		onChange={(e) => {
			props.update([props.loan, 'duration_years'], e.target.value);
			if (e.target.value * 12 !== props.data[props.loan].duration_months) {
				props.update([props.loan, 'duration_months'], e.target.value * 12);
			}

			if (props.loan_status === 'in_progress') {
				let remaining_amount = remainingCapital(props.data[props.loan].amount, props.data[props.loan].interest_rate, props.data[props.loan].duration_months, props.contract_date) || null;
				props.update([props.loan, 'remaining_amount'], remaining_amount);
			}
		}}
		value={props.data[props.loan].duration_years === null ? '' : props.data[props.loan].duration_years}
		margin="normal"
	/>;
};

let LoanDurationMonths = (props) => {
	let validators = ['requiredOnCondition:' + !props.data[props.loan].duration_years, 'isIntegerNotRequired', 'maxNumber:360', 'minNumber:1'];
	if (props.checkDuration && props.loan_status !== "new") validators.push('monthsBiggerThanRemaining');
	return <StringField
		type={'tel'}
		validators={validators}
		errorMessages={[I18n.t('step1.errors.needsToBeFilled'), 'integer', I18n.t('step1.errors.durationMax'), I18n.t('step1.errors.durationMin'), props.checkDuration && 'Votre prêt est achevé ou presque!']}
		disabled={!!props.disabled || (!!props.data[props.loan].duration_years && !props.data[props.loan].duration_months)}
		label={<Translate value="step1.durationOfLoan" />}
		helperText={<Translate value="step1.durationMonths" />}
		id='duration_months'
		onChange={(e, val) => {
			props.update([props.loan, 'duration_months'], e.target.value);
			if (e.target.value / 12 !== props.data[props.loan].duration_years) {
				props.update([props.loan, 'duration_years'], e.target.value % 12 === 0 ? e.target.value / 12 : '');
			}
			if (props.loan_status === 'in_progress') {
				let remaining_amount = remainingCapital(props.data[props.loan].amount, props.data[props.loan].interest_rate, props.data[props.loan].duration_months, props.contract_date) || null;
				props.update([props.loan, 'remaining_amount'], remaining_amount);
			}
		}}
		value={props.data[props.loan].duration_months === null ? '' : props.data[props.loan].duration_months}
		className={'mediumLabel'}
		name={'monthsLoan'}
		margin="normal"
	/>;
};

let ProjectType = (props) => {

	return <SelectField
		label={<Translate value='step1.projectType'/>}
		onChange={(e) => {
			props.update('project_type', e.target.value)
		}}
		value={props.data.project_type}
		validators={['required']}
		errorMessages={[I18n.t('step1.errors.needsToBeFilled')]}
		margin="normal"
		id='project_select'
		// variant='outlined'
		className={'mediumLabel'}
		disabled={props.disabled}
	>{SelectFieldItems(props.projects)}</SelectField>;
};


let LoanType = (props) => {
	let types = [
		{name: 'Amortissable', key: 'depriciable'},
		{name: 'Prêt Relais', key: 'relay'},
		{name: 'In Fine', key: 'in_fine'},
		{name: 'Prêt Par Paliers', key: 'split_loan'},
		{name: 'Crédit Bail', key: 'lease'}
	];
	return <SelectField
		label={<Translate value="step1.loan_type" />}
		onChange={(e) => {
			if (props.showSplitLoan) props.splitLoanCallback(e.target.value)
			props.update([props.loan, 'type'], e.target.value);
		}}
		value={props.data[props.loan].type}
		validators={['required']}
		errorMessages={I18n.t('step1.errors.needsToBeFilled')}
		margin="normal"
		id='loan_type_select'
		// variant='outlined'
		className={'mediumLabel'}
		disabled={props.disabled}
	>
		{SelectFieldItems(types)}
	</SelectField>;
};

let LoanAmount = (props) => {
	let label = <Translate value='step1.amount'/>;

	if (props.loan_status === 'new') label = <Translate value="step1.amount" />;
	let min_amount = props.data[props.loan].type === 'lease' ? '100' : '5000';
	return <NumberField
		shrink={true}
		name={'amount'}
		id='loan_amount'
		placeholder={I18n.t('generic.currency')}
		label={props.data[props.loan].type === 'lease' ? 'Loyer' :label}
		decimalScale={2}
		fixedDecimalScale
		type='tel'
		validators={['required', 'isFloatRequired', 'maxFloatNumber: 10000000', `minFloatNumber: ${min_amount}`]}
		errorMessages={[I18n.t('step1.errors.needsToBeFilled'), 'La valeur doit être un nombre', I18n.t('step1.errors.amountMax'), I18n.t('step1.errors.amountLimits')]}
		value={props.data[props.loan].amount}
		suffix={I18n.t('generic.currencySymbol')}
		onValueChange={(event, value) => {
			props.update([props.loan, 'amount'], value);
			if (props.loan_status === 'in_progress') {
				let remaining_amount = remainingCapital(props.data[props.loan].amount, props.data[props.loan].interest_rate, props.data[props.loan].duration_months, props.contract_date) || null;
				props.update([props.loan, 'remaining_amount'], remaining_amount);
			}
		}}
		className={'longLabel'}
		disabled={props.disabled}
	/>;
};

let LoanRemainingAmount = (props) => {
	return <NumberField
		shrink={true}
		name={'amount'}
		placeholder='EUR'
		id='remaining_loan_amount'
		label="Capital restant dû"
		decimalScale={2}
		fixedDecimalScale
		type='tel'
		validators={['required', 'isFloatRequired', 'maxFloatNumber: 3000000']}
		errorMessages={[I18n.t('step1.errors.needsToBeFilled'), 'La valeur doit être un nombre', 'La valeur ne doit pas être supérieure à 3 000 000 EUR']}
		value={parseFloat(props.data[props.loan].custom_remaining_amount) > 0 ? props.data[props.loan].custom_remaining_amount : props.data[props.loan].remaining_amount}
		suffix=' &euro;'
		onFocus={() => props.update([props.loan, 'remaining_lock'], true)}
		onBlur={() => props.update([props.loan, 'remaining_lock'], false)}
		onValueChange={(event, value) => {
			if (!!props.data[props.loan].remaining_lock) {
				props.update([props.loan, 'custom_remaining_amount'], value);
			}
			props.update([props.loan, 'remaining_amount'], value);
		}}
		disabled={props.disabled}
	/>;
};

let InterestRate = (props) => {

	let label = <Translate value="step1.rate" dangerousHTML />;
	// if (props.loan_status === 'new') label = 'Taux d’intérêt';

	return <InterestField
		label={label}
		id="interest_rate"
		value={props.data[props.loan].interest_rate}
		suffix='%'
		validators={['required', 'isFloatRequired', 'maxFloatNumber: 10']}
		errorMessages={[I18n.t('step1.errors.needsToBeFilled'), 'La valeur doit être un nombre', 'La valeur ne doit pas être supérieure à 10%']}
		decimalScale={3}
		// placeholder='en % hors assurance'
		fixedDecimalScale
		margin="normal"
		onValueChange={(event, value) => {
			props.update([props.loan, 'interest_rate'], value);

			if (props.loan_status === 'in_progress') {
				let remaining_amount = remainingCapital(props.data[props.loan].amount, props.data[props.loan].interest_rate, props.data[props.loan].duration_months, props.contract_date) || null;
				props.update([props.loan, 'remaining_amount'], remaining_amount);
			}
		}}
		disabled={props.disabled}
	/>;
};

export {
	YourBank,
	DelayedLoan,
	LoanContractDate,
	LoanDurationYears,
	LoanDurationMonths,
	ProjectType,
	LoanRemainingAmount,
	LoanAmount,
	InterestRate,
	LoanType,
	EffectiveDate
};
